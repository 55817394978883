import PriceListPriceHistory from 'component/PriceListPage/PriceListPriceHistory'
import TimeRecordHistory from 'component/TimeRecordOverviewPage/TimeRecordHistory'
import toastStyle from 'component/toast/toastStyle'
import browserHistory from 'helper/history'
import i18nProvider from 'helper/i18n'
import PrivateRoute from 'layout/PrivateLayout/PrivateRoute'
import SuspenseComponent from 'layout/PrivateLayout/SuspenseComponent'
import PublicRoute from 'layout/PublicLayout/PublicRoute'
import moment from 'moment'
import 'moment/locale/cs'
import PropTypes from 'prop-types'
import React, {lazy} from 'react'
import {connect} from 'react-redux'
import {Redirect, Router, Switch} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {compose} from 'redux'
import {I18nProvider} from '@lingui/react'
import withStyles from '@material-ui/core/styles/withStyles'

const LoginPage = lazy(() => import('page/LoginPage'))
const EmployeePage = lazy(() => import('page/EmployeePage'))
const InboxPage = lazy(() => import('page/InboxPage'))
const PriceListPage = lazy(() => import('page/PriceListPage'))
const InvoicePage = lazy(() => import('page/InvoicePage'))
const HourPage = lazy(() => import('page/HourPage'))
const WorkReportPage = lazy(() => import('page/WorkReportPage'))
const TimeRecordOverviewPage = lazy(() => import('page/TimeRecordOverviewPage'))
const ClientPage = lazy(() => import('page/ClientPage'))
const EmployeeDetail = lazy(() => import('component/EmployeePage/EmployeeDetail/EmployeeDetail'))
const ClientDetail = lazy(() => import('component/ClientPage/ClientDetail/ClientDetail'))
const PriceListPrice = lazy(() => import('component/PriceListPage/PriceListPrice'))
const InvoiceDetail = lazy(() => import('component/InvoicePage/InvoiceDetail'))
const InboxDetail = lazy(() => import('component/InboxPage/InboxDetail'))
const EmployeeEdit = lazy(() => import('component/EmployeePage/EmployeeEdit'))
const EmployeeNew = lazy(() => import('component/EmployeePage/EmployeeNew'))
const EmployeeHistory = lazy(() => import('component/EmployeePage/EmployeeHistory'))
const PriceListPriceDetail = lazy(() => import('component/PriceListPage/PriceListPriceDetail'))
const PriceListPriceNew = lazy(() => import('component/PriceListPage/PriceListPriceNew'))
const ResetPasswordPage = lazy(() => import('page/ResetPasswordPage'))
const ClientNew = lazy(() => import('component/ClientPage/ClientNew'))
const ClientEdit = lazy(() => import('component/ClientPage/ClientEdit'))
const ClientHistory = lazy(() => import('component/ClientPage/ClientHistory'))
const ClientContactDetail = lazy(() =>
  import('component/ClientPage/ClientContact/ClientContactDetail')
)
const ClientContactHistory = lazy(() =>
  import('component/ClientPage/ClientContact/ClientContactHistory')
)
const InvoiceEdit = lazy(() => import('component/InvoicePage/InvoiceEdit'))
const TimeRecordDetail = lazy(() => import('component/TimeRecordOverviewPage/TimeRecordDetail'))
const TimeRecordEdit = lazy(() => import('component/TimeRecordOverviewPage/TimeRecordEdit'))
const ClientInvoicePage = lazy(() => import('page/ClientInvoicePage'))
const ClientMap = lazy(() => import('component/ClientPage/ClientMap/ClientMap'))
const ClientInvoiceDetail = lazy(() => import('component/ClientInvoicePage/ClientInvoiceDetail'))
const ClientInvoiceEdit = lazy(() => import('component/ClientInvoicePage/ClientInvoiceEdit'))
const QuotePage = lazy(() => import('page/QuotePage'))
const QuoteDetail = lazy(() => import('component/QuotePage/QuoteDetail'))
const EfficiencyPage = lazy(() => import('page/EfficiencyPage'))
const EvaluationsPage = lazy(() => import('page/EvaluationsPage'))
const EvaluationDetail = lazy(() => import('component/EvaluationPage/EvaluationDetail'))
const EfficiencyDetail = lazy(() => import('component/EfficiencyPage/EfficiencyDetail'))
const EvaluationEdit = lazy(() => import('component/EvaluationPage/EvaluationEdit'))
const PersonifiedMessage = lazy(() => import('page/PersonifiedMessage'))

function App(props) {
  const {globalSetting, classes} = props

  const activeLang = globalSetting.currentLanguage ? globalSetting.currentLanguage : 'cs'

  i18nProvider.activate(activeLang)

  moment.locale(activeLang)

  return (
    <I18nProvider i18n={i18nProvider}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <Router history={browserHistory}>
        <Switch>
          <PublicRoute path="/login" component={<SuspenseComponent component={<LoginPage />} />} />
          <PublicRoute
            exact
            path="/reset-password/:token"
            component={<SuspenseComponent component={<ResetPasswordPage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/hours"
            component={<SuspenseComponent component={<HourPage />} />}
          />
          {/* WORK REPORTS MZDOVE VYKAZY */}
          <PrivateRoute
            exact={true}
            path="/work-reports"
            component={<SuspenseComponent component={<WorkReportPage />} />}
          />
          {/* TIME RECORDS (PREHLED HODIN) */}
          <PrivateRoute
            exact={true}
            path="/time-records"
            component={<SuspenseComponent component={<TimeRecordOverviewPage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/time-records/:id"
            component={<SuspenseComponent component={<TimeRecordDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/time-records/:id/edit"
            component={<SuspenseComponent component={<TimeRecordEdit />} />}
          />
          <PrivateRoute
            exact={true}
            path="/time-records/:id/history"
            component={<SuspenseComponent component={<TimeRecordHistory />} />}
          />
          {/* EMPLOYEES */}
          <PrivateRoute
            exact={true}
            path="/employees"
            component={<SuspenseComponent component={<EmployeePage />} />}
          />
          {/* new must be before /:id, otherwise /new route would be caught by /:id */}
          <PrivateRoute
            exact={true}
            path="/employees/new"
            component={<SuspenseComponent component={<EmployeeNew />} />}
          />
          <PrivateRoute
            exact={true}
            path="/employees/:id"
            component={<SuspenseComponent component={<EmployeeDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/employees/:id/edit"
            component={<SuspenseComponent component={<EmployeeEdit />} />}
          />
          <PrivateRoute
            exact={true}
            path="/employees/:id/history"
            component={<SuspenseComponent component={<EmployeeHistory />} />}
          />
          {/* CLIENTS */}
          <PrivateRoute
            exact={true}
            path="/clients"
            component={<SuspenseComponent component={<ClientPage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/clients/new"
            component={<SuspenseComponent component={<ClientNew />} />}
          />
          <PrivateRoute
            exact={true}
            path="/clients/map"
            component={<SuspenseComponent component={<ClientMap />} />}
          />
          <PrivateRoute
            exact={true}
            path={['/clients/:id', '/clients/:id/contact']}
            component={<SuspenseComponent component={<ClientDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/clients/:id/edit"
            component={<SuspenseComponent component={<ClientEdit />} />}
          />
          <PrivateRoute
            exact={true}
            path="/clients/:id/history"
            component={<SuspenseComponent component={<ClientHistory />} />}
          />
          <PrivateRoute
            exact={true}
            path="/clients/:id/contact/:contactId"
            component={<SuspenseComponent component={<ClientContactDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/clients/:id/contact/:contactId/history"
            component={<SuspenseComponent component={<ClientContactHistory />} />}
          />
          {/* PRICE LISTS */}
          <PrivateRoute
            exact={true}
            path="/price-lists"
            component={<SuspenseComponent component={<PriceListPage />} />}
          />
          <PrivateRoute
            exact={true}
            path={['/price-lists/:id', '/price-lists/:id/prices']}
            component={<SuspenseComponent component={<PriceListPrice />} />}
          />
          <PrivateRoute
            exact={true}
            path="/price-lists/:id/prices/new"
            component={<SuspenseComponent component={<PriceListPriceNew />} />}
          />
          <PrivateRoute
            exact={true}
            path="/price-lists/:id/prices/:priceId"
            component={<SuspenseComponent component={<PriceListPriceDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/price-lists/:id/prices/:priceId/history"
            component={<SuspenseComponent component={<PriceListPriceHistory />} />}
          />
          {/* EMPLOYEE INVOICES */}
          <PrivateRoute
            exact={true}
            path="/invoices"
            component={<SuspenseComponent component={<InvoicePage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/invoices/:id"
            component={<SuspenseComponent component={<InvoiceDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/invoices/:id/edit"
            component={<SuspenseComponent component={<InvoiceEdit />} />}
          />
          {/* QUOTES */}
          <PrivateRoute
            exact={true}
            path="/quotes"
            component={<SuspenseComponent component={<QuotePage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/quotes/:id"
            component={<SuspenseComponent component={<QuoteDetail />} />}
          />
          {/* EFFICIENCY */}
          <PrivateRoute
            exact={true}
            path="/efficiency"
            component={<SuspenseComponent component={<EfficiencyPage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/efficiency/:id"
            component={<SuspenseComponent component={<EfficiencyDetail />} />}
          />
          {/* EVALUATION */}
          <PrivateRoute
            exact={true}
            path="/evaluations"
            component={<SuspenseComponent component={<EvaluationsPage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/evaluations/:id"
            component={<SuspenseComponent component={<EvaluationDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/evaluations/:id/edit"
            component={<SuspenseComponent component={<EvaluationEdit />} />}
          />
          {/* MESSAGES */}
          <PrivateRoute
            exact={true}
            path="/inbox"
            component={<SuspenseComponent component={<InboxPage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/inbox/:id"
            component={<SuspenseComponent component={<InboxDetail />} />}
          />
          {/* CLIENT INVOICES */}
          <PrivateRoute
            exact={true}
            path="/client-invoices"
            component={<SuspenseComponent component={<ClientInvoicePage />} />}
          />
          <PrivateRoute
            exact={true}
            path="/client-invoices/:id"
            component={<SuspenseComponent component={<ClientInvoiceDetail />} />}
          />
          <PrivateRoute
            exact={true}
            path="/client-invoices/:id/edit"
            component={<SuspenseComponent component={<ClientInvoiceEdit />} />}
          />
          {/* MESSAGE */}
          <PrivateRoute
            exact={true}
            path="/personified-messages"
            component={<SuspenseComponent component={<PersonifiedMessage />} />}
          />

          <Redirect to="/hours" />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

App.propTypes = {
  globalSetting: PropTypes.object,
  classes: PropTypes.object,
}

export default compose(
  withStyles(toastStyle),
  connect((store) => {
    return {
      globalSetting: store.globalSetting,
    }
  })
)(App)
