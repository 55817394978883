import {redirectTo} from 'helper/functions'
import React from 'react'
import {TOGGLE_SHORTCUT_HELP, UPDATE_SHORTCUTS} from 'redux/actionType'
import {Trans} from '@lingui/macro'

const globalShortcuts = [
  {
    shortcut: 'shift+h',
    action: () => redirectTo('/hours'),
    description: <Trans>Worked hours overview</Trans>,
  },
  {
    shortcut: 'shift+m',
    action: () => redirectTo('/work-reports'),
    description: <Trans>Salary work reports</Trans>,
  },
  {
    shortcut: 'shift+p',
    action: () => redirectTo('/time-records'),
    description: <Trans>Hours overview</Trans>,
  },
  {
    shortcut: 'shift+z',
    action: () => redirectTo('/employees'),
    description: <Trans>Employees overview</Trans>,
  },
  {
    shortcut: 'shift+k',
    action: () => redirectTo('/clients'),
    description: <Trans>Clients overview</Trans>,
  },
  {
    shortcut: 'shift+c',
    action: () => redirectTo('/price-lists'),
    description: <Trans>Price list overview</Trans>,
  },
  {
    shortcut: 'shift+f',
    action: () => redirectTo('/invoices'),
    description: <Trans>Invoices overview</Trans>,
  },
]

const initState = {
  shortcuts: [...globalShortcuts],
  helpVisible: false,
}

const shortcutReducer = (state = initState, action = null) => {
  switch (action.type) {
    case UPDATE_SHORTCUTS: {
      return {
        ...state,
        shortcuts: [...globalShortcuts, ...action.data],
      }
    }
    case TOGGLE_SHORTCUT_HELP: {
      return {
        ...state,
        helpVisible: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export default shortcutReducer
