const mapPermissionsToAbilities = (permissions) => {
  const rules = []
  permissions.forEach((permission) => {
    switch (permission) {
      // MANAGES
      case 'can_manage_users':
        rules.push({subject: 'Users', action: 'manage'})
        break
      case 'can_manage_roles':
        rules.push({subject: 'Roles', action: 'manage'})
        break
      // EMPLOYEES
      case 'can_list_employees':
        rules.push({subject: 'Employees', action: 'read'})
        break
      case 'can_list_employees_simplified':
        rules.push({subject: 'EmployeesSimplified', action: 'read'})
        break
      case 'can_get_employees':
        rules.push({subject: 'Employee', action: 'read'})
        break
      case 'can_create_employees':
        rules.push({subject: 'Employee', action: 'create'})
        break
      case 'can_update_employees':
        rules.push({subject: 'Employee', action: 'update'})
        break
      case 'can_delete_employees':
        rules.push({subject: 'Employee', action: 'delete'})
        break
      case 'can_view_hourly_wages':
        rules.push({subject: 'HourlyWages', action: 'read'})
        break
      // CLIENTS
      case 'can_list_clients':
        rules.push({subject: 'Clients', action: 'read'})
        break
      case 'can_list_clients_simplified':
        rules.push({subject: 'ClientsSimplified', action: 'read'})
        break
      case 'can_create_clients':
        rules.push({subject: 'Client', action: 'create'})
        break
      case 'can_get_clients':
        rules.push({subject: 'Client', action: 'read'})
        break
      case 'can_update_clients':
        rules.push({subject: 'Client', action: 'update'})
        break
      case 'can_delete_clients':
        rules.push({subject: 'Client', action: 'delete'})
        break
      case 'can_set_client_employee':
        rules.push({subject: 'Client', action: 'setEmployee'})
        break
      // PRICE LISTS
      case 'can_create_price_list':
        rules.push({subject: 'PriceList', action: 'create'})
        break
      case 'can_list_price_list':
        rules.push({subject: 'PriceLists', action: 'read'})
        break
      case 'can_get_price_list':
        rules.push({subject: 'PriceList', action: 'read'})
        break
      case 'can_delete_price_list':
        rules.push({subject: 'PriceList', action: 'delete'})
        break
      case 'can_update_price_list ':
        rules.push({subject: 'PriceList', action: 'update'})
        break
      // PRICES
      case 'can_create_price':
        rules.push({subject: 'Price', action: 'create'})
        break
      case 'can_list_price':
        rules.push({subject: 'Prices', action: 'read'})
        break
      case 'can_get_price':
        rules.push({subject: 'Price', action: 'read'})
        break
      case 'can_delete_price':
        rules.push({subject: 'Price', action: 'delete'})
        break
      case 'can_update_price':
        rules.push({subject: 'Price', action: 'update'})
        break
      // TIME RECORDS
      case 'can_list_time_records':
        rules.push({subject: 'TimeRecords', action: 'read'})
        break
      case 'can_create_time_records':
        rules.push({subject: 'TimeRecord', action: 'create'})
        break
      case 'can_get_time_records':
        rules.push({subject: 'TimeRecord', action: 'read'})
        break
      case 'can_update_time_records':
        rules.push({subject: 'TimeRecord', action: 'update'})
        break
      case 'can_delete_time_records':
        rules.push({subject: 'TimeRecord', action: 'delete'})
        break
      case 'can_manage_all_time_records':
        rules.push({subject: 'AllTimeRecords', action: 'manage'})
        break
      case 'can_list_all_time_records':
        rules.push({subject: 'AllTimeRecords', action: 'read'})
        break
      case 'can_list_subordinates_time_records':
        rules.push({subject: 'SubordinatesTimeRecords', action: 'read'})
        break
      case 'can_get_reports':
        rules.push({subject: 'Reports', action: 'read'})
        break
      // EMPLOYEE INVOICES
      case 'can_list_employee_invoices':
        rules.push({subject: 'EmployeeInvoices', action: 'read'})
        break
      case 'can_list_all_employee_invoices':
        rules.push({subject: 'AllEmployeeInvoices', action: 'read'})
        break
      case 'can_get_employee_invoices':
        rules.push({subject: 'EmployeeInvoice', action: 'read'})
        break
      case 'can_create_employee_invoices':
        rules.push({subject: 'EmployeeInvoice', action: 'create'})
        break
      case 'can_update_employee_invoices':
        rules.push({subject: 'EmployeeInvoice', action: 'update'})
        break
      case 'can_delete_employee_invoices':
        rules.push({subject: 'EmployeeInvoice', action: 'delete'})
        break
      case 'can_generate_employee_invoices':
        rules.push({subject: 'EmployeeInvoices', action: 'generate'})
        break
      case 'can_close_and_transfer_employee_invoices':
        rules.push({subject: 'EmployeeInvoice', action: 'closeAndTransfer'})
        break
      // TAX DATES
      case 'can_list_client_tax_dates':
        rules.push({subject: 'TaxDates', action: 'read'})
        break
      case 'can_get_client_tax_dates':
        rules.push({subject: 'TaxDate', action: 'read'})
        break
      case 'can_create_client_tax_dates':
        rules.push({subject: 'TaxDate', action: 'create'})
        break
      case 'can_update_client_tax_dates':
        rules.push({subject: 'TaxDate', action: 'update'})
        break
      case 'can_delete_client_tax_dates':
        rules.push({subject: 'TaxDate', action: 'delete'})
        break
      // CLIENT INVOICES
      case 'can_list_client_invoices':
        rules.push({subject: 'ClientInvoices', action: 'read'})
        break
      case 'can_get_client_invoices':
        rules.push({subject: 'ClientInvoice', action: 'read'})
        break
      case 'can_create_client_invoices':
        rules.push({subject: 'ClientInvoice', action: 'create'})
        break
      case 'can_update_client_invoices':
        rules.push({subject: 'ClientInvoice', action: 'update'})
        break
      case 'can_delete_client_invoices':
        rules.push({subject: 'ClientInvoice', action: 'delete'})
        break
      case 'can_generate_client_invoices':
        rules.push({subject: 'ClientInvoice', action: 'generate'})
        break
      case 'can_close_and_transfer_client_invoices':
        rules.push({subject: 'ClientInvoice', action: 'closeAndTransfer'})
        break
      // CLIENT INVOICE ITEMS
      case 'can_list_client_invoice_items':
        rules.push({subject: 'ClientInvoiceItems', action: 'read'})
        break
      case 'can_get_client_invoice_items':
        rules.push({subject: 'ClientInvoiceItem', action: 'read'})
        break
      case 'can_create_client_invoice_items':
        rules.push({subject: 'ClientInvoiceItem', action: 'create'})
        break
      case 'can_update_client_invoice_items':
        rules.push({subject: 'ClientInvoiceItem', action: 'update'})
        break
      case 'can_delete_client_invoice_items':
        rules.push({subject: 'ClientInvoiceItem', action: 'delete'})
        break
      // BANKS
      case 'can_list_banks':
        rules.push({subject: 'Banks', action: 'read'})
        break
      case 'can_get_banks':
        rules.push({subject: 'Bank', action: 'read'})
        break
      case 'can_create_banks':
        rules.push({subject: 'Bank', action: 'create'})
        break
      case 'can_update_banks':
        rules.push({subject: 'Bank', action: 'update'})
        break
      case 'can_delete_banks':
        rules.push({subject: 'Bank', action: 'delete'})
        break
      // EFFICIENCY
      case 'can_list_efficiencies':
        rules.push({subject: 'Efficiencies', action: 'read'})
        break
      case 'can_get_efficiencies':
        rules.push({subject: 'Efficiency', action: 'read'})
        break
      case 'can_create_efficiencies':
        rules.push({subject: 'Efficiency', action: 'create'})
        break
      case 'can_update_efficiencies':
        rules.push({subject: 'Efficiency', action: 'update'})
        break
      case 'can_delete_efficiencies':
        rules.push({subject: 'Efficiency', action: 'delete'})
        break
      // EVALUATION
      case 'can_list_employee_evaluations':
        rules.push({subject: 'Evaluations', action: 'read'})
        break
      case 'can_get_employee_evaluations':
        rules.push({subject: 'Evaluation', action: 'read'})
        break
      case 'can_create_employee_evaluations':
        rules.push({subject: 'Evaluation', action: 'create'})
        break
      case 'can_update_employee_evaluations':
        rules.push({subject: 'Evaluation', action: 'update'})
        break
      case 'can_delete_employee_evaluations':
        rules.push({subject: 'Evaluation', action: 'delete'})
        break
      // QUOTES
      case 'can_list_quotes':
        rules.push({subject: 'Quotes', action: 'read'})
        break
      case 'can_get_quotes':
        rules.push({subject: 'Quote', action: 'read'})
        break
      case 'can_create_quotes':
        rules.push({subject: 'Quote', action: 'create'})
        break
      case 'can_update_quotes':
        rules.push({subject: 'Quote', action: 'update'})
        break
      case 'can_delete_quotes':
        rules.push({subject: 'Quote', action: 'delete'})
        break
      default:
        break
    }
  })
  return rules
}

export default mapPermissionsToAbilities
