import {DATE_FORMAT} from 'helper/constants'
import moment from 'moment'
import React from 'react'
import {Trans} from '@lingui/macro'

/// undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators =
  (...validators) =>
  (value) => {
    return validators?.reduce(
      (error, validator) => error || (validator && validator(value)),
      undefined
    )
  }

export const required = (value) => {
  return value || value === 0 ? undefined : <Trans>Required field</Trans>
}

export const validateEmail = (value) => {
  let regexp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

  if (!value || regexp.test(value)) {
    return undefined
  } else {
    return <Trans>Invalid e-mail address</Trans>
  }
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.new_password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}

export const maxLength = (max) => (value) => {
  return value?.length > max ? (
    <Trans>Number of characters can not be more than {max}</Trans>
  ) : undefined
}

export const validateHoursInDateTimeFormat = (value) => {
  const hours = value.split(' ')?.[1]

  if (hours?.length !== 5) return <Trans>Time must be in HH:mm format</Trans>

  return undefined
}

export const validateHoursFormat = (value) => {
  let regexp = /^([0-9]{1,3}):[0-5][0-9]$/

  if (regexp.test(value)) {
    return undefined
  } else {
    return <Trans>Time must be in HH:mm format</Trans>
  }
}

export const maxDate = (max, value, format = DATE_FORMAT) => {
  if (value) {
    if (moment(value, format).isAfter(moment(max, format))) {
      return <Trans>Date cannot be after {max}</Trans>
    }
  }
  return undefined
}
