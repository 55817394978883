import React from 'react'

/* hidden button which is triggered from outside the form / component,
   therefore it's hidden */

const SubmitLinkButton = (props) => {
  const {id, disabled = false} = props

  return <button id={id} disabled={disabled} type={'submit'} style={{display: 'none'}} />
}

export default SubmitLinkButton
