import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'

const BoxFullWidth = (props) => {
  const {classes, className, ...rest} = props

  return <Box {...rest} className={cx(classes.fullWidth, className)} />
}

BoxFullWidth.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  rest: PropTypes.object,
}

export default withStyles(materialStyle)(BoxFullWidth)
