import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {TextField, TextareaAutosize} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '@material-ui/core/styles/withStyles'

const TextInput = (props) => {
  const {
    classes,
    input,
    meta,
    type,
    helperText,
    variant = 'outlined',
    showHelperText = true,
    label,
    className,
    fullWidth = true,
    onChange,
    onCustomChange,
    value,
    inputProps,
    asterisk = false,
    loading,
    multiline,
    onCustomFocus,
    inputPaddingRightZero,
    inputClassName,
    customError,
    error,
    ...rest
  } = props

  const errorMessage =
    meta &&
    meta.touched &&
    (meta.error ||
      (meta.invalid && !meta.dirtySinceLastSubmit ? meta.submitError : false) ||
      customError)
  const errorHelperText = showHelperText && (errorMessage || helperText || customError || ' ')

  return (
    <>
      {label && (
        <div className={classes.fieldLabel}>
          {label}
          {asterisk && <span className={classes.requiredColor}> *</span>}
        </div>
      )}
      {multiline ? (
        <>
          <TextareaAutosize
            {...input}
            variant={variant}
            type={type ? type : input?.type}
            className={cx(
              classes.messageTextArea,
              className,
              'input-field-class',
              !!errorMessage && classes.messageTextAreaError,
              (input?.type === 'number' || type === 'number') && classes.inputJustifyRight,
              input?.value === '' ? classes.textAreaPlaceHolder : ''
            )}
            onChange={(e) => {
              if (e.target.value?.length <= inputProps?.maxLength) {
                input?.onChange(e)
                onCustomChange && onCustomChange(e.target.value, input?.name)
              }
            }}
            {...rest}
          />
          {showHelperText && (
            <FormHelperText
              className={cx(classes.formHelperText, errorMessage && classes.formHelperErrorText)}
            >
              {errorHelperText}
            </FormHelperText>
          )}
        </>
      ) : (
        <>
          <TextField
            {...input}
            variant={variant}
            fullWidth={fullWidth}
            type={type ? type : input?.type}
            className={cx(
              classes.inputField,
              className,
              (input?.type === 'number' || type === 'number') && classes.inputJustifyRight,
              (input?.type === 'number' || type === 'number') && 'hideNumberArrows',
              inputPaddingRightZero && classes.inputPaddingRightZero
            )}
            onChange={(e) => {
              input?.onChange(e)
              onCustomChange && onCustomChange(e.target.value, input?.name)
            }}
            onFocus={(e) => {
              input?.onFocus(e)
              onCustomFocus && onCustomFocus(e, input?.name)
            }}
            error={!!errorMessage || !!customError || !!error}
            inputProps={{
              className: inputClassName ? inputClassName : 'input-field-class',
              ...inputProps,
            }}
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="end" className={classes.loadingEndAdornment}>
                  <CircularProgress color="secondary" />
                </InputAdornment>
              ),
            }}
            {...rest}
          />
          {showHelperText && (
            <FormHelperText
              className={cx(
                classes.formHelperText,
                (errorMessage || customError) && classes.formHelperErrorText
              )}
            >
              {errorHelperText}
            </FormHelperText>
          )}
        </>
      )}
    </>
  )
}

TextInput.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  rest: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  inputPaddingRightZero: PropTypes.bool,
  inputClassName: PropTypes.string,
}

export default withStyles(fieldStyle)(TextInput)
