import {
  ANALYZE_QUOTE_FAIL,
  ANALYZE_QUOTE_REQUEST,
  ANALYZE_QUOTE_SUCCESS,
  GET_QUOTES_FAIL,
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  GET_QUOTE_FAIL,
  GET_QUOTE_REQUEST,
  GET_QUOTE_SUCCESS,
} from 'redux/actionType'

const initState = {
  quoteList: [],
  quoteListLoading: false,
  quoteListMeta: {},

  quoteDetail: {},
  quoteDetailLoading: false,

  quoteAnalyze: {},
  quoteAnalyzeLoading: false,
}

const quoteReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_QUOTES_REQUEST: {
      return {
        ...state,
        quoteListLoading: !action.loadNext,
      }
    }
    case GET_QUOTES_SUCCESS: {
      return {
        ...state,
        quoteListLoading: false,
        quoteListMeta: action.data.meta,
        quoteList: action.loadNext
          ? [...state.quoteList, ...action.data.objects]
          : action.data.objects,
      }
    }
    case GET_QUOTES_FAIL: {
      return {
        ...state,
        quoteListLoading: false,
      }
    }
    case GET_QUOTE_REQUEST: {
      return {
        ...state,
        quoteDetailLoading: true,
      }
    }
    case GET_QUOTE_SUCCESS: {
      return {
        ...state,
        quoteDetailLoading: false,
        quoteDetail: action.data,
      }
    }
    case GET_QUOTE_FAIL: {
      return {
        ...state,
        quoteDetailLoading: false,
      }
    }
    case ANALYZE_QUOTE_REQUEST: {
      return {
        ...state,
        quoteAnalyzeLoading: true,
      }
    }
    case ANALYZE_QUOTE_SUCCESS: {
      return {
        ...state,
        quoteAnalyzeLoading: false,
        quoteAnalyze: action.data,
      }
    }
    case ANALYZE_QUOTE_FAIL: {
      return {
        ...state,
        quoteAnalyzeLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default quoteReducer
