import cx from 'classnames'
import BoxFullWidth from 'component/material/BoxFullWidth'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {authorizedAbility} from 'helper/authorizedAbility'
import {redirectTo} from 'helper/functions'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {toggleShortcuts} from 'redux/action/shortcutAction'
import AccBalances from 'style/asset/account_balances.png'
import Evaluation from 'style/asset/assessment.png'
import EfficiencyIcon from 'style/asset/chart.png'
import ClockRed from 'style/asset/clock_red.png'
import FolderInvoices from 'style/asset/folder_invoices.png'
import Group from 'style/asset/group.png'
import QuoteIcon from 'style/asset/offering.png'
import Pencil from 'style/asset/pencil.png'
import Sms from 'style/asset/sms.png'
import TableMultiple from 'style/asset/table_multiple.png'
import {Trans} from '@lingui/macro'
import Drawer from '@material-ui/core/Drawer'
import {withStyles} from '@material-ui/core/styles'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const DrawerMenu = (props) => {
  const {
    classes,
    openHelpDialog,
    handleOpenHelpDialog,
    handleCloseHelpDialog,
    shortcuts,
    toggleShortcuts,
  } = props

  let location = useLocation()

  const isMenuItemActive = (route) => {
    return location.pathname.includes(route)
  }

  const handleRedirectTo = (route) => (e) => {
    redirectTo(route)
  }

  const drawer = (
    <BoxFullWidth p={6} className={classes.drawerContent}>
      <div className={classes.drawerMenuItems}>
        {[
          {
            route: '/hours',
            label: <Trans>Hours</Trans>,
            icon: <img src={ClockRed} alt={'hours'} />,
          },
          {
            route: '/work-reports',
            label: <Trans>Salary work reports</Trans>,
            icon: <img src={TableMultiple} alt={'time record reports'} />,
            authorized: authorizedAbility.can('read', 'Reports'),
          },
          {
            route: '/time-records',
            label: <Trans>Work overview</Trans>,
            icon: <img src={TableMultiple} alt={'time record overview'} />,
            authorized: authorizedAbility.can('read', 'TimeRecords'),
          },
          {
            route: '/employees',
            label: <Trans>Employees</Trans>,
            icon: <img src={Group} alt={'employees'} />,
            authorized: authorizedAbility.can('read', 'Employees'),
          },
          {
            route: '/clients',
            label: <Trans>Clients</Trans>,
            icon: <img src={AccBalances} alt={'clients'} />,
            authorized: authorizedAbility.can('read', 'Clients'),
          },
          {
            route: '/price-lists',
            label: <Trans>Price lists</Trans>,
            icon: <img src={Pencil} alt={'prices'} />,
            authorized: authorizedAbility.can('read', 'PriceLists'),
          },
          {
            route: '/invoices',
            label: <Trans>Invoices OSVC</Trans>,
            icon: <img src={FolderInvoices} alt={'invoices'} />,
            authorized: authorizedAbility.can('read', 'EmployeeInvoices'),
          },
          {
            route: '/client-invoices',
            label: <Trans>Client invoices</Trans>,
            icon: <img src={FolderInvoices} alt={'client-invoices'} />,
            authorized: authorizedAbility.can('read', 'ClientInvoices'),
          },
          {
            route: '/quotes',
            label: <Trans>Quotes</Trans>,
            icon: <img src={QuoteIcon} alt={'quotes'} />,
            authorized: authorizedAbility.can('read', 'Quotes'),
          },
          {
            route: '/efficiency',
            label: <Trans>Efficiency report</Trans>,
            icon: <img src={EfficiencyIcon} alt={'efficiency'} />,
            authorized: authorizedAbility.can('read', 'Efficiencies'),
          },
          {
            route: '/evaluations',
            label: <Trans>Evaluation</Trans>,
            icon: <img src={Evaluation} alt={'evaluations'} />,
            authorized: authorizedAbility.can('read', 'Evaluations'),
          },
          {
            route: '/personified-messages',
            label: <Trans>Personified messages</Trans>,
            icon: <img src={Sms} alt={'sms'} />,
            authorized: authorizedAbility.can('read', 'Clients'),
          },
        ].map((item, index) => {
          return (
            item.authorized !== false && (
              <div
                key={index}
                className={cx(
                  classes.drawerMenuItem,
                  isMenuItemActive(item.route) && classes.drawerMenuItemActive
                )}
                onClick={handleRedirectTo(item.route)}
              >
                <span className={cx(isMenuItemActive(item.route) && classes.drawerMenuItemActive)}>
                  {item.icon}
                </span>
                <span className={cx(classes.drawerMenuItemLabel)}>{item.label}</span>
              </div>
            )
          )
        })}
      </div>
      <div className={classes.helpButton} onClick={handleOpenHelpDialog}>
        <div className={classes.helpIcon}>
          <HelpOutlineIcon />
        </div>
        <Trans>Help</Trans>
      </div>
    </BoxFullWidth>
  )

  return (
    <>
      <DialogWindow
        title={<Trans>Keyboard shortcuts</Trans>}
        open={openHelpDialog}
        onClose={handleCloseHelpDialog}
        maxWidth={'sm'}
      >
        <GridContainer direction={'row'}>
          <GridItem xs={12}>
            <BoxFullWidth pb={2}>
              <Trans>List of control shortcuts available throughout the app.</Trans>
            </BoxFullWidth>
          </GridItem>
          <GridItem container spacing={1}>
            {[
              {
                shortcut: '?',
                action: () => toggleShortcuts(true),
                description: <Trans>Keyboard shortcuts overview</Trans>,
              },
              ...shortcuts,
            ]?.map((shortcut, index) => (
              <GridItem key={index} xs={12} container direction={'row'} alignItems={'center'}>
                <GridItem className={classes.shortcutKeyWidth}>
                  {shortcut?.shortcut instanceof Array ? (
                    shortcut?.shortcut.map((short) => (
                      <div key={short} className={classes.shortcutKey}>
                        {short}
                      </div>
                    ))
                  ) : (
                    <div className={classes.shortcutKey}>{shortcut.shortcut}</div>
                  )}
                </GridItem>
                <GridItem>{shortcut.description}</GridItem>
              </GridItem>
            ))}
          </GridItem>
        </GridContainer>
      </DialogWindow>

      <nav className={classes.drawer}>
        <Drawer classes={{paper: classes.drawerPaper}} variant="permanent" open>
          {drawer}
        </Drawer>
      </nav>
    </>
  )
}

DrawerMenu.propTypes = {
  classes: PropTypes.object,
  shortcuts: PropTypes.array,
  toggleShortcuts: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleShortcuts,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      shortcuts: store.shortcut.shortcuts,
    }
  }, mapDispatchToProps)
)(DrawerMenu)
