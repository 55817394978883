import PasswordInput from 'component/field/PasswordInput'
import SubmitLinkButton from 'component/field/SubmitLinkButton'
import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {mapErrorResponseToForm} from 'helper/functions'
import {required, samePasswordValidation} from 'helper/validations'
import PropTypes from 'prop-types'
import React from 'react'
import {Field, Form} from 'react-final-form'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {changeMyPassword} from 'redux/action/authAction'
import {Trans} from '@lingui/macro'

const ChangeMyPasswordDialog = (props) => {
  const {open, onClose, changeMyPassword} = props

  const handleSubmit = (val) => {
    return changeMyPassword({new_password: val.new_password, old_password: val.old_password})
      .then((res) => {
        onClose()
      })
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  return (
    <DialogWindow
      title={<Trans>Change my password</Trans>}
      open={open}
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth={true}
      dialogActions={
        <GridContainer spacing={4} justifyContent={'flex-end'}>
          <GridItem>
            <PrimaryButton
              onClick={onClose}
              variant={'outlined'}
              size={'small'}
              fullWidth={false}
              text={<Trans>Close</Trans>}
            />
          </GridItem>
          <GridItem>
            <PrimaryButton
              type="submit"
              onClick={() => document.getElementById('change-password-submit-button').click()}
              size={'small'}
              fullWidth={false}
              text={<Trans>Save</Trans>}
            />
          </GridItem>
        </GridContainer>
      }
    >
      <Form onSubmit={handleSubmit} validate={samePasswordValidation}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer spacing={4} direction={'row'}>
                <GridItem xs={12}>
                  <Field
                    name="old_password"
                    label={<Trans>Old password</Trans>}
                    component={PasswordInput}
                    validate={required}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="new_password"
                    label={<Trans>New password</Trans>}
                    component={PasswordInput}
                    validate={required}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="password_confirmation"
                    label={<Trans>Password confirmation</Trans>}
                    component={PasswordInput}
                    validate={required}
                  />
                </GridItem>
                <SubmitLinkButton
                  id={'change-password-submit-button'}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </DialogWindow>
  )
}

ChangeMyPasswordDialog.propTypes = {
  changeMyPassword: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      changeMyPassword,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(ChangeMyPasswordDialog)
