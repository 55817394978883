import historyStyles from 'component/History/historyStyles'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomCard from 'component/material/CustomCard'
import CustomTooltip from 'component/material/CustomTooltip'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Loader from 'component/material/Loader'
import PrimaryButton from 'component/material/PrimaryButton'
import {DATE_TIME_FORMAT} from 'helper/constants'
import {getHighestDate, getName, isObjectEmpty} from 'helper/functions'
import moment from 'moment/moment'
import React from 'react'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const HistoryRecordDiffCard = (props) => {
  const {
    classes,
    diffToDisplay,
    diffLoading,
    diffData,
    diffAttributes,
    historyData,
    handleOpenRestoreDialog,
    getCurrentValue,
    getChangedValue,
  } = props

  const hadValidTo = diffToDisplay?.valid_to !== null

  return (
    <CustomCard
      title={
        <>
          <Trans>Record overview from</Trans>
          {diffToDisplay?.valid_from && (
            <>
              {' '}
              {moment(diffToDisplay?.valid_from).format(DATE_TIME_FORMAT)}
              <b>{` (${getHighestDate(moment(diffToDisplay?.valid_from))}) `}</b>
            </>
          )}
          {diffToDisplay?.edited_by?.id && (
            <>
              <Trans>edited by</Trans>
              {` ${getName(diffToDisplay?.edited_by)}`}
            </>
          )}
        </>
      }
      content={
        <>
          {diffLoading ? (
            <Loader />
          ) : historyData?.length > 0 ? (
            <>
              <GridContainer className={classes.historyHeadRow}>
                <GridItem xs={4}>
                  <b>
                    <Trans>Attribute name</Trans>
                  </b>
                </GridItem>
                <GridItem xs={4}>
                  <b>
                    <Trans>Current value</Trans>
                  </b>
                </GridItem>
                {hadValidTo && (
                  <GridItem xs={4}>
                    <b>
                      <Trans>History value</Trans>
                    </b>
                  </GridItem>
                )}
              </GridContainer>

              {!isObjectEmpty(diffData) &&
                diffAttributes.map(
                  (row, index) =>
                    (row.authorized === true || row.authorized === undefined) && (
                      <GridContainer key={index} className={classes.historyRow}>
                        <GridItem xs={4}>{row.label}</GridItem>
                        <GridItem xs={4}>{getCurrentValue(diffData, row)}</GridItem>
                        {hadValidTo && (
                          <GridItem xs={4} className={classes.historyValue}>
                            {getChangedValue(diffData, row)}
                          </GridItem>
                        )}
                      </GridContainer>
                    )
                )}
              {hadValidTo && (
                <BoxFullWidth pt={3}>
                  <GridContainer justifyContent={'flex-end'}>
                    <GridItem>
                      <PrimaryButton
                        onClick={handleOpenRestoreDialog}
                        size={'small'}
                        fullWidth={false}
                        text={
                          <CustomTooltip title={<Trans>Restore selected version of history</Trans>}>
                            <Trans>Restore</Trans>
                          </CustomTooltip>
                        }
                      />
                    </GridItem>
                  </GridContainer>
                </BoxFullWidth>
              )}
            </>
          ) : (
            <div className={classes.noHistoryRecord}>
              <Trans>No history yet</Trans>
            </div>
          )}
        </>
      }
      additionalCardHeaderClass={classes.recordOverviewHeader}
      additionalCardContentClass={
        historyData?.length > 0 ? classes.recordOverviewContent : classes.historyRecordsContent
      }
    />
  )
}

export default withStyles(historyStyles)(HistoryRecordDiffCard)
