// auth action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS'

// GLOBAL LOADING DISPLAYING LOADING LINE IN TOP APP BAR
export const UPDATE_GLOBAL_LOADING = 'UPDATE_GLOBAL_LOADING'

// KEEPING UP TO DATE UNREAD MESSAGE BADGE IN TOP BAR
export const UPDATE_MESSAGE_NUMBER = 'UPDATE_MESSAGE_NUMBER'

// KEEPING NAMES INSTEAD OF IDS IN BREADCRUMBS
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS'

// SHORTCUTS
export const UPDATE_SHORTCUTS = 'UPDATE_SHORTCUTS'
export const TOGGLE_SHORTCUT_HELP = 'TOGGLE_SHORTCUT_HELP'

// EMPLOYEE
export const GET_LIST_EMPLOYEE_REQUEST = 'GET_LIST_EMPLOYEE_REQUEST'
export const GET_LIST_EMPLOYEE_SUCCESS = 'GET_LIST_EMPLOYEE_SUCCESS'
export const GET_LIST_EMPLOYEE_FAIL = 'GET_LIST_EMPLOYEE_FAIL'

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_FAIL = 'GET_EMPLOYEE_FAIL'

export const GET_EMPLOYEE_POSITION_REQUEST = 'GET_EMPLOYEE_POSITION_REQUEST'
export const GET_EMPLOYEE_POSITION_SUCCESS = 'GET_EMPLOYEE_POSITION_SUCCESS'
export const GET_EMPLOYEE_POSITION_FAIL = 'GET_EMPLOYEE_POSITION_FAIL'

export const GET_EMPLOYEE_VERSIONS_REQUEST = 'GET_EMPLOYEE_VERSIONS_REQUEST'
export const GET_EMPLOYEE_VERSIONS_SUCCESS = 'GET_EMPLOYEE_VERSIONS_SUCCESS'
export const GET_EMPLOYEE_VERSIONS_FAIL = 'GET_EMPLOYEE_VERSIONS_FAIL'

export const GET_EMPLOYEE_VERSION_DIFF_REQUEST = 'GET_EMPLOYEE_VERSION_DIFF_REQUEST'
export const GET_EMPLOYEE_VERSION_DIFF_SUCCESS = 'GET_EMPLOYEE_VERSION_DIFF_SUCCESS'
export const GET_EMPLOYEE_VERSION_DIFF_FAIL = 'GET_EMPLOYEE_VERSION_DIFF_FAIL'

export const GET_EMPLOYEE_LIST_ONLY_REQUEST = 'GET_EMPLOYEE_LIST_ONLY_REQUEST'
export const GET_EMPLOYEE_LIST_ONLY_SUCCESS = 'GET_EMPLOYEE_LIST_ONLY_SUCCESS'
export const GET_EMPLOYEE_LIST_ONLY_FAIL = 'GET_EMPLOYEE_LIST_ONLY_FAIL'

export const GET_EMPLOYEE_INVOICE_LIST_REQUEST = 'GET_EMPLOYEE_INVOICE_LIST_REQUEST'
export const GET_EMPLOYEE_INVOICE_LIST_SUCCESS = 'GET_EMPLOYEE_INVOICE_LIST_SUCCESS'
export const GET_EMPLOYEE_INVOICE_LIST_FAIL = 'GET_EMPLOYEE_INVOICE_LIST_FAIL'

export const GET_EMPLOYEES_INVOICES_REQUEST = 'GET_EMPLOYEES_INVOICES_REQUEST'
export const GET_EMPLOYEES_INVOICES_SUCCESS = 'GET_EMPLOYEES_INVOICES_SUCCESS'
export const GET_EMPLOYEES_INVOICES_FAIL = 'GET_EMPLOYEES_INVOICES_FAIL'

export const GET_EMPLOYEE_INVOICE_REQUEST = 'GET_EMPLOYEE_INVOICE_REQUEST'
export const GET_EMPLOYEE_INVOICE_SUCCESS = 'GET_EMPLOYEE_INVOICE_SUCCESS'
export const GET_EMPLOYEE_INVOICE_FAIL = 'GET_EMPLOYEE_INVOICE_FAIL'

// BILLING COMPANIES
export const GET_BILLING_COMPANIES_REQUEST = 'GET_BILLING_COMPANIES_REQUEST'
export const GET_BILLING_COMPANIES_SUCCESS = 'GET_BILLING_COMPANIES_SUCCESS'
export const GET_BILLING_COMPANIES_FAIL = 'GET_BILLING_COMPANIES_FAIL'

// MESSAGE INBOX
export const GET_LIST_INBOX_REQUEST = 'GET_LIST_INBOX_REQUEST'
export const GET_LIST_INBOX_SUCCESS = 'GET_LIST_INBOX_SUCCESS'
export const GET_LIST_INBOX_FAIL = 'GET_LIST_INBOX_FAIL'

export const GET_INBOX_REQUEST = 'GET_INBOX_REQUEST'
export const GET_INBOX_SUCCESS = 'GET_INBOX_SUCCESS'
export const GET_INBOX_FAIL = 'GET_INBOX_FAIL'

// PRICE LISTS
export const GET_LIST_PRICE_LIST_REQUEST = 'GET_LIST_PRICE_LIST_REQUEST'
export const GET_LIST_PRICE_LIST_SUCCESS = 'GET_LIST_PRICE_LIST_SUCCESS'
export const GET_LIST_PRICE_LIST_FAIL = 'GET_LIST_PRICE_LIST_FAIL'

export const GET_LIST_PRICE_REQUEST = 'GET_LIST_PRICE_REQUEST'
export const GET_LIST_PRICE_SUCCESS = 'GET_LIST_PRICE_SUCCESS'
export const GET_LIST_PRICE_FAIL = 'GET_LIST_PRICE_FAIL'

export const GET_PRICE_LIST_REQUEST = 'GET_PRICE_LIST_REQUEST'
export const GET_PRICE_LIST_SUCCESS = 'GET_PRICE_LIST_SUCCESS'
export const GET_PRICE_LIST_FAIL = 'GET_PRICE_LIST_FAIL'

export const GET_PRICE_REQUEST = 'GET_PRICE_REQUEST'
export const GET_PRICE_SUCCESS = 'GET_PRICE_SUCCESS'
export const GET_PRICE_FAIL = 'GET_PRICE_FAIL'

export const GET_PRICE_VERSIONS_REQUEST = 'GET_PRICE_VERSIONS_REQUEST'
export const GET_PRICE_VERSIONS_SUCCESS = 'GET_PRICE_VERSIONS_SUCCESS'
export const GET_PRICE_VERSIONS_FAIL = 'GET_PRICE_VERSIONS_FAIL'
export const GET_PRICE_VERSION_DIFF_REQUEST = 'GET_PRICE_VERSION_DIFF_REQUEST'
export const GET_PRICE_VERSION_DIFF_SUCCESS = 'GET_PRICE_VERSION_DIFF_SUCCESS'
export const GET_PRICE_VERSION_DIFF_FAIL = 'GET_PRICE_VERSION_DIFF_FAIL'

// CLIENTS
export const GET_LIST_CLIENT_REQUEST = 'GET_LIST_CLIENT_REQUEST'
export const GET_LIST_CLIENT_SUCCESS = 'GET_LIST_CLIENT_SUCCESS'
export const GET_LIST_CLIENT_FAIL = 'GET_LIST_CLIENT_FAIL'

export const GET_CLIENT_REQUEST = 'GET_CLIENT_REQUEST'
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS'
export const GET_CLIENT_FAIL = 'GET_CLIENT_FAIL'

export const GET_ARES_CLIENT_REQUEST = 'GET_ARES_CLIENT_REQUEST'
export const GET_ARES_CLIENT_SUCCESS = 'GET_ARES_CLIENT_SUCCESS'
export const GET_ARES_CLIENT_FAIL = 'GET_ARES_CLIENT_FAIL'

export const GET_CLIENTS_TO_VERIFY_REQUEST = 'GET_CLIENTS_TO_VERIFY_REQUEST'
export const GET_CLIENTS_TO_VERIFY_SUCCESS = 'GET_CLIENTS_TO_VERIFY_SUCCESS'
export const GET_CLIENTS_TO_VERIFY_FAIL = 'GET_CLIENTS_TO_VERIFY_FAIL'

export const GET_CLIENT_VERSIONS_REQUEST = 'GET_CLIENT_VERSIONS_REQUEST'
export const GET_CLIENT_VERSIONS_SUCCESS = 'GET_CLIENT_VERSIONS_SUCCESS'
export const GET_CLIENT_VERSIONS_FAIL = 'GET_CLIENT_VERSIONS_FAIL'

export const GET_CLIENT_VERSION_DIFF_REQUEST = 'GET_CLIENT_VERSION_DIFF_REQUEST'
export const GET_CLIENT_VERSION_DIFF_SUCCESS = 'GET_CLIENT_VERSION_DIFF_SUCCESS'
export const GET_CLIENT_VERSION_DIFF_FAIL = 'GET_CLIENT_VERSION_DIFF_FAIL'

export const GET_CLIENT_CONTACT_VERSIONS_REQUEST = 'GET_CLIENT_CONTACT_VERSIONS_REQUEST'
export const GET_CLIENT_CONTACT_VERSIONS_SUCCESS = 'GET_CLIENT_CONTACT_VERSIONS_SUCCESS'
export const GET_CLIENT_CONTACT_VERSIONS_FAIL = 'GET_CLIENT_CONTACT_VERSIONS_FAIL'

export const GET_CLIENT_CONTACT_VERSION_DIFF_REQUEST = 'GET_CLIENT_CONTACT_VERSION_DIFF_REQUEST'
export const GET_CLIENT_CONTACT_VERSION_DIFF_SUCCESS = 'GET_CLIENT_CONTACT_VERSION_DIFF_SUCCESS'
export const GET_CLIENT_CONTACT_VERSION_DIFF_FAIL = 'GET_CLIENT_CONTACT_VERSION_DIFF_FAIL'

export const POST_SYNC_SMARTEMAILING_REQUEST = 'POST_SYNC_SMARTEMAILING_REQUEST'
export const POST_SYNC_SMARTEMAILING_SUCCESS = 'POST_SYNC_SMARTEMAILING_SUCCESS'
export const POST_SYNC_SMARTEMAILING_FAIL = 'POST_SYNC_SMARTEMAILING_FAIL'

export const GET_SMARTEMAILING_STATUS_REQUEST = 'GET_SMARTEMAILING_STATUS_REQUEST'
export const GET_SMARTEMAILING_STATUS_SUCCESS = 'GET_SMARTEMAILING_STATUS_SUCCESS'
export const GET_SMARTEMAILING_STATUS_FAIL = 'GET_SMARTEMAILING_STATUS_FAIL'

export const GET_CLIENT_LIST_ONLY_REQUEST = 'GET_CLIENT_LIST_ONLY_REQUEST'
export const GET_CLIENT_LIST_ONLY_SUCCESS = 'GET_CLIENT_LIST_ONLY_SUCCESS'
export const GET_CLIENT_LIST_ONLY_FAIL = 'GET_CLIENT_LIST_ONLY_FAIL'

export const GET_CLIENT_TAX_DATES_REQUEST = 'GET_CLIENT_TAX_DATES_REQUEST'
export const GET_CLIENT_TAX_DATES_SUCCESS = 'GET_CLIENT_TAX_DATES_SUCCESS'
export const GET_CLIENT_TAX_DATES_FAIL = 'GET_CLIENT_TAX_DATES_FAIL'

export const GET_CLIENTS_INVOICES_REQUEST = 'GET_CLIENTS_INVOICES_REQUEST'
export const GET_CLIENTS_INVOICES_SUCCESS = 'GET_CLIENTS_INVOICES_SUCCESS'
export const GET_CLIENTS_INVOICES_FAIL = 'GET_CLIENTS_INVOICES_FAIL'

export const GET_CLIENT_INVOICE_REQUEST = 'GET_CLIENT_INVOICE_REQUEST'
export const GET_CLIENT_INVOICE_SUCCESS = 'GET_CLIENT_INVOICE_SUCCESS'
export const GET_CLIENT_INVOICE_FAIL = 'GET_CLIENT_INVOICE_FAIL'

export const POST_CLOSE_CLIENT_INVOICE_LOADING = 'POST_CLOSE_CLIENT_INVOICE_LOADING'

export const GET_CLIENT_INVOICE_LIST_REQUEST = 'GET_CLIENT_INVOICE_LIST_REQUEST'
export const GET_CLIENT_INVOICE_LIST_SUCCESS = 'GET_CLIENT_INVOICE_LIST_SUCCESS'
export const GET_CLIENT_INVOICE_LIST_FAIL = 'GET_CLIENT_INVOICE_LIST_FAIL'

export const GET_CLIENT_INVOICE_ITEMS_REQUEST = 'GET_CLIENT_INVOICE_ITEMS_REQUEST'
export const GET_CLIENT_INVOICE_ITEMS_SUCCESS = 'GET_CLIENT_INVOICE_ITEMS_SUCCESS'
export const GET_CLIENT_INVOICE_ITEMS_FAIL = 'GET_CLIENT_INVOICE_ITEMS_FAIL'

// TIME RECORDS - HOURS
export const GET_LIST_HOURS_REQUEST = 'GET_LIST_HOURS_REQUEST'
export const GET_LIST_HOURS_SUCCESS = 'GET_LIST_HOURS_SUCCESS'
export const GET_LIST_HOURS_FAIL = 'GET_LIST_HOURS_FAIL'

export const GET_TIMERECORD_REQUEST = 'GET_TIMERECORD_REQUEST'
export const GET_TIMERECORD_SUCCESS = 'GET_TIMERECORD_SUCCESS'
export const GET_TIMERECORD_FAIL = 'GET_TIMERECORD_FAIL'

export const GET_EMPLOYEE_VAT_PERIOD_REQUEST = 'GET_EMPLOYEE_VAT_PERIOD_REQUEST'
export const GET_EMPLOYEE_VAT_PERIOD_SUCCESS = 'GET_EMPLOYEE_VAT_PERIOD_SUCCESS'
export const GET_EMPLOYEE_VAT_PERIOD_FAIL = 'GET_EMPLOYEE_VAT_PERIOD_FAIL'

export const GET_EMPLOYEE_BILLING_FREQUENCY_REQUEST = 'GET_EMPLOYEE_BILLING_FREQUENCY_REQUEST'
export const GET_EMPLOYEE_BILLING_FREQUENCY_SUCCESS = 'GET_EMPLOYEE_BILLING_FREQUENCY_SUCCESS'
export const GET_EMPLOYEE_BILLING_FREQUENCY_FAIL = 'GET_EMPLOYEE_BILLING_FREQUENCY_FAIL'

export const GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_REQUEST =
  'GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_REQUEST'
export const GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_SUCCESS =
  'GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_SUCCESS'
export const GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_FAIL =
  'GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_FAIL'

// TIME RECORDS - REPORT
export const GET_LIST_REPORT_REQUEST = 'GET_LIST_REPORT_REQUEST'
export const GET_LIST_REPORT_SUCCESS = 'GET_LIST_REPORT_SUCCESS'
export const GET_LIST_REPORT_FAIL = 'GET_LIST_REPORT_FAIL'

export const GET_TIMERECORD_VERSIONS_REQUEST = 'GET_TIMERECORD_VERSIONS_REQUEST'
export const GET_TIMERECORD_VERSIONS_SUCCESS = 'GET_TIMERECORD_VERSIONS_SUCCESS'
export const GET_TIMERECORD_VERSIONS_FAIL = 'GET_TIMERECORD_VERSIONS_FAIL'

export const GET_TIMERECORD_VERSION_DIFF_REQUEST = 'GET_TIMERECORD_VERSION_DIFF_REQUEST'
export const GET_TIMERECORD_VERSION_DIFF_SUCCESS = 'GET_TIMERECORD_VERSION_DIFF_SUCCESS'
export const GET_TIMERECORD_VERSION_DIFF_FAIL = 'GET_TIMERECORD_VERSION_DIFF_FAIL'

// CONSTANTS
export const GET_LIST_CONSTANTS_REQUEST = 'GET_LIST_CONSTANTS_REQUEST'
export const GET_LIST_CONSTANTS_SUCCESS = 'GET_LIST_CONSTANTS_SUCCESS'
export const GET_LIST_CONSTANTS_FAIL = 'GET_LIST_CONSTANTS_FAIL'

// BANK
export const GET_BANKS_REQUEST = 'GET_BANKS_REQUEST'
export const GET_BANKS_SUCCESS = 'GET_BANKS_SUCCESS'
export const GET_BANKS_FAIL = 'GET_BANKS_FAIL'

// QUOTE
export const GET_QUOTES_REQUEST = 'GET_QUOTES_REQUEST'
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS'
export const GET_QUOTES_FAIL = 'GET_QUOTES_FAIL'

export const GET_QUOTE_REQUEST = 'GET_QUOTE_REQUEST'
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS'
export const GET_QUOTE_FAIL = 'GET_QUOTE_FAIL'

export const ANALYZE_QUOTE_REQUEST = 'ANALYZE_QUOTE_REQUEST'
export const ANALYZE_QUOTE_SUCCESS = 'ANALYZE_QUOTE_SUCCESS'
export const ANALYZE_QUOTE_FAIL = 'ANALYZE_QUOTE_FAIL'

// EVALUATION
export const GET_EVALUATIONS_REQUEST = 'GET_EVALUATIONS_REQUEST'
export const GET_EVALUATIONS_SUCCESS = 'GET_EVALUATIONS_SUCCESS'
export const GET_EVALUATIONS_FAIL = 'GET_EVALUATIONS_FAIL'

export const GET_EVALUATION_REQUEST = 'GET_EVALUATION_REQUEST'
export const GET_EVALUATION_SUCCESS = 'GET_EVALUATION_SUCCESS'
export const GET_EVALUATION_FAIL = 'GET_EVALUATION_FAIL'

// EFFICIENCY
export const GET_EFFICIENCY_REQUEST = 'GET_EFFICIENCY_REQUEST'
export const GET_EFFICIENCY_SUCCESS = 'GET_EFFICIENCY_SUCCESS'
export const GET_EFFICIENCY_FAIL = 'GET_EFFICIENCY_FAIL'

export const GET_EFFICIENCY_DETAIL_REQUEST = 'GET_EFFICIENCY_DETAIL_REQUEST'
export const GET_EFFICIENCY_DETAIL_SUCCESS = 'GET_EFFICIENCY_DETAIL_SUCCESS'
export const GET_EFFICIENCY_DETAIL_FAIL = 'GET_EFFICIENCY_DETAIL_FAIL'

export const PATCH_EFFICIENCY_DETAIL_SUCCESS = 'PATCH_EFFICIENCY_DETAIL_SUCCESS'

export const GET_EFFICIENCY_DETAIL_ITEMS_REQUEST = 'GET_EFFICIENCY_DETAIL_ITEMS_REQUEST'
export const GET_EFFICIENCY_DETAIL_ITEMS_SUCCESS = 'GET_EFFICIENCY_DETAIL_ITEMS_SUCCESS'
export const GET_EFFICIENCY_DETAIL_ITEMS_FAIL = 'GET_EFFICIENCY_DETAIL_ITEMS_FAIL'
