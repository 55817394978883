// accessing theme which is active in ThemeProvider
const publicLayoutStyle = (theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: theme.palette.loginBgColor,
  },

  loginPageContainer: {
    minWidth: 450,
    width: 450,
  },

  loginPagePaper: {
    backgroundColor: theme.palette.loginBoxBgColor,
  },

  loginText: {
    fontSize: 24,
    color: theme.palette.text.primary,
  },

  resetPasswordLink: {
    cursor: 'pointer',
    fontSize: 14,
    color: theme.palette.primary.main,
    fontWeight: 500,
    width: 'auto',
  },
})

export default publicLayoutStyle
