import materialStyle from 'component/material/materialStyle'
import React from 'react'
import {Typography} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import {Close} from '@material-ui/icons'

const DialogWindow = (props) => {
  const {classes, title, open, onClose, children, dialogActions, ...rest} = props

  return (
    <Dialog onClose={onClose} open={open} {...rest}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">{title}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      {dialogActions && (
        <DialogActions className={classes.dialogActions}>{dialogActions}</DialogActions>
      )}
    </Dialog>
  )
}

export default withStyles(materialStyle)(DialogWindow)
