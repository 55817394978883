import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomTableRow = (props) => {
  const {classes, children, className, ...rest} = props

  return (
    <TableRow {...rest} className={cx(classes.tableRow, className)}>
      {children}
    </TableRow>
  )
}

CustomTableRow.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
}
export default withStyles(materialStyle)(CustomTableRow)
