import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import moment from 'moment'
import {
  GET_LIST_REPORT_FAIL,
  GET_LIST_REPORT_REQUEST,
  GET_LIST_REPORT_SUCCESS,
  GET_TIMERECORD_VERSIONS_FAIL,
  GET_TIMERECORD_VERSIONS_REQUEST,
  GET_TIMERECORD_VERSIONS_SUCCESS,
  GET_TIMERECORD_VERSION_DIFF_FAIL,
  GET_TIMERECORD_VERSION_DIFF_REQUEST,
  GET_TIMERECORD_VERSION_DIFF_SUCCESS,
  UPDATE_GLOBAL_LOADING,
} from 'redux/actionType'

export const getTimeRecordReportList = (limit, offset, meta, filter, deleted) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_REPORT_REQUEST})
    return httpClient
      .get(endpoints.reports, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        employee_cooperation_type:
          filter.employee_cooperation_type !== 'all' ? filter.employee_cooperation_type : null,
        month: filter.month || null,
        year: filter.year || null,
        show_deleted: deleted || null,
      })
      .then((res) => {
        dispatch({type: GET_LIST_REPORT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_REPORT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postTimeRecordReport = (filter) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})

    let urlParams = '?'
    if (filter.employee_cooperation_type !== 'all')
      urlParams += `employee_cooperation_type=${filter.employee_cooperation_type}`
    if (filter.month) urlParams += `&month=${filter.month}`
    if (filter.year) urlParams += `&year=${filter.year}`

    return httpClient
      .post(endpoints.reports + urlParams)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getSingleTimeRecord = (filter) => {
  return (dispatch) => {
    return httpClient
      .get(endpoints.reports, {
        month: filter.month || null,
        year: filter.year || null,
        employee_id: filter.employee_id || null,
      })
      .then((res) => {
        return Promise.resolve(res?.data?.objects)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const exportTimeRecordReport = (filter) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(
        endpoints.reports + '/export-xlsx',
        {
          order_by: 'employee__contact__last_name:ASC',
          employee_cooperation_type:
            filter.employee_cooperation_type !== 'all' ? filter.employee_cooperation_type : null,
          month: filter.month || null,
          year: filter.year || null,
          limit: 1000,
        },
        {
          headers: {
            'Content-Type': 'application/xlsx',
            Accept: 'application/xlsx',
            tz: moment()?.format('Z'),
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

// ----------------------------------------------------------------------
// History
// ----------------------------------------------------------------------
export const getTimeRecordVersions = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_TIMERECORD_VERSIONS_REQUEST})
    return httpClient
      .get(`${endpoints.timeRecords}/${id}/versions`, {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_TIMERECORD_VERSIONS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_TIMERECORD_VERSIONS_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getTimeRecordVersionsDiff = (id, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_TIMERECORD_VERSION_DIFF_REQUEST})
    return httpClient
      .get(`${endpoints.timeRecords}/${id}/versions/${versionId}/diff`)
      .then((res) => {
        dispatch({type: GET_TIMERECORD_VERSION_DIFF_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_TIMERECORD_VERSION_DIFF_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getTimeRecordVersionsRollback = (id, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.timeRecords}/${id}/versions/${versionId}/rollback`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
