import {combineReducers} from 'redux'
import authReducer from 'redux/reducer/authReducer'
import bankReducer from 'redux/reducer/bankReducer'
import breadcrumbsReducer from 'redux/reducer/breadcrumbsReducer'
import clientInvoiceReducer from 'redux/reducer/clientInvoiceReducer'
import clientReducer from 'redux/reducer/clientReducer'
import constantReducer from 'redux/reducer/constantReducer'
import efficiencyReducer from 'redux/reducer/efficiencyReducer'
import employeeInvoicesReducer from 'redux/reducer/employeeInvoiceReducer'
import employeeReducer from 'redux/reducer/employeeReducer'
import evaluationReducer from 'redux/reducer/evaluationReducer'
import globalLoadingReducer from 'redux/reducer/globalLoadingReducer'
import globalSettingReducer from 'redux/reducer/globalSettingReducer'
import hourReducer from 'redux/reducer/hourReducer'
import inboxReducer from 'redux/reducer/inboxReducer'
import priceListReducer from 'redux/reducer/priceListReducer'
import quoteReducer from 'redux/reducer/quoteReducer'
import shortcutReducer from 'redux/reducer/shortcutReducer'
import timeRecordReportReducer from 'redux/reducer/timeRecordReportReducer'

export default combineReducers({
  auth: authReducer,
  globalSetting: globalSettingReducer,
  globalLoading: globalLoadingReducer,
  message: inboxReducer,
  employee: employeeReducer,
  employeeInvoices: employeeInvoicesReducer,
  shortcut: shortcutReducer,
  priceList: priceListReducer,
  breadcrumbs: breadcrumbsReducer,
  client: clientReducer,
  clientInvoices: clientInvoiceReducer,
  hour: hourReducer,
  timeRecord: timeRecordReportReducer,
  constant: constantReducer,
  banks: bankReducer,
  quote: quoteReducer,
  efficiency: efficiencyReducer,
  evaluation: evaluationReducer,
})
