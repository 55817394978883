// accessing theme which is active in ThemeProvider
import {DRAWER_WIDTH, TOP_APP_BAR_HEIGHT} from 'helper/constants'

const LOADING_TRANSITION_TIME = '1100ms'

const privateLayoutStyle = (theme) => ({
  loadingMask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: theme.palette.white,
    transition: 'opacity 1800ms',
    height: '100vh',
    width: '100vw',
    zIndex: 1250,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },

  loadingMaskFade: {
    transition: 'opacity 1800ms',
    opacity: 1,
  },

  root: {
    display: 'flex',
    height: `calc(100vh - ${TOP_APP_BAR_HEIGHT}px)`,
    flexDirection: 'row',
    color: theme.palette.text.primary,
  },

  topAppBar: {
    height: TOP_APP_BAR_HEIGHT,
    width: '100%',
    backgroundColor: theme.palette.background.menu,
    borderBottom: '1px solid ' + theme.palette.primary.dark + 'cc',
  },

  topAppBarLinearProgress: {
    width: '100%',
    position: 'absolute',
  },

  topBarLogo: {
    height: TOP_APP_BAR_HEIGHT,
    width: 320,
    position: 'absolute',
    top: '0',
    left: '0',
    transition: `top ${LOADING_TRANSITION_TIME}, left ${LOADING_TRANSITION_TIME}, width ${LOADING_TRANSITION_TIME}, height ${LOADING_TRANSITION_TIME}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300,

    '& img': {
      width: 280,
      height: 'auto',
      transition: `width ${LOADING_TRANSITION_TIME}, height ${LOADING_TRANSITION_TIME}`,
    },
  },

  topAppBarVersion: {
    paddingLeft: 320,
    display: 'flex',
    alignItems: 'center',
    height: TOP_APP_BAR_HEIGHT,
    color: theme.palette.text.hint,
    padding: '2px 10px 0 10px',
    boxSizing: 'border-box',
  },

  topAppBarRightSide: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  topAppBarLoggedIn: {
    display: 'flex',
    alignItems: 'center',
    height: TOP_APP_BAR_HEIGHT,
    color: theme.palette.text.primary,
    padding: '0 10px',
    borderRight: '1px solid ' + theme.palette.primary.main + '40',
  },

  topAppBarLoggedInText: {
    paddingLeft: 5,
  },

  topAppBarClientsToVerify: {
    borderLeft: '1px solid ' + theme.palette.primary.main + '40',
  },

  topAppBarNewMessage: {
    color: theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid ' + theme.palette.primary.main + '40',
    borderRight: '1px solid ' + theme.palette.primary.main + '40',
  },

  topAppBarLogoutIcon: {
    height: TOP_APP_BAR_HEIGHT,
    width: TOP_APP_BAR_HEIGHT,
    borderRadius: 0,
  },

  loadingTopBarLogo: {
    '& img': {
      width: 400,
      height: 35,
      transition: `width ${LOADING_TRANSITION_TIME}, height ${LOADING_TRANSITION_TIME}`,
    },

    width: 400,
    top: `calc(50% - 64px - 50px)`,
    left: 'calc(50% - 200px)',
    transition: `top ${LOADING_TRANSITION_TIME}, left ${LOADING_TRANSITION_TIME}, width ${LOADING_TRANSITION_TIME}, height ${LOADING_TRANSITION_TIME}`,
  },

  drawer: {
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.menu,
      borderRight: 'none',
      boxSizing: 'border-box',
    },
    width: DRAWER_WIDTH,
    flexShrink: 0,
    height: 'auto',
  },

  shortcutKeyWidth: {
    minWidth: 90,
    textAlign: 'end',
    marginRight: 5,
  },

  shortcutKey: {
    display: 'inline-block',
    color: theme.palette.white,
    backgroundColor: theme.palette.text.primary,
    border: '1px solid ' + theme.palette.text.primary,
    borderRadius: 5,
    textAlign: 'center',
    marginRight: 5,
    boxShadow: 'inset 0 1px 0 #666, 0 1px 0 #bbb',
    padding: '5px 9px',
  },

  drawerContent: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: '100vw',
    maxHeight: `calc(100vh - ${TOP_APP_BAR_HEIGHT}px)`,
    padding: 0,
  },

  drawerLogo: {
    padding: '16px 0 35px',
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },

  drawerMenuItems: {
    flexGrow: 1,
    overflowY: 'auto',

    '& img': {
      width: 32,
      height: 32,
    },
  },

  drawerMenuItem: {
    cursor: 'pointer',
    color: theme.palette.text.primary,
    height: 32,
    padding: '10px 16px',
    display: 'flex',
    fontWeight: 400,
    alignItems: 'center',
    transition: 'background-color 200ms, color 200ms',

    '&:last-child': {
      borderBottom: 'none',
    },

    '&:hover': {
      color: theme.palette.text.black,
      backgroundColor: theme.palette.primary.main + '40',
      transition: 'background-color 200ms, color 200ms',
    },

    '& span': {
      height: 28,
      display: 'flex',
      alignItems: 'center',

      '& img': {
        width: 28,
        height: 28,
      },
    },
  },

  drawerMenuItemActive: {
    color: theme.palette.text.black,
    transition: 'background-color 200ms, color 200ms',
    backgroundColor: theme.palette.primary.main + '40',
    '& span': {
      // transparent span to not mess the opacity
      backgroundColor: theme.palette.primary.main + '00',
    },
  },

  drawerMenuItemLabel: {
    paddingLeft: 15,
  },

  drawerPositionRelative: {
    position: 'relative',
  },

  drawerUser: {
    cursor: 'pointer',
  },

  drawerUserName: {
    fontWeight: 700,
    width: 'max-content',
  },

  drawerLogout: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    width: 'max-content',
    '&:hover': {
      fontWeight: 500,
    },
  },

  drawerPaper: {
    width: DRAWER_WIDTH,
    top: TOP_APP_BAR_HEIGHT,
  },
  mainSectionContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
  },

  header: {
    flexGrow: 1,
    padding: theme.spacing(4),
    backgroundColor: '#f1f6fd',
    display: 'flex',
    alignItems: 'flex-start',
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
    height: 65,
    boxSizing: 'border-box',
    borderLeft: '1px solid ' + theme.palette.primary.dark + 'cc',
    borderBottom: '1px solid ' + theme.palette.primary.main + 90,
    overflowY: 'hidden',
    position: 'relative',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.content,
    display: 'flex',
    alignItems: 'flex-start',
    width: `calc(100vw - ${DRAWER_WIDTH}px)`,
    height: `calc(100vh - ${TOP_APP_BAR_HEIGHT}px - 65px)`,
    boxSizing: 'border-box',
    borderLeft: '1px solid ' + theme.palette.primary.dark + 'cc',
    overflow: 'auto',
    flexDirection: 'column',
  },

  helpButton: {
    width: '100%',
    padding: '12px 12px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    transition: 'background-color 200ms',
    color: theme.palette.text.secondary,

    '&:hover': {
      backgroundColor: theme.palette.primary.main + '20',
      transition: 'background-color 200ms',
    },
  },

  helpIcon: {
    height: 16,
    paddingRight: 5,

    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },

  versionText: {
    paddingLeft: 5,
    fontSize: 12,
  },

  dropDownMenu: {
    '& .MuiList-padding': {
      padding: 0,
      borderRadius: theme.shape.borderRadius,
      border: '1px solid ' + theme.palette.borderDark,
    },
  },

  dropDownMenuItem: {
    color: theme.palette.text.primary,
    fontSize: 14,
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
      backgroundColor: theme.palette.border + 'aa',
    },
    '& svg': {
      fill: theme.palette.text.primary,
      height: 16,
      width: 16,
      paddingRight: 10,
    },
  },

  tableOverflow: {
    position: 'relative',
    overflow: 'initial',
    minWidth: '100%',
  },

  activeBreadcrumb: {
    cursor: 'pointer',
  },

  capitalizeFirstLetter: {
    textTransform: 'capitalize',
  },

  messageBadge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: 2,
      right: 2,
    },
  },

  messageName: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.white,
    paddingBottom: 6,
  },

  messageSubject: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.white,
    paddingBottom: 2,
  },
})

export default privateLayoutStyle
