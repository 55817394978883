import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

const CustomTooltip = (props) => {
  const {children, title, classNames, placement = 'top', ...rest} = props

  return (
    <Tooltip title={title} placement={placement} {...rest}>
      <div style={{display: 'flex'}} className={classNames}>
        {children}
      </div>
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  placement: PropTypes.string,
  classNames: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default CustomTooltip
