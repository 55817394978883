import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import {
  GET_INBOX_FAIL,
  GET_INBOX_REQUEST,
  GET_INBOX_SUCCESS,
  GET_LIST_INBOX_FAIL,
  GET_LIST_INBOX_REQUEST,
  GET_LIST_INBOX_SUCCESS,
  UPDATE_GLOBAL_LOADING,
  UPDATE_MESSAGE_NUMBER,
} from 'redux/actionType'

export const updateMessagesStorage = (data) => {
  return (dispatch) => {
    return dispatch({type: UPDATE_MESSAGE_NUMBER, data: data})
  }
}

export const getMessageInbox = (limit, offset, meta, loadNext = false) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_INBOX_REQUEST, loadNext: loadNext})

    return httpClient
      .get(endpoints.receipts + '/inbox', {
        limit: limit,
        offset: offset,
        order_by: meta?.orderBy,
        order_direction: meta?.orderBy && meta?.orderDirection,
      })
      .then((res) => {
        dispatch({type: GET_LIST_INBOX_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_INBOX_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const searchMessageInbox = (limit, offset, meta, search, loadNext = false) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_INBOX_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`

    return httpClient
      .post(endpoints.receipts + '/inbox/search' + urlParams, {
        query: search,
      })
      .then((res) => {
        dispatch({type: GET_LIST_INBOX_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_INBOX_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getMessage = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_INBOX_REQUEST})
    return httpClient
      .get(endpoints.receipts + '/' + id)
      .then((res) => {
        dispatch({type: GET_INBOX_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_INBOX_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const markMessageAsRead = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(endpoints.receipts + '/mark-as-read', {receipts_ids: data})
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.resolve(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const markMessageAsUnRead = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(endpoints.receipts + '/mark-as-unread', {receipts_ids: data})
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
