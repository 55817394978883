import cx from 'classnames'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'helper/configConstants'
import endpoints from 'helper/endpoints'
import {fireInfoToast, getItemFromStorage, redirectTo} from 'helper/functions'
import DrawerMenu from 'layout/PrivateLayout/DrawerMenu'
import TopRightIconButtons from 'layout/PrivateLayout/TopRightIconButtons'
import TopRightMenu from 'layout/PrivateLayout/TopRightMenu'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {updateMessagesStorage} from 'redux/action/inboxAction'
import {toggleShortcuts} from 'redux/action/shortcutAction'
import Footer from 'style/asset/footer.svg'
import loaderGif from 'style/asset/loader_64.gif'
import orakulumLogo from 'style/asset/orakulum_logo.png'
import {Trans} from '@lingui/macro'
import {IconButton} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import {withStyles} from '@material-ui/core/styles'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

const PrivateLayout = (props) => {
  const {
    classes,
    children,
    globalLoading,
    updateMessagesStorage,
    shortcuts,
    helpVisible,
    toggleShortcuts,
    breadcrumbsNames,
  } = props

  let location = useLocation()
  let previousBreadcrumb = null
  const currentUser = getItemFromStorage(LS_LOGGED_USER)

  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openHelpDialog, setOpenHelpDialog] = useState(false)

  const handleOpenHelpDialog = () => {
    setOpenHelpDialog(true)
    toggleShortcuts(true)
  }

  const handleCloseHelpDialog = () => {
    setOpenHelpDialog(false)
    toggleShortcuts(false)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // loading of the page, with moving logo to left top corner
  const loadingPromise = () => {
    return new Promise((res) => {
      setLoading(true)
      setTimeout(() => {
        res(false)
      }, 1500)
    })
  }

  // object lookup
  const breadcrumbsTransObject = {
    messages: <Trans>Messages</Trans>,
    hours: <Trans>Worked hours overview</Trans>,
    'time-records': <Trans>Work overview</Trans>,
    'work-reports': <Trans>Salary work reports</Trans>,
    employees: <Trans>Employees</Trans>,
    clients: <Trans>Clients</Trans>,
    contact: <Trans>Contact</Trans>,
    'price-lists': <Trans>Price lists</Trans>,
    prices: <Trans>Prices</Trans>,
    invoices: <Trans>Invoices OSVC</Trans>,
    'client-invoices': <Trans>Client invoices</Trans>,
    new: <Trans>New</Trans>,
    edit: <Trans>Edit</Trans>,
    history: <Trans>History</Trans>,
    quotes: <Trans>Quotes</Trans>,
    efficiency: <Trans>Efficiency report</Trans>,
    evaluations: <Trans>Evaluations</Trans>,
    'personified-messages': <Trans>Personified messages</Trans>,
    inbox: <Trans>Messages</Trans>,
  }

  const getBreadcrumbTrans = (string) => {
    const translation = breadcrumbsTransObject[string]

    const name = breadcrumbsNames[previousBreadcrumb]?.name
    previousBreadcrumb = string

    return translation || name || <span className={classes.capitalizeFirstLetter}>{string}</span>
  }

  const getBreadcrumbs = () => {
    const breadcrumbs = []
    const pathList = location.pathname.split('/')
    const breadCrumbUrl = location.pathname.split('/')

    // remove first element of array, in this case it is empty string
    // pathList is for names of breadcrumbs (advancing from left to right)
    pathList.shift()

    // remove last element of array, in this case current page which has no link
    // breadCrumbUrl is for link addresses of breadcrumbs
    breadCrumbUrl.pop()

    // base path of breadcrumb
    let basePath = ''

    while (pathList?.length > 1) {
      // add slash and new part of the url
      basePath += '/' + pathList[0]

      // create scoped variable to not override in
      const path = basePath

      breadcrumbs.push(
        <Typography
          className={classes.activeBreadcrumb}
          key={pathList?.length}
          color="primary"
          onClick={() => redirectTo(path)}
        >
          {getBreadcrumbTrans(pathList[0])}
        </Typography>
      )

      breadCrumbUrl.pop()
      pathList.shift()
    }

    breadcrumbs.push(
      <Typography key={'last-breadcrumb'} color="textPrimary">
        <strong>{getBreadcrumbTrans(pathList[0])}</strong>
      </Typography>
    )

    return breadcrumbs
  }

  const initWebSocket = () => {
    // Create WebSocket connection
    const sessionId = getItemFromStorage(LS_SESSION_ID_NAME)
    if (sessionId) {
      const socket = new WebSocket(endpoints.webSocket + '/cable/?session_id=' + sessionId)

      // Connection opened
      socket.addEventListener('open', (event) => {
        const msg = {
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'InboxChannel',
          }),
        }
        console.info('WS connection opened!')
        socket.send(JSON.stringify(msg))
      })

      // Listen for messages
      socket.addEventListener('message', (event) => {
        const messageObj = JSON.parse(event?.data)?.message
        if (JSON.parse(event?.data)?.type !== 'ping' && messageObj) {
          const newMessage = messageObj?.new_receipt?.message
          if (newMessage) {
            fireInfoToast(
              <GridContainer direction={'column'}>
                <GridItem xs={12} className={classes.messageName}>
                  <Trans>New message</Trans>
                </GridItem>
                <GridItem xs={12} className={classes.messageSubject}>
                  <Trans>Subject</Trans>: {newMessage?.subject}
                </GridItem>
              </GridContainer>
            )
          }
          updateMessagesStorage(JSON.parse(event.data).message)
        }
      })

      // Connection closed
      socket.addEventListener('close', (event) => {
        console.info('WS connection closed!')
      })
    }
  }

  useEffect(() => {
    loadingPromise().then((res) => setLoading(res))
    initWebSocket()
  }, [])

  useEffect(() => {
    if (helpVisible && !openHelpDialog) {
      setOpenHelpDialog(true)
    }
  }, [helpVisible, shortcuts])

  return (
    <GridContainer direction={'row'}>
      {/* MASK & LOADER SHOWN AFTER LOGIN */}
      <div className={cx(classes.loadingMask, loading ? classes.loadingMaskFade : '')}>
        <img src={loaderGif} alt={'loading...'} />
      </div>

      {/* TOP BAR WITH LOGO */}
      <GridItem container direction={'row'} className={classes.topAppBar}>
        {globalLoading && (
          <LinearProgress color="secondary" className={classes.topAppBarLinearProgress} />
        )}
        <GridItem xs={false}>
          <div className={cx(classes.topBarLogo, loading ? classes.loadingTopBarLogo : '')}>
            <img src={orakulumLogo} alt={'orakulum-logo'} />
          </div>
        </GridItem>

        <GridItem container xs={true} className={classes.topAppBarRightSide}>
          {/* Top bar part with clients to review and messages */}
          <TopRightIconButtons />

          <div className={classes.topAppBarLoggedIn}>
            <Trans>Logged in as:</Trans>
            <div className={classes.topAppBarLoggedInText}>{currentUser?.name}</div>
          </div>
          <div>
            <IconButton
              className={classes.topAppBarLogoutIcon}
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <AccountCircleOutlinedIcon />
            </IconButton>

            {/* Menu with logout and change password */}
            <TopRightMenu handleClose={handleClose} anchorElement={anchorEl} />
          </div>
        </GridItem>
      </GridItem>

      <GridItem className={classes.root}>
        <DrawerMenu
          openHelpDialog={openHelpDialog}
          handleOpenHelpDialog={handleOpenHelpDialog}
          handleCloseHelpDialog={handleCloseHelpDialog}
        />

        <GridContainer direction={'column'} className={classes.mainSectionContainer}>
          <GridItem xs={12}>
            <div className={classes.header}>
              <div className={classes.tableOverflow}>
                <GridItem
                  xs={12}
                  container
                  justifyContent={'flex-start'}
                  align={'center'}
                  className={classes.pageTitle}
                >
                  <Box py={1.5}>
                    <Breadcrumbs>{getBreadcrumbs()}</Breadcrumbs>
                  </Box>
                </GridItem>
              </div>
            </div>
          </GridItem>
          <GridItem xs={12} container>
            <div className={classes.content}>
              {children}
              <Box
                sx={{
                  width: '100%',
                  mt: '30px',
                  display: 'flex',
                  alignItems: 'flex-end',
                  flexGrow: 1,
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
                  onClick={() => window.open('https://www.eluvia.com/')}
                >
                  <span style={{color: '#999999', fontSize: 12}}>
                    <Trans>Launched from land to cloud by Eluvians</Trans>
                  </span>
                  <img style={{height: 18, padding: '0 10px'}} src={Footer} alt={'footer'} />
                </span>
              </Box>
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  globalLoading: PropTypes.bool,
  updateMessagesStorage: PropTypes.func,
  shortcuts: PropTypes.array,
  helpVisible: PropTypes.bool,
  toggleShortcuts: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateMessagesStorage,
      toggleShortcuts,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      globalLoading: store.globalLoading.loading,
      shortcuts: store.shortcut.shortcuts,
      helpVisible: store.shortcut.helpVisible,
      breadcrumbsNames: store.breadcrumbs,
    }
  }, mapDispatchToProps)
)(PrivateLayout)
