import ChangeMyPasswordDialog from 'layout/PrivateLayout/ChangeMyPasswordDialog'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {logout} from 'redux/action/authAction'
import {Trans} from '@lingui/macro'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined'

const TopRightMenu = (props) => {
  const {handleClose, anchorElement, logout, classes} = props

  const [openChangePassword, setOpenChangePassword] = useState(false)

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true)
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false)
  }

  const handleLogout = () => {
    return logout()
  }

  return (
    <>
      <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        className={classes.dropDownMenu}
      >
        <MenuItem className={classes.dropDownMenuItem} onClick={handleOpenChangePassword}>
          <VpnKeyOutlinedIcon />
          <Trans>Change password</Trans>
        </MenuItem>
        <MenuItem className={classes.dropDownMenuItem} onClick={handleLogout}>
          <ExitToAppIcon />
          <Trans>Logout</Trans>
        </MenuItem>
      </Menu>

      <ChangeMyPasswordDialog onClose={handleCloseChangePassword} open={openChangePassword} />
    </>
  )
}

TopRightMenu.propTypes = {
  classes: PropTypes.object,
  logout: PropTypes.func,
  handleClose: PropTypes.func,
  anchorElement: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(TopRightMenu)
