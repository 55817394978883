import cx from 'classnames'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Loader from 'component/material/Loader'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {CardContent} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomCard = (props) => {
  const {classes, loading, title, content, additionalCardHeaderClass, additionalCardContentClass} =
    props
  return (
    <Card className={classes.customCard} elevation={1}>
      {loading ? (
        <BoxFullWidth py={6}>
          <Loader />
        </BoxFullWidth>
      ) : (
        <>
          <CardHeader
            className={cx(classes.customCardHeader, additionalCardHeaderClass)}
            disableTypography={true}
            title={title}
          />
          <CardContent className={cx(classes.customCardContent, additionalCardContentClass)}>
            {content}
          </CardContent>
        </>
      )}
    </Card>
  )
}

CustomCard.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node,
  content: PropTypes.node,
  additionalCardHeaderClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  additionalCardContentClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
}

export default withStyles(materialStyle)(CustomCard)
