import cx from 'classnames'
import BoxFullWidth from 'component/material/BoxFullWidth'
import CustomTooltip from 'component/material/CustomTooltip'
import GridContainer from 'component/material/GridContainer'
import Loader from 'component/material/Loader'
import materialStyle from 'component/material/materialStyle'
import TableRow from 'component/material/table/TableRow'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import {TableContainer} from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomTable = React.memo((props) => {
  const {
    classes,
    columns,
    data,
    size,
    onTableRowClick,
    onTableColumnSort,
    tableSort,
    displayCheckbox = true,
    loading,
    handleSetTableSelectedRows,
    tableSelectedRows,
    selectedRow,
    noDataText = <Trans>No data</Trans>,
    stickyHeader = true,
    tableMaxHeight = 'calc(100vh - 168px)',
    onMouseRowLeave,
    editedRowId,
  } = props

  const [orderDirection, setOrderDirection] = useState()
  const [orderBy, setOrderBy] = useState()

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      handleSetTableSelectedRows(data)
    } else {
      handleSetTableSelectedRows([])
    }
  }

  const handleSelectRow = (row) => () => {
    if (tableSelectedRows?.some((record) => record.id === row.id)) {
      const newSelected = [...tableSelectedRows]
      handleSetTableSelectedRows(newSelected?.filter((record) => record.id !== row.id))
    } else {
      tableSelectedRows && handleSetTableSelectedRows([...tableSelectedRows, row])
    }
  }

  const rowClick = (row, rowIndex) => () => {
    onTableRowClick(row, rowIndex)
  }

  const onSortClick = (sortKey, label) => () => {
    if (orderBy === sortKey && orderDirection === 'ASC') {
      setOrderDirection('DESC')
      setOrderBy(sortKey)
      onTableColumnSort({orderBy: sortKey, orderDirection: 'DESC', label: label})
    } else if (orderBy === sortKey && orderDirection === 'DESC') {
      setOrderDirection(null)
      setOrderBy(null)
      onTableColumnSort({orderBy: null, orderDirection: null, label: null})
    } else {
      setOrderDirection('ASC')
      setOrderBy(sortKey)
      onTableColumnSort({orderBy: sortKey, orderDirection: 'ASC', label: label})
    }
  }

  useEffect(() => {
    setOrderDirection(tableSort?.orderDirection)
    setOrderBy(tableSort?.orderBy)
  }, [tableSort])

  return (
    columns && (
      <TableContainer style={{maxHeight: stickyHeader ? tableMaxHeight : 'unset'}}>
        <Table stickyHeader={stickyHeader} className={classes.tableClass}>
          <TableHead>
            <TableRow>
              {displayCheckbox && (
                <TableCell
                  padding="none"
                  style={{minWidth: '40px'}}
                  className={cx(classes.tableBorderRight, classes.tableHeadBorderBottom)}
                >
                  <Checkbox
                    className={cx(classes.tableCellCheckboxClass)}
                    size={'small'}
                    indeterminate={
                      tableSelectedRows?.length - 1 > 0 &&
                      tableSelectedRows?.length - 1 < data?.length - 1
                    }
                    checked={
                      data?.length - 1 > 0 && tableSelectedRows?.length - 1 === data?.length - 1
                    }
                    onChange={handleSetTableSelectedRows && handleSelectAllClick}
                    disableRipple={true}
                  />
                </TableCell>
              )}
              {columns?.map((col, index) => (
                <TableCell
                  key={index}
                  align={col.align ? col.align : 'left'}
                  size={size ? size : 'medium'}
                  className={cx(
                    classes.tableCellClass,
                    classes.tableHeadCellClass,
                    orderDirection && orderBy === col.sortKey
                      ? classes.activeOrderHeaderBackground
                      : ''
                  )}
                  sortDirection={orderDirection?.toLowerCase()}
                  style={{maxWidth: col.width ? col.width : '200px'}}
                >
                  {onTableColumnSort && col.sortKey ? (
                    <TableSortLabel
                      onClick={col.sortKey && onSortClick(col.sortKey, col.label)}
                      active={orderDirection && orderBy === col.sortKey}
                      direction={orderDirection?.toLowerCase()}
                      style={{maxWidth: col.width ? col.width : '200px'}}
                    >
                      <CustomTooltip title={<Trans>Sort</Trans>} enterDelay={300}>
                        {col.label}
                      </CustomTooltip>
                    </TableSortLabel>
                  ) : (
                    <span className={classes.tableLabel}>{col.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <tr>
                <td colSpan={displayCheckbox ? 1 + columns?.length : columns?.length}>
                  <BoxFullWidth py={6}>
                    <Loader />
                  </BoxFullWidth>
                </td>
              </tr>
            ) : data?.length ? (
              data?.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  className={cx(
                    selectedRow === rowIndex ? classes.tableRowHover : '',
                    editedRowId === row.id ? classes.activeEfficiencyTableRow : ''
                  )}
                  onMouseLeave={() => onMouseRowLeave && onMouseRowLeave()}
                >
                  {displayCheckbox && (
                    <TableCell
                      padding="none"
                      style={{minWidth: '40px'}}
                      className={cx(
                        classes.tableBorderRight,
                        rowIndex % 2 ? '' : classes.tableCellDarkClass,
                        classes.tableCellCheckboxTransition
                      )}
                    >
                      <Checkbox
                        disableRipple={true}
                        className={cx(classes.tableCellCheckboxClass)}
                        size={'small'}
                        onChange={handleSetTableSelectedRows && handleSelectRow(row)}
                        checked={tableSelectedRows?.some((record) => record.id === row.id)}
                      />
                    </TableCell>
                  )}
                  {columns.map((col, colIndex) => (
                    <TableCell
                      onClick={onTableRowClick && rowClick(row, rowIndex)}
                      key={colIndex}
                      align={col.align ? col.align : 'left'}
                      scope="row"
                      size={size ? size : 'medium'}
                      style={{maxWidth: col.width ? col.width : '200px'}}
                      className={cx(
                        classes.tableCellClass,
                        onTableRowClick && classes.tableRowCursorPointer,
                        rowIndex % 2 ? '' : classes.tableCellDarkClass
                      )}
                    >
                      <div
                        style={{maxWidth: col.width ? col.width : '180px', width: 'max-content'}}
                      >
                        {col.render ? col.render(row[col.name], row, rowIndex) : row[col.name]}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <td colSpan={displayCheckbox ? 1 + columns?.length : columns?.length}>
                  <BoxFullWidth py={6}>
                    <GridContainer justifyContent={'center'} className={classes.noData}>
                      {noDataText}
                    </GridContainer>
                  </BoxFullWidth>
                </td>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  )
})

CustomTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  size: PropTypes.string,
  onTableRowClick: PropTypes.func,
  onTableColumnSort: PropTypes.func,
  tableSort: PropTypes.object,
  displayCheckbox: PropTypes.bool,
  loading: PropTypes.bool,
  handleSetTableSelectedRows: PropTypes.func,
  tableSelectedRows: PropTypes.array,
  noDataText: PropTypes.node,
}

export default withStyles(materialStyle)(CustomTable)
