import {
  GET_LIST_PRICE_FAIL,
  GET_LIST_PRICE_LIST_FAIL,
  GET_LIST_PRICE_LIST_REQUEST,
  GET_LIST_PRICE_LIST_SUCCESS,
  GET_LIST_PRICE_REQUEST,
  GET_LIST_PRICE_SUCCESS,
  GET_PRICE_FAIL,
  GET_PRICE_LIST_FAIL,
  GET_PRICE_LIST_REQUEST,
  GET_PRICE_LIST_SUCCESS,
  GET_PRICE_REQUEST,
  GET_PRICE_SUCCESS,
  GET_PRICE_VERSIONS_FAIL,
  GET_PRICE_VERSIONS_REQUEST,
  GET_PRICE_VERSIONS_SUCCESS,
  GET_PRICE_VERSION_DIFF_FAIL,
  GET_PRICE_VERSION_DIFF_REQUEST,
  GET_PRICE_VERSION_DIFF_SUCCESS,
} from 'redux/actionType'

const initState = {
  meta: {},
  priceList: [],
  priceListLoading: false,
  singlePriceList: {},
  singlePriceListLoading: false,
  pricesMeta: {},
  prices: [],
  pricesLoading: false,
  singlePrice: {},
  singlePriceLoading: false,
  priceHistory: [],
  priceHistoryLoading: false,
  priceHistoryDiff: {},
  priceHistoryDiffLoading: false,
}

const priceListReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_LIST_PRICE_LIST_REQUEST: {
      return {
        ...state,
        priceListLoading: !action.loadNext,
      }
    }
    case GET_LIST_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        priceListLoading: false,
        priceList: action.loadNext
          ? [...state.priceList, ...action.data.objects]
          : action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_LIST_PRICE_LIST_FAIL: {
      return {
        ...state,
        priceListLoading: false,
      }
    }

    case GET_LIST_PRICE_REQUEST: {
      return {
        ...state,
        pricesLoading: !action.loadNext,
      }
    }
    case GET_LIST_PRICE_SUCCESS: {
      return {
        ...state,
        pricesLoading: false,
        pricesMeta: action.data.meta,
        prices: action.loadNext ? [...state.prices, ...action.data.objects] : action.data.objects,
      }
    }
    case GET_LIST_PRICE_FAIL: {
      return {
        ...state,
        pricesLoading: false,
      }
    }

    case GET_PRICE_LIST_REQUEST: {
      return {
        ...state,
        singlePriceListLoading: true,
      }
    }
    case GET_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        singlePriceListLoading: false,
        singlePriceList: action.data,
      }
    }
    case GET_PRICE_LIST_FAIL: {
      return {
        ...state,
        singlePriceListLoading: false,
      }
    }
    case GET_PRICE_REQUEST: {
      return {
        ...state,
        singlePriceLoading: true,
      }
    }
    case GET_PRICE_SUCCESS: {
      return {
        ...state,
        singlePriceLoading: false,
        singlePrice: action.data,
      }
    }
    case GET_PRICE_FAIL: {
      return {
        ...state,
        singlePriceLoading: false,
      }
    }
    case GET_PRICE_VERSIONS_REQUEST: {
      return {
        ...state,
        priceHistoryLoading: true,
      }
    }
    case GET_PRICE_VERSIONS_SUCCESS: {
      return {
        ...state,
        priceHistoryLoading: false,
        priceHistory: action.data,
      }
    }
    case GET_PRICE_VERSIONS_FAIL: {
      return {
        ...state,
        priceHistoryLoading: false,
      }
    }
    case GET_PRICE_VERSION_DIFF_REQUEST: {
      return {
        ...state,
        priceHistoryDiffLoading: true,
      }
    }
    case GET_PRICE_VERSION_DIFF_SUCCESS: {
      return {
        ...state,
        priceHistoryDiffLoading: false,
        priceHistoryDiff: action.data,
      }
    }
    case GET_PRICE_VERSION_DIFF_FAIL: {
      return {
        ...state,
        priceHistoryDiffLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default priceListReducer
