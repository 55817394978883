import {UPDATE_BREADCRUMBS} from 'redux/actionType'

const initState = {}

const breadcrumbsReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_BREADCRUMBS) {
    return {...state, ...action.data}
  } else {
    return state
  }
}

export default breadcrumbsReducer
