import {UPDATE_GLOBAL_LOADING} from 'redux/actionType'

const initState = {
  loading: false,
}

const globalLoadingReducer = (state = initState, action = null) => {
  switch (action.type) {
    case UPDATE_GLOBAL_LOADING: {
      return {
        ...state,
        loading: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export default globalLoadingReducer
