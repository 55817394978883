import {resolveEnv} from 'helper/constants'
import {getServiceUrl} from 'helper/functions'

const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  currentUser: `${getServiceUrl('auth')}/current-user`,
  users: `${getServiceUrl('auth')}/users`,

  timeRecords: `${getServiceUrl('orakulum')}/time-records`,
  reports: `${getServiceUrl('orakulum')}/reports`,

  employees: `${getServiceUrl('orakulum')}/employees`,
  employeePositions: `${getServiceUrl('orakulum')}/employee-positions`,
  employeeInvoices: `${getServiceUrl('orakulum')}/employee-invoices`,

  billingCompanies: `${getServiceUrl('orakulum')}/billing-companies`,

  priceList: `${getServiceUrl('orakulum')}/price-lists`,
  prices: `${getServiceUrl('orakulum')}/prices`,

  clients: `${getServiceUrl('orakulum')}/clients`,
  clientInvoices: `${getServiceUrl('orakulum')}/client-invoices`,

  ares: `${getServiceUrl('orakulum')}/ares`,
  contacts: `${getServiceUrl('orakulum')}/contacts`,
  exportToExcel: `${getServiceUrl('orakulum')}/export-client-contacts`,
  exportContactToExcel: `${getServiceUrl('orakulum')}/export-client-contact-emails`,
  syncSmartEmailing: `${getServiceUrl('orakulum')}/clients/synchronize-smartemailing`,

  smartEmailingStatus: `${getServiceUrl('orakulum')}/clients/smartemailing-sync-status`,

  constants: `${getServiceUrl('orakulum')}/constants`,

  banks: `${getServiceUrl('orakulum')}/banks`,

  quotes: `${getServiceUrl('orakulum')}/quotes`,

  evaluations: `${getServiceUrl('orakulum')}/evaluations`,
  evaluationPref: `${getServiceUrl('orakulum')}/evaluation-preferences/1`,
  efficiency: `${getServiceUrl('orakulum')}/efficiency`,

  // messaging service //
  receipts: `${getServiceUrl('messaging')}/receipts`,

  // websocket server //
  webSocket: `${resolveEnv('REACT_APP_WS_URL')}/api/messaging`,
}

export default endpoints
