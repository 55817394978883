import {FORCE_NO_AUTH, LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'helper/configConstants'
import {getItemFromStorage, redirectTo} from 'helper/functions'
import PrivateLayout from 'layout/PrivateLayout/PrivateLayout'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'

const PrivateRoute = (props) => {
  const {component, path, ...rest} = props

  useEffect(() => {
    document.getElementsByTagName('html').scrollTop = 0
  }, [])

  const hasSessionId = () => {
    return !!getItemFromStorage(LS_SESSION_ID_NAME)
  }

  const hasLoggedUser = () => {
    return !!getItemFromStorage(LS_LOGGED_USER)
  }

  const sessionId = hasSessionId() || FORCE_NO_AUTH
  const loggedUser = hasLoggedUser() || FORCE_NO_AUTH

  if (!sessionId || !loggedUser) {
    redirectTo('/login')
  }

  return (
    <Route path={path} {...rest}>
      <PrivateLayout>{component}</PrivateLayout>
    </Route>
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.node,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  rest: PropTypes.object,
}

export default PrivateRoute
