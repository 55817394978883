import cx from 'classnames'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'

const GridContainer = (props) => {
  const {children, className, classes, alignSelfFlexTop = false, ...rest} = props
  return (
    <Grid
      container
      className={cx(className, alignSelfFlexTop && classes.alignSelfFlexTop)}
      {...rest}
    >
      {children}
    </Grid>
  )
}

GridContainer.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  rest: PropTypes.object,
}

export default withStyles(materialStyle)(GridContainer)
