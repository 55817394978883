import TextInput from 'component/field/TextInput'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {IconButton} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import withStyles from '@material-ui/core/styles/withStyles'
import {Visibility, VisibilityOff} from '@material-ui/icons'

/**
 * Simple password component with visibility toggle icon button
 */
const PasswordInput = (props) => {
  const {input, classes, ...rest} = props

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (event) => {
    event.preventDefault()
    setShowPassword((showPassword) => !showPassword)
  }

  const AdornmentIcon = showPassword ? Visibility : VisibilityOff
  const inputElementType = showPassword ? 'text' : 'password'

  return (
    <TextInput
      variant={'outlined'}
      input={input}
      type={inputElementType}
      className={classes.passwordEndAdornmentPadding}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className={classes.passwordEndAdornment}>
            <IconButton onClick={handleClickShowPassword}>
              <AdornmentIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}

PasswordInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(materialStyle)(PasswordInput)
