import {LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS} from 'redux/actionType'

const initState = {
  loading: false,
  data: {},
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default authReducer
