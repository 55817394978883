import {
  GET_BILLING_COMPANIES_FAIL,
  GET_BILLING_COMPANIES_REQUEST,
  GET_BILLING_COMPANIES_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_EMPLOYEE_INVOICE_LIST_FAIL,
  GET_EMPLOYEE_INVOICE_LIST_REQUEST,
  GET_EMPLOYEE_INVOICE_LIST_SUCCESS,
  GET_EMPLOYEE_LIST_ONLY_FAIL,
  GET_EMPLOYEE_LIST_ONLY_REQUEST,
  GET_EMPLOYEE_LIST_ONLY_SUCCESS,
  GET_EMPLOYEE_POSITION_FAIL,
  GET_EMPLOYEE_POSITION_REQUEST,
  GET_EMPLOYEE_POSITION_SUCCESS,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_VERSIONS_FAIL,
  GET_EMPLOYEE_VERSIONS_REQUEST,
  GET_EMPLOYEE_VERSIONS_SUCCESS,
  GET_EMPLOYEE_VERSION_DIFF_FAIL,
  GET_EMPLOYEE_VERSION_DIFF_REQUEST,
  GET_EMPLOYEE_VERSION_DIFF_SUCCESS,
  GET_LIST_EMPLOYEE_FAIL,
  GET_LIST_EMPLOYEE_REQUEST,
  GET_LIST_EMPLOYEE_SUCCESS,
} from 'redux/actionType'

const initState = {
  employeeListLoading: false,
  employeeList: [],
  meta: {},
  employeeDetailLoading: false,
  employeeDetail: {},
  employeePositions: [],
  employeePositionsLoading: false,
  employeeHistory: [],
  employeeHistoryLoading: false,
  employeeHistoryDiff: {},
  employeeHistoryDiffLoading: false,
  employeeListOnly: [],
  employeeListOnlyLoading: false,
  employeeListOnlyMeta: {},
  billingCompanies: [],
  billingCompaniesMeta: {},
  billingCompaniesLoading: false,
  employeeInvoiceList: [],
  employeeInvoiceListLoading: false,
  employeeInvoiceListMeta: {},
}

const employeeReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_LIST_EMPLOYEE_REQUEST: {
      return {
        ...state,
        employeeListLoading: !action.loadNext,
      }
    }
    case GET_LIST_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employeeListLoading: false,
        employeeList: action.loadNext
          ? [...state.employeeList, ...action.data.objects]
          : action.data.objects,
        meta: action.data?.meta,
      }
    }
    case GET_LIST_EMPLOYEE_FAIL: {
      return {
        ...state,
        employeeListLoading: false,
      }
    }
    case GET_EMPLOYEE_REQUEST: {
      return {
        ...state,
        employeeDetailLoading: true,
      }
    }
    case GET_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        employeeDetailLoading: false,
        employeeDetail: action.data,
      }
    }
    case GET_EMPLOYEE_FAIL: {
      return {
        ...state,
        employeeDetailLoading: false,
      }
    }
    case GET_EMPLOYEE_POSITION_REQUEST: {
      return {
        ...state,
        employeePositionsLoading: true,
      }
    }
    case GET_EMPLOYEE_POSITION_SUCCESS: {
      return {
        ...state,
        employeePositionsLoading: false,
        employeePositions: action.data.objects.sort((a, b) => a.id - b.id),
      }
    }
    case GET_EMPLOYEE_POSITION_FAIL: {
      return {
        ...state,
        employeePositionsLoading: false,
      }
    }
    case GET_EMPLOYEE_VERSIONS_REQUEST: {
      return {
        ...state,
        employeeHistoryLoading: true,
      }
    }
    case GET_EMPLOYEE_VERSIONS_SUCCESS: {
      return {
        ...state,
        employeeHistory: action.data,
        employeeHistoryLoading: false,
      }
    }
    case GET_EMPLOYEE_VERSIONS_FAIL: {
      return {
        ...state,
        employeeHistoryLoading: false,
      }
    }
    case GET_EMPLOYEE_VERSION_DIFF_REQUEST: {
      return {
        ...state,
        employeeHistoryDiffLoading: true,
      }
    }
    case GET_EMPLOYEE_VERSION_DIFF_SUCCESS: {
      return {
        ...state,
        employeeHistoryDiffLoading: false,
        employeeHistoryDiff: action.data,
      }
    }
    case GET_EMPLOYEE_VERSION_DIFF_FAIL: {
      return {
        ...state,
        employeeHistoryDiffLoading: false,
      }
    }
    case GET_EMPLOYEE_LIST_ONLY_REQUEST: {
      return {
        ...state,
        employeeListOnlyLoading: true,
      }
    }
    case GET_EMPLOYEE_LIST_ONLY_SUCCESS: {
      return {
        ...state,
        employeeListOnlyLoading: false,
        employeeListOnly: action.data.objects,
        employeeListOnlyMeta: action.data.meta,
      }
    }
    case GET_EMPLOYEE_LIST_ONLY_FAIL: {
      return {
        ...state,
        employeeListOnlyLoading: false,
      }
    }
    case GET_BILLING_COMPANIES_REQUEST: {
      return {
        ...state,
        billingCompaniesLoading: true,
      }
    }
    case GET_BILLING_COMPANIES_SUCCESS: {
      return {
        ...state,
        billingCompaniesLoading: false,
        billingCompaniesMeta: action.data.meta,
        billingCompanies: action.data.objects,
      }
    }
    case GET_BILLING_COMPANIES_FAIL: {
      return {
        ...state,
        billingCompaniesLoading: false,
      }
    }
    case GET_EMPLOYEE_INVOICE_LIST_REQUEST: {
      return {
        ...state,
        employeeInvoiceListLoading: true,
      }
    }
    case GET_EMPLOYEE_INVOICE_LIST_SUCCESS: {
      return {
        ...state,
        employeeInvoiceListLoading: false,
        employeeInvoiceList: action.data.objects,
        employeeInvoiceListMeta: action.data.meta,
      }
    }
    case GET_EMPLOYEE_INVOICE_LIST_FAIL: {
      return {
        ...state,
        employeeInvoiceListLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default employeeReducer
