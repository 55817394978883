import {
  GET_INBOX_FAIL,
  GET_INBOX_REQUEST,
  GET_INBOX_SUCCESS,
  GET_LIST_INBOX_FAIL,
  GET_LIST_INBOX_REQUEST,
  GET_LIST_INBOX_SUCCESS,
  UPDATE_MESSAGE_NUMBER,
} from 'redux/actionType'

const initState = {
  count: null,
  loading: false,
  inbox: [],
  meta: {},
  message: {},
  messageLoading: false,
}

const inboxReducer = (state = initState, action = null) => {
  switch (action.type) {
    case UPDATE_MESSAGE_NUMBER: {
      return {
        ...state,
        count: action.data.unread_receipts_count,
      }
    }
    case GET_LIST_INBOX_REQUEST: {
      return {
        ...state,
        loading: !action.loadNext,
      }
    }
    case GET_LIST_INBOX_SUCCESS: {
      return {
        ...state,
        loading: false,
        inbox: action.loadNext
          ? [
              ...state.inbox,
              ...action.data.objects.map((m) => ({
                is_read: m.is_read,
                id: m.id,
                subject: m.message.subject,
                message: m.message.body,
                created_at: m.created_at,
              })),
            ]
          : action.data.objects,
        meta: action.data.meta,
      }
    }
    case GET_LIST_INBOX_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case GET_INBOX_REQUEST: {
      return {
        ...state,
        messageLoading: true,
      }
    }
    case GET_INBOX_SUCCESS: {
      return {
        ...state,
        messageLoading: false,
        message: action.data,
      }
    }
    case GET_INBOX_FAIL: {
      return {
        ...state,
        messageLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default inboxReducer
