import axios from 'axios'
import {LS_SESSION_ID_NAME} from 'helper/configConstants'
import {PUBLIC_API_KEY} from 'helper/constants'
import {getItemFromStorage} from 'helper/functions'
import React from 'react'

// `withCredentials` indicates whether or not cross-site Access-Control requests
// should be made using credentials , default = false
axios.defaults.withCredentials = true

if (PUBLIC_API_KEY) {
  axios.defaults.headers.common['X-PUBLIC-API-KEY'] = PUBLIC_API_KEY
}

axios.interceptors.request.use((config) => {
  const configCopy = config
  const token = getItemFromStorage(LS_SESSION_ID_NAME)

  if (token) {
    configCopy.headers['session-id'] = token
  }

  return configCopy
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

const post = (
  url,
  data = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.post(url, data, config)
}

const get = (
  url,
  params = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  const configCopy = config
  configCopy.params = params

  return axios.get(url, configCopy)
}

const put = (url, data = {}, config = {}) => {
  return axios.put(url, data, config)
}

const patch = (url, data = {}, config = {}) => {
  return axios.patch(url, data, config)
}

const del = (url, config = {}) => {
  return axios.delete(url, config)
}

const httpClient = {
  post,
  get,
  put,
  del,
  patch,
}

export default httpClient
