import GridItem from 'component/material/GridItem'
import React, {Suspense} from 'react'
import {CircularProgress} from '@material-ui/core'

const SuspenseComponent = (props) => {
  const {component} = props

  return (
    <Suspense
      fallback={
        <GridItem
          container
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            minHeight: '100vh',
            minWidth: '100vw',
          }}
        >
          <CircularProgress size={40} thickness={3.6} />
        </GridItem>
      }
    >
      {component}
    </Suspense>
  )
}

export default SuspenseComponent
