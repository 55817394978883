const historyStyle = (theme) => ({
  historyRecordsHeader: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
    padding: 12,
  },

  historyLink: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },

  historyRecordsContent: {
    padding: 0 + ' !important',
  },

  recordOverviewHeader: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
    padding: 12,
  },

  recordOverviewContent: {
    padding: '12px !important',
  },

  historyRow: {
    borderTop: '1px solid ' + theme.palette.border,
    minHeight: 30,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-around',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      minHeight: 30,
      padding: 5,
      borderRight: '1px solid ' + theme.palette.border,
      wordBreak: 'break-word',

      '&:last-child': {
        borderRight: 'none',
      },
    },
  },

  historyHeadRow: {
    '& div': {
      borderRight: '1px solid ' + theme.palette.border,
      padding: 5,
      wordBreak: 'break-word',

      '&:last-child': {
        borderRight: 'none',
      },
    },
  },

  historyValue: {
    color: theme.palette.error.main,
  },

  unchangedHistoryValue: {
    color: theme.palette.text.hint,
  },

  noHistoryRecord: {
    padding: 12,
    textAlign: 'center',
    color: theme.palette.text.hint,
  },

  selectedRow: {
    fontWeight: 700,
  },
})

export default historyStyle
