import {authorizedAbility} from 'helper/authorizedAbility'
import {LS_GLOBAL_SETTINGS, LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'helper/configConstants'
import endpoints from 'helper/endpoints'
import {
  fireErrorToast,
  fireSuccessToast,
  getItemFromStorage,
  globalApiErrorHandler,
  redirectTo,
  setItemToStorage,
} from 'helper/functions'
import httpClient from 'helper/httpClient'
import mapPermissionsToAbilities from 'helper/mapPermissionsToAbilities'
import React from 'react'
import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAIL,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from 'redux/actionType'
import {globalSettingDefaultValue} from 'redux/reducer/globalSettingReducer'
import {Trans} from '@lingui/macro'

export const login = (data) => {
  return (dispatch) => {
    dispatch({type: LOGIN_REQUEST})
    return httpClient
      .post(endpoints.login, data)
      .then((res) => {
        if (res.status === 200) {
          setItemToStorage(LS_SESSION_ID_NAME, res.headers['session-id'])
          setItemToStorage(LS_LOGGED_USER, res.data?.user)
          authorizedAbility.update(mapPermissionsToAbilities(res.data?.user?.permissions))
          fireSuccessToast(<Trans>Successfully logged in.</Trans>)

          // reload LS when there are old data
          if (
            getItemFromStorage(LS_GLOBAL_SETTINGS)?.rowsPerPageOptions?.toString() !==
            globalSettingDefaultValue?.rowsPerPageOptions?.toString()
          ) {
            setItemToStorage(LS_GLOBAL_SETTINGS, globalSettingDefaultValue)
          }

          redirectTo('/hours')
        }
        dispatch({type: LOGIN_SUCCESS})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: LOGIN_FAIL})
        if (err.response?.status === 401) {
          return fireErrorToast(<Trans>Wrong login credentials!</Trans>, {autoClose: 5000})
        }

        return globalApiErrorHandler(err)
      })
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({type: LOGOUT_REQUEST})
    return httpClient
      .del(endpoints.logout)
      .then((res) => {
        if (res.status === 204) {
          redirectTo('/login')
          localStorage.removeItem(LS_SESSION_ID_NAME)
          localStorage.removeItem(LS_LOGGED_USER)
          dispatch({type: LOGOUT_SUCCESS, data: res.data.user})
        }
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: LOGOUT_FAIL})
        return globalApiErrorHandler(err)
      })
  }
}

export const forgottenPassword = (data) => {
  return () => {
    return httpClient
      .post(endpoints.forgottenPassword, data)
      .then((res) => {
        fireSuccessToast(<Trans>E-mail was sent.</Trans>)
        return Promise.resolve(res)
      })
      .catch(globalApiErrorHandler)
  }
}

export const resetPassword = (token, data) => {
  return () => {
    return httpClient
      .post(endpoints.resetPassword + '/' + token, data)
      .then((res) => {
        fireSuccessToast(<Trans>Password was reset.</Trans>)
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
  }
}

export const changeMyPassword = (data) => {
  return () => {
    return httpClient
      .post(endpoints.currentUser + '/change-password', data)
      .then((res) => {
        fireSuccessToast(<Trans>Password was changed.</Trans>)
        return Promise.resolve(res)
      })
      .catch(globalApiErrorHandler)
  }
}

export const changeUserPassword = (id, data) => {
  return () => {
    return httpClient
      .post(`${endpoints.users}/${id}/change-password`, data)
      .then((res) => {
        fireSuccessToast(<Trans>Password was changed.</Trans>)
        return Promise.resolve(res)
      })
      .catch(globalApiErrorHandler)
  }
}
