import {
  GET_ARES_CLIENT_FAIL,
  GET_ARES_CLIENT_REQUEST,
  GET_ARES_CLIENT_SUCCESS,
  GET_CLIENTS_TO_VERIFY_FAIL,
  GET_CLIENTS_TO_VERIFY_REQUEST,
  GET_CLIENTS_TO_VERIFY_SUCCESS,
  GET_CLIENT_CONTACT_VERSIONS_FAIL,
  GET_CLIENT_CONTACT_VERSIONS_REQUEST,
  GET_CLIENT_CONTACT_VERSIONS_SUCCESS,
  GET_CLIENT_CONTACT_VERSION_DIFF_FAIL,
  GET_CLIENT_CONTACT_VERSION_DIFF_REQUEST,
  GET_CLIENT_CONTACT_VERSION_DIFF_SUCCESS,
  GET_CLIENT_FAIL,
  GET_CLIENT_INVOICE_ITEMS_FAIL,
  GET_CLIENT_INVOICE_ITEMS_REQUEST,
  GET_CLIENT_INVOICE_ITEMS_SUCCESS,
  GET_CLIENT_INVOICE_LIST_FAIL,
  GET_CLIENT_INVOICE_LIST_REQUEST,
  GET_CLIENT_INVOICE_LIST_SUCCESS,
  GET_CLIENT_LIST_ONLY_FAIL,
  GET_CLIENT_LIST_ONLY_REQUEST,
  GET_CLIENT_LIST_ONLY_SUCCESS,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_TAX_DATES_FAIL,
  GET_CLIENT_TAX_DATES_REQUEST,
  GET_CLIENT_TAX_DATES_SUCCESS,
  GET_CLIENT_VERSIONS_FAIL,
  GET_CLIENT_VERSIONS_REQUEST,
  GET_CLIENT_VERSIONS_SUCCESS,
  GET_CLIENT_VERSION_DIFF_FAIL,
  GET_CLIENT_VERSION_DIFF_REQUEST,
  GET_CLIENT_VERSION_DIFF_SUCCESS,
  GET_LIST_CLIENT_FAIL,
  GET_LIST_CLIENT_REQUEST,
  GET_LIST_CLIENT_SUCCESS,
  GET_SMARTEMAILING_STATUS_FAIL,
  GET_SMARTEMAILING_STATUS_REQUEST,
  GET_SMARTEMAILING_STATUS_SUCCESS,
  POST_SYNC_SMARTEMAILING_FAIL,
  POST_SYNC_SMARTEMAILING_REQUEST,
  POST_SYNC_SMARTEMAILING_SUCCESS,
} from 'redux/actionType'

const initState = {
  clientListLoading: false,
  clientList: [],
  clientListMeta: {},

  clientLoading: false,
  client: {},

  aresLoading: false,
  aresData: {},

  clientsToVerify: [],
  clientsToVerifyMeta: {},
  clientsToVerifyLoading: false,

  clientHistory: [],
  clientHistoryLoading: false,
  clientHistoryDiff: {},
  clientHistoryDiffLoading: false,

  clientContactHistory: [],
  clientContactHistoryLoading: false,
  clientContactHistoryDiff: {},
  clientContactHistoryDiffLoading: false,

  clientListOnly: [],
  clientListOnlyLoading: false,
  clientListOnlyMeta: {},

  exportInProgress: false,
  exportTaskName: null,
  exportStatus: null,

  clientTaxDates: [],
  clientTaxDatesLoading: false,
  clientTaxDatesMeta: {},

  clientInvoiceItemsLoading: false,
  clientInvoiceItems: [],
  clientInvoiceItemsMeta: {},

  clientInvoices: [],
  clientInvoicesLoading: false,
  clientInvoicesMeta: {},
}

const clientReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_LIST_CLIENT_REQUEST: {
      return {
        ...state,
        clientListLoading: !action.loadNext,
      }
    }
    case GET_LIST_CLIENT_SUCCESS: {
      return {
        ...state,
        clientListLoading: false,
        clientList: action.loadNext
          ? [...state.clientList, ...action.data.objects]
          : action.data.objects,
        clientListMeta: action.data.meta,
      }
    }
    case GET_LIST_CLIENT_FAIL: {
      return {
        ...state,
        clientListLoading: false,
      }
    }
    case GET_CLIENT_REQUEST: {
      return {
        ...state,
        clientLoading: true,
      }
    }
    case GET_CLIENT_SUCCESS: {
      return {
        ...state,
        clientLoading: false,
        client: action.data,
      }
    }
    case GET_CLIENT_FAIL: {
      return {
        ...state,
        clientLoading: false,
      }
    }
    case GET_ARES_CLIENT_REQUEST: {
      return {
        ...state,
        aresLoading: true,
      }
    }
    case GET_ARES_CLIENT_SUCCESS: {
      return {
        ...state,
        aresData: action.data,
        aresLoading: false,
      }
    }
    case GET_ARES_CLIENT_FAIL: {
      return {
        ...state,
        aresLoading: false,
      }
    }
    case GET_CLIENTS_TO_VERIFY_REQUEST: {
      return {
        ...state,
        clientsToVerifyLoading: true,
      }
    }
    case GET_CLIENTS_TO_VERIFY_SUCCESS: {
      return {
        ...state,
        clientsToVerifyLoading: false,
        clientsToVerify: action.data.objects,
        clientsToVerifyMeta: action.data.meta,
      }
    }
    case GET_CLIENTS_TO_VERIFY_FAIL: {
      return {
        ...state,
        clientsToVerifyLoading: false,
      }
    }
    case GET_CLIENT_VERSIONS_REQUEST: {
      return {
        ...state,
        clientHistoryLoading: true,
      }
    }
    case GET_CLIENT_VERSIONS_SUCCESS: {
      return {
        ...state,
        clientHistory: action.data,
        clientHistoryLoading: false,
      }
    }
    case GET_CLIENT_VERSIONS_FAIL: {
      return {
        ...state,
        clientHistoryLoading: false,
      }
    }
    case GET_CLIENT_VERSION_DIFF_REQUEST: {
      return {
        ...state,
        clientHistoryDiffLoading: true,
      }
    }
    case GET_CLIENT_VERSION_DIFF_SUCCESS: {
      return {
        ...state,
        clientHistoryDiffLoading: false,
        clientHistoryDiff: action.data,
      }
    }
    case GET_CLIENT_VERSION_DIFF_FAIL: {
      return {
        ...state,
        clientHistoryDiffLoading: false,
      }
    }
    case GET_CLIENT_CONTACT_VERSIONS_REQUEST: {
      return {
        ...state,
        clientContactHistoryLoading: true,
      }
    }
    case GET_CLIENT_CONTACT_VERSIONS_SUCCESS: {
      return {
        ...state,
        clientContactHistory: action.data,
        clientContactHistoryLoading: false,
      }
    }
    case GET_CLIENT_CONTACT_VERSIONS_FAIL: {
      return {
        ...state,
        clientContactHistoryLoading: false,
      }
    }
    case GET_CLIENT_CONTACT_VERSION_DIFF_REQUEST: {
      return {
        ...state,
        clientContactHistoryDiffLoading: true,
      }
    }
    case GET_CLIENT_CONTACT_VERSION_DIFF_SUCCESS: {
      return {
        ...state,
        clientContactHistoryDiffLoading: false,
        clientContactHistoryDiff: action.data,
      }
    }
    case GET_CLIENT_CONTACT_VERSION_DIFF_FAIL: {
      return {
        ...state,
        clientContactHistoryDiffLoading: false,
      }
    }
    // contact exports
    case POST_SYNC_SMARTEMAILING_REQUEST: {
      return {
        ...state,
        exportInProgress: true,
      }
    }
    case POST_SYNC_SMARTEMAILING_SUCCESS: {
      return {
        ...state,
        exportTaskName: action.data?.task_name,
      }
    }
    case POST_SYNC_SMARTEMAILING_FAIL: {
      return {
        ...state,
        exportTaskName: null,
        exportInProgress: false,
      }
    }
    case GET_SMARTEMAILING_STATUS_REQUEST: {
      return {
        ...state,
        exportStatus: null,
      }
    }
    case GET_SMARTEMAILING_STATUS_SUCCESS: {
      return {
        ...state,
        exportStatus: action.data?.status,
      }
    }
    case GET_SMARTEMAILING_STATUS_FAIL: {
      return {
        ...state,
        exportTaskName: null,
        exportInProgress: false,
      }
    }
    case GET_CLIENT_LIST_ONLY_REQUEST: {
      return {
        ...state,
        clientListOnlyLoading: true,
      }
    }
    case GET_CLIENT_LIST_ONLY_SUCCESS: {
      return {
        ...state,
        clientListOnlyLoading: false,
        clientListOnly: action.data.objects,
        clientListOnlyMeta: action.data.meta,
      }
    }
    case GET_CLIENT_LIST_ONLY_FAIL: {
      return {
        ...state,
        clientListOnlyLoading: false,
      }
    }
    case GET_CLIENT_TAX_DATES_REQUEST: {
      return {
        ...state,
        clientTaxDatesLoading: true,
      }
    }
    case GET_CLIENT_TAX_DATES_SUCCESS: {
      return {
        ...state,
        clientTaxDates: action.data.objects,
        clientTaxDatesMeta: action.data.meta,
        clientTaxDatesLoading: false,
      }
    }
    case GET_CLIENT_TAX_DATES_FAIL: {
      return {
        ...state,
        clientTaxDatesLoading: false,
      }
    }
    case GET_CLIENT_INVOICE_ITEMS_REQUEST: {
      return {
        ...state,
        clientInvoiceItemsLoading: true,
      }
    }
    case GET_CLIENT_INVOICE_ITEMS_SUCCESS: {
      return {
        ...state,
        clientInvoiceItemsLoading: false,
        clientInvoiceItems: action.data.objects,
        clientInvoiceItemsMeta: action.data.meta,
      }
    }
    case GET_CLIENT_INVOICE_ITEMS_FAIL: {
      return {
        ...state,
        clientInvoiceItemsLoading: false,
      }
    }
    case GET_CLIENT_INVOICE_LIST_REQUEST: {
      return {
        ...state,
        clientInvoicesLoading: true,
      }
    }
    case GET_CLIENT_INVOICE_LIST_SUCCESS: {
      return {
        ...state,
        clientInvoicesLoading: false,
        clientInvoices: action.data.objects,
        clientInvoicesMeta: action.data.meta,
      }
    }
    case GET_CLIENT_INVOICE_LIST_FAIL: {
      return {
        ...state,
        clientInvoicesLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default clientReducer
