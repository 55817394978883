import DialogWindow from 'component/material/DialogWindow'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import {Trans} from '@lingui/macro'
import {Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import withStyles from '@material-ui/core/styles/withStyles'
import Warning from '@material-ui/icons/Warning'

const WarningDialog = (props) => {
  const {open, onClose, onConfirm, classes, message, title, maxWidth = 'xs'} = props

  return (
    <DialogWindow
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      className={classes.warningDialogTitlePadding}
    >
      <GridContainer justifyContent="center">
        <GridContainer justifyContent="center" direction={'row'}>
          <Box pt={5} pb={3} px={12} className={classes.warningDialogTitleIcon}>
            <Warning className={classes.warningDialogIcon} />
            <span className={classes.warningDialogTitle}>
              {title || <Trans>Are you sure?</Trans>}
            </span>
          </Box>
        </GridContainer>
        <Box pb={3}>
          {message && (
            <GridContainer justifyContent="center">
              <Typography component={'span'} variant={'h6'} align={'center'}>
                {message}
              </Typography>
            </GridContainer>
          )}
        </Box>
        <GridContainer>
          <GridItem container xs={6} justifyContent="flex-end">
            <Box py={5} px={3}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                size={'small'}
                text={<Trans>Cancel</Trans>}
                onClick={onClose}
                variant={'outlined'}
              />
            </Box>
          </GridItem>
          <GridItem xs={6}>
            <Box py={5} px={3}>
              <PrimaryButton
                fullWidth={false}
                textPadding={0}
                size={'small'}
                text={<Trans>Confirm</Trans>}
                onClick={onConfirm}
              />
            </Box>
          </GridItem>
        </GridContainer>
      </GridContainer>
    </DialogWindow>
  )
}

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  message: PropTypes.node,
}

export default withStyles(materialStyle)(WarningDialog)
