import {TOGGLE_SHORTCUT_HELP, UPDATE_SHORTCUTS} from 'redux/actionType'

export const updateShortcuts = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_SHORTCUTS, data: data})
  }
}

export const toggleShortcuts = (data) => {
  return (dispatch) => {
    dispatch({type: TOGGLE_SHORTCUT_HELP, data: data})
  }
}
