import {
  GET_EVALUATIONS_FAIL,
  GET_EVALUATIONS_REQUEST,
  GET_EVALUATIONS_SUCCESS,
  GET_EVALUATION_FAIL,
  GET_EVALUATION_REQUEST,
  GET_EVALUATION_SUCCESS,
} from 'redux/actionType'

const initState = {
  evaluations: [],
  evaluationsLoading: false,
  evaluationsMeta: {},

  evaluationDetail: {},
  evaluationDetailLoading: false,
}

const evaluationReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_EVALUATIONS_REQUEST: {
      return {
        ...state,
        evaluationsLoading: !action.loadNext,
      }
    }
    case GET_EVALUATIONS_SUCCESS: {
      return {
        ...state,
        evaluations: action.loadNext
          ? [...state.evaluations, ...action.data.objects]
          : action.data.objects,
        evaluationsMeta: action.data.meta,
        evaluationsLoading: false,
      }
    }
    case GET_EVALUATIONS_FAIL: {
      return {
        ...state,
        evaluationsLoading: false,
      }
    }
    case GET_EVALUATION_REQUEST: {
      return {
        ...state,
        evaluationDetailLoading: true,
      }
    }
    case GET_EVALUATION_SUCCESS: {
      return {
        ...state,
        evaluationDetail: action.data,
        evaluationDetailLoading: false,
      }
    }
    case GET_EVALUATION_FAIL: {
      return {
        ...state,
        evaluationDetailLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default evaluationReducer
