import {
  GET_CLIENTS_INVOICES_FAIL,
  GET_CLIENTS_INVOICES_REQUEST,
  GET_CLIENTS_INVOICES_SUCCESS,
  GET_CLIENT_INVOICE_FAIL,
  GET_CLIENT_INVOICE_REQUEST,
  GET_CLIENT_INVOICE_SUCCESS,
  POST_CLOSE_CLIENT_INVOICE_LOADING,
} from 'redux/actionType'

const initState = {
  clientsInvoicesList: [],
  clientsInvoicesListLoading: false,
  clientsInvoicesListMeta: {},

  clientInvoice: {},
  clientInvoiceLoading: false,
}

const clientInvoiceReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_CLIENTS_INVOICES_REQUEST: {
      return {
        ...state,
        clientsInvoicesListLoading: !action.loadNext,
      }
    }
    case GET_CLIENTS_INVOICES_SUCCESS: {
      return {
        ...state,
        clientsInvoicesList: action.loadNext
          ? [...state.clientsInvoicesList, ...action.data.objects]
          : action.data.objects,
        clientsInvoicesListMeta: action.data.meta,
        clientsInvoicesListLoading: false,
      }
    }
    case GET_CLIENTS_INVOICES_FAIL: {
      return {
        ...state,
        clientsInvoicesListLoading: false,
      }
    }
    case GET_CLIENT_INVOICE_REQUEST: {
      return {
        ...state,
        clientInvoiceLoading: true,
      }
    }
    case GET_CLIENT_INVOICE_SUCCESS: {
      return {
        ...state,
        clientInvoiceLoading: false,
        clientInvoice: action.data,
      }
    }
    case GET_CLIENT_INVOICE_FAIL: {
      return {
        ...state,
        clientInvoiceLoading: false,
      }
    }
    case POST_CLOSE_CLIENT_INVOICE_LOADING: {
      return {
        ...state,
        clientInvoiceLoading: action.data,
      }
    }
    default: {
      return state
    }
  }
}

export default clientInvoiceReducer
