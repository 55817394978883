import {
  GET_EFFICIENCY_DETAIL_FAIL,
  GET_EFFICIENCY_DETAIL_ITEMS_FAIL,
  GET_EFFICIENCY_DETAIL_ITEMS_REQUEST,
  GET_EFFICIENCY_DETAIL_ITEMS_SUCCESS,
  GET_EFFICIENCY_DETAIL_REQUEST,
  GET_EFFICIENCY_DETAIL_SUCCESS,
  GET_EFFICIENCY_FAIL,
  GET_EFFICIENCY_REQUEST,
  GET_EFFICIENCY_SUCCESS,
  PATCH_EFFICIENCY_DETAIL_SUCCESS,
} from 'redux/actionType'

const initState = {
  efficiency: [],
  efficiencyLoading: false,
  efficiencyMeta: {},

  efficiencyDetail: [],
  efficiencyDetailLoading: false,

  efficiencyDetailItemsLoading: false,
  efficiencyDetailItems: [],
}

const efficiencyReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_EFFICIENCY_REQUEST: {
      return {
        ...state,
        efficiencyLoading: !action.loadNext,
      }
    }
    case GET_EFFICIENCY_SUCCESS: {
      return {
        ...state,
        efficiency: action.loadNext
          ? [...state.efficiency, ...action.data.objects]
          : action.data.objects,
        efficiencyMeta: action.data.meta,
        efficiencyLoading: false,
      }
    }
    case GET_EFFICIENCY_FAIL: {
      return {
        ...state,
        efficiencyLoading: false,
      }
    }
    case GET_EFFICIENCY_DETAIL_REQUEST: {
      return {
        ...state,
        efficiencyDetailLoading: true,
      }
    }
    case GET_EFFICIENCY_DETAIL_SUCCESS: {
      return {
        ...state,
        efficiencyDetail: action.data,
        efficiencyDetailLoading: false,
      }
    }
    case GET_EFFICIENCY_DETAIL_ITEMS_REQUEST: {
      return {
        ...state,
        efficiencyDetailItemsLoading: true,
      }
    }
    case GET_EFFICIENCY_DETAIL_ITEMS_SUCCESS: {
      return {
        ...state,
        efficiencyDetailItems: action.data,
        efficiencyDetailItemsLoading: false,
      }
    }
    case GET_EFFICIENCY_DETAIL_ITEMS_FAIL: {
      return {
        ...state,
        efficiencyDetailItemsLoading: false,
      }
    }
    case GET_EFFICIENCY_DETAIL_FAIL: {
      return {
        ...state,
        efficiencyDetailLoading: false,
      }
    }
    case PATCH_EFFICIENCY_DETAIL_SUCCESS: {
      const patchItemIndex = state.efficiencyDetailItems?.findIndex(
        (item) => item.id === action.data.id
      )
      const patchItems = [...state.efficiencyDetailItems]

      patchItems[patchItemIndex] = {
        ...state.efficiencyDetailItems[patchItemIndex],
        ...action.data,
      }

      return {
        ...state,
        efficiencyDetailItems: patchItems,
      }
    }
    default: {
      return state
    }
  }
}

export default efficiencyReducer
