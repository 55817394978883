import {LS_LOGGED_USER} from 'helper/configConstants'
import {getItemFromStorage} from 'helper/functions'
import mapPermissionsToAbilities from 'helper/mapPermissionsToAbilities'
import {createContext} from 'react'
import {Ability} from '@casl/ability'
import {createContextualCan} from '@casl/react'

const currentUser = getItemFromStorage(LS_LOGGED_USER)
const permissions = currentUser?.permissions || []
export const isCurrentUserAdmin = currentUser?.roles?.some((r) => r.name === 'admin')

// USAGE
// <Can I="read" an="Employees"> display/hide
// or
// const canReadEmployees = authorizedAbility.can('read', 'Employees') true/false

export const authorizedAbility = new Ability(mapPermissionsToAbilities(permissions))

export const AbilityContext = createContext()
export const AbilityContextProvider = AbilityContext.Provider
export const Can = createContextualCan(AbilityContext.Consumer)
