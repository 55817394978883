import CustomTooltip from 'component/material/CustomTooltip'
import DialogWindow from 'component/material/DialogWindow'
import Table from 'component/material/table/Table'
import {authorizedAbility} from 'helper/authorizedAbility'
import {getDateTimeWithCurrentTimeZone, redirectTo} from 'helper/functions'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {getClientsToVerify} from 'redux/action/clientAction'
import {Trans} from '@lingui/macro'
import {IconButton} from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import {withStyles} from '@material-ui/core/styles'
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

const TopRightIconButtons = (props) => {
  const {
    classes,
    numberOfUnreadMessages,
    getClientsToVerify,
    clientsToVerifyLoading,
    clientsToVerify,
  } = props

  // open clients to for first page load
  const [openClientsToVerify, setOpenClientsToVerify] = useState(false)

  const clientsToVerifyColumns = [
    {
      name: 'name',
      label: <Trans>Client name</Trans>,
    },
    {
      name: 'updated_at',
      label: <Trans>Last update</Trans>,
      render: (val) => val && getDateTimeWithCurrentTimeZone(val),
    },
  ]

  const handleClientClick = (row) => {
    if (authorizedAbility.can('read', 'Client')) {
      redirectTo('/clients/' + row.id)
      handleCloseClientsToVerify()
    }
  }

  const handleOpenClientsToVerify = () => {
    setOpenClientsToVerify(true)
  }

  const handleCloseClientsToVerify = () => {
    setOpenClientsToVerify(false)
  }

  const handleRedirectToMessages = () => {
    redirectTo('/inbox')
  }

  useEffect(() => {
    getClientsToVerify().then((res) => {
      if (res?.objects?.length !== 0) {
        // timeout to give time for animation to finish
        return setTimeout(() => {
          setOpenClientsToVerify(true)
        }, 1500)
      }
    })
  }, [])

  return (
    <>
      <div className={classes.topAppBarClientsToVerify}>
        <CustomTooltip title={<Trans>Clients to verify</Trans>}>
          <IconButton className={classes.topAppBarLogoutIcon} onClick={handleOpenClientsToVerify}>
            {clientsToVerify?.length ? (
              <Badge
                overlap="rectangular"
                badgeContent={clientsToVerify?.length}
                color="primary"
                className={classes.messageBadge}
              >
                <AssignmentIndOutlinedIcon />
              </Badge>
            ) : (
              <AssignmentIndOutlinedIcon />
            )}
          </IconButton>
        </CustomTooltip>

        <DialogWindow
          maxWidth={'md'}
          fullWidth={true}
          open={openClientsToVerify}
          title={<Trans>The details of the following clients need to be checked</Trans>}
          onClose={handleCloseClientsToVerify}
        >
          <Table
            loading={clientsToVerifyLoading}
            columns={clientsToVerifyColumns}
            data={clientsToVerify}
            onTableRowClick={handleClientClick}
            displayCheckbox={false}
          />
        </DialogWindow>
      </div>

      <div className={classes.topAppBarNewMessage}>
        <CustomTooltip title={<Trans>New messages</Trans>}>
          <IconButton className={classes.topAppBarLogoutIcon} onClick={handleRedirectToMessages}>
            {numberOfUnreadMessages && numberOfUnreadMessages > 0 ? (
              <Badge
                overlap="rectangular"
                badgeContent={numberOfUnreadMessages}
                color="primary"
                className={classes.messageBadge}
              >
                <MailOutlineIcon />
              </Badge>
            ) : (
              <MailOutlineIcon />
            )}
          </IconButton>
        </CustomTooltip>
      </div>
    </>
  )
}

TopRightIconButtons.propTypes = {
  classes: PropTypes.object,
  numberOfUnreadMessages: PropTypes.number,
  getClientsToVerify: PropTypes.func,
  clientsToVerifyLoading: PropTypes.bool,
  clientsToVerify: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClientsToVerify,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      numberOfUnreadMessages: store.message.count,
      clientsToVerifyLoading: store.client.clientsToVerifyLoading,
      clientsToVerify: store.client.clientsToVerify,
    }
  }, mapDispatchToProps)
)(TopRightIconButtons)
