import materialStyle from 'component/material/materialStyle'
import PropTypes from 'prop-types'
import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'

const PrimaryButton = (props) => {
  const {
    text,
    textPadding = 0,
    fullWidth = false,
    variant = 'contained',
    size = 'large',
    color = 'primary',
    classes,
    ...rest
  } = props

  return (
    <Button
      color={color}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      classes={{root: classes.buttonRoot}}
      className={variant === 'outlined' ? classes.outlinedButton : ''}
      {...rest}
    >
      <Box p={textPadding}>{text}</Box>
    </Button>
  )
}

PrimaryButton.propTypes = {
  text: PropTypes.node,
  textPadding: PropTypes.number,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(materialStyle)(PrimaryButton)
