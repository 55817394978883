import i18nProvider from 'helper/i18n'
import {t} from '@lingui/macro'

/**
 * Get environment parameter from .env file
 * @param {string} envName - name of parameter
 */
export const resolveEnv = (envName) => {
  if (ENVIRONMENT === 'production') {
    return window._env_[envName]
  }
  return process.env[envName]
}

export const ENVIRONMENT = process.env.NODE_ENV
export const PUBLIC_API_KEY = resolveEnv('REACT_APP_PUBLIC_API_KEY')
export const GATEWAY_URL = resolveEnv('REACT_APP_GATEWAY_URL')

// database date format
export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ'
export const DB_DATE_TIME_FORMAT_WITHOUT_Z = 'YYYY-MM-DDTHH:mm:ss'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const MONTH_FORMAT = 'MM.YYYY'
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

export const DRAWER_WIDTH = 200
export const TOP_APP_BAR_HEIGHT = 55
export const FORM_ELEM_HEIGHT = 34

export const MARKERS = [
  {
    value: '#4D96FF',
    label: i18nProvider._(t`Client seat`),
  },
  {
    value: '#FF6B6B',
    label: i18nProvider._(t`Statutory contact`),
  },
  {
    value: '#8D3B72',
    label: i18nProvider._(t`Accountant`),
  },
]

export const COUNTRY_LIST = [
  {
    value: 'CZ',
    label: i18nProvider._(t`Czech Republic`),
  },
  {
    value: 'FR',
    label: i18nProvider._(t`France`),
  },
  {
    value: 'DE',
    label: i18nProvider._(t`Germany`),
  },
  {
    value: 'PL',
    label: i18nProvider._(t`Poland`),
  },
  {
    value: 'AT',
    label: i18nProvider._(t`Austria`),
  },
  {
    value: 'RU',
    label: i18nProvider._(t`Russia`),
  },
  {
    value: 'SK',
    label: i18nProvider._(t`Slovakia`),
  },
]

export const COOPERATION_TYPE_LIST = [
  {value: 'EMPLOYEE', label: i18nProvider._(t`Employee`)},
  {value: 'TRADESMAN', label: i18nProvider._(t`Own CIN - OSVC`)},
  {value: 'JOB_AGREEMENT', label: i18nProvider._(t`Job agreement - DPP`)},
]

export const PRICE_TYPE_LIST = [
  {value: 'GLOBAL', label: i18nProvider._(t`Global`)},
  {value: 'WAGE', label: i18nProvider._(t`Wages`)},
  {value: 'ACCOUNTING', label: i18nProvider._(t`Accounting`)},
  {value: 'TAX_EVIDENCE', label: i18nProvider._(t`Tax evidence`)},
]

export const PRICE_SUB_TYPE_LIST = [
  {value: 'GLOBAL', label: i18nProvider._(t`Global`)},
  {value: 'EXTRA_CHARGE', label: i18nProvider._(t`Extra charge`)},
  {value: 'DISCOUNT', label: i18nProvider._(t`Discount`)},
  {value: 'CLAIM', label: i18nProvider._(t`Claim`)},
  {value: 'ONE_TIME', label: i18nProvider._(t`One time`)},
]

export const CLIENT_CONTRACT = [
  {value: 'SIGNED', label: i18nProvider._(t`Yes`)},
  {value: 'UNSIGNED', label: i18nProvider._(t`No`)},
  {value: 'READY_TO_SIGN', label: i18nProvider._(t`Ready to sign`)},
]

export const CLIENT_STATUS = [
  {value: 'ACTIVE', label: i18nProvider._(t`Active`)},
  {value: 'OLD', label: i18nProvider._(t`Inactive`)},
]

export const CLIENT_TYPE = [
  {value: 'PERSON', label: i18nProvider._(t`Person`)},
  {value: 'TRADESMAN', label: i18nProvider._(t`Tradesman`)},
  {value: 'COMPANY', label: i18nProvider._(t`Company`)},
]

export const CLIENT_CONTACT_TYPE = [
  {value: 'OTHER_CONTACT', label: i18nProvider._(t`Other contacts`)},
  {value: 'BUSINESS_CONTACT', label: i18nProvider._(t`Business contact`)},
  {value: 'STATUTORY_AUTHORITY', label: i18nProvider._(t`Statutory authority`)},
  {value: 'FINANCIAL_OFFICE', label: i18nProvider._(t`Financial office`)},
  {value: 'SOCIAL_CARE_OFFICE', label: i18nProvider._(t`Social care office`)},
  {value: 'HEALTH_CARE_OFFICE', label: i18nProvider._(t`Health care office`)},
  {value: 'INVOICING_EMAIL', label: i18nProvider._(t`Invoicing e-mail`)},
]

export const CLIENT_BILLING_TYPE = [
  {value: 'SINGLE_TIME', label: i18nProvider._(t`Single time`)},
  {value: 'FEE', label: i18nProvider._(t`A flat rate`)},
  {value: 'ITEMS', label: i18nProvider._(t`Itemized`)},
  {value: 'HOURS', label: i18nProvider._(t`Hourly`)},
  {value: 'GRATIS', label: i18nProvider._(t`Gratis`)},
]

export const CLIENT_PROCESSING_TYPE = [
  {value: 'ACCOUNTING', label: i18nProvider._(t`Accounting processing type`)},
  {value: 'ACCOUNTING_NONPROFITS', label: i18nProvider._(t`Accounting nonprofits`)},
  {value: 'TAX_REGISTER', label: i18nProvider._(t`Tax register`)},
  {value: 'WAGES', label: i18nProvider._(t`Wages`)},
  {value: 'REMOTE_CONNECTION', label: i18nProvider._(t`Remote connection`)},
  {value: 'INCOME_TAX_RETURN', label: i18nProvider._(t`Income tax return`)},
  {value: 'OTHER_TAX_RETURN', label: i18nProvider._(t`Other tax return`)},
  {value: 'CONSULTING', label: i18nProvider._(t`Consulting`)},
  {value: 'OTHER', label: i18nProvider._(t`Other processing type`)},
]

export const DOCUMENT_TRANSFER_TYPE = [
  {value: 'UNDEFINED', label: i18nProvider._(t`Undefined`)},
  {value: 'TO_PROVIDER', label: i18nProvider._(t`To provider`)},
  {value: 'FROM_CLIENT', label: i18nProvider._(t`From client`)},
  {value: 'ELECTRONIC', label: i18nProvider._(t`Electronic`)},
]

export const CLIENT_WORK_PLACE = [
  {value: 'UNDEFINED', label: i18nProvider._(t`Undefined`)},
  {value: 'PROVIDER', label: i18nProvider._(t`Peklo`)},
  {value: 'CLIENT', label: i18nProvider._(t`Client`)},
  {value: 'REMOTE_CONNECTION', label: i18nProvider._(t`Remote connection to the client`)},
]

export const BILLING_FREQUENCY = [
  {value: 'SINGLE_TIME', label: i18nProvider._(t`Single time`)},
  {value: 'MONTHLY', label: i18nProvider._(t`Monthly`)},
  {value: 'QUARTERLY', label: i18nProvider._(t`Quarterly`)},
  {value: 'HALF_YEARLY', label: i18nProvider._(t`Half-yearly`)},
  {value: 'YEARLY', label: i18nProvider._(t`Yearly`)},
  {value: 'GRATIS', label: i18nProvider._(t`Gratis`)},
]

export const BILLING_TYPE = [
  {value: 'SINGLE_TIME', label: i18nProvider._(t`Single time`)},
  {value: 'FEE', label: i18nProvider._(t`A flat rate`)},
  {value: 'ITEMS', label: i18nProvider._(t`Itemized`)},
  {value: 'HOURS', label: i18nProvider._(t`Hourly`)},
  {value: 'GRATIS', label: i18nProvider._(t`Gratis`)},
]

export const VAT_PERIODICITY = [
  {value: 'NOT_PAYER', label: i18nProvider._(t`Not payer`)},
  {value: 'MONTHLY', label: i18nProvider._(t`Monthly`)},
  {value: 'QUARTERLY', label: i18nProvider._(t`Quarterly`)},
  {value: 'IDENTIFIED_PERSON', label: i18nProvider._(t`Identified person`)},
]

export const ACCOUNTING_SOFTWARE = [
  {value: 'POHODA', label: i18nProvider._(t`Pohoda`)},
  {value: 'MICRONET', label: i18nProvider._(t`Micronet`)},
  {value: 'HELIOS_RED', label: i18nProvider._(t`Helios Red`)},
  {value: 'PAMICA', label: i18nProvider._(t`PAMICA`)},
  {value: 'ABRA', label: i18nProvider._(t`ABRA`)},
  {value: 'KELOC', label: i18nProvider._(t`Keloc`)},
  {value: 'OTHER', label: i18nProvider._(t`Other accounting software`)},
  {value: 'UNDEFINED', label: i18nProvider._(t`Undefined`)},
]

export const ACCOUNTING_SERVICES = [
  {value: 'BILLING', label: i18nProvider._(t`Billing`)},
  {value: 'BILLING_OTHER', label: i18nProvider._(t`Other`)},
  {value: 'BILLING_CONTROLS', label: i18nProvider._(t`Billing/controls`)},
  {value: 'HOUR_RATE', label: i18nProvider._(t`Hour rate`)},
  {value: 'WAGES', label: i18nProvider._(t`Wages`)},
]

export const TARIFF_TYPE = [
  {value: 'NONE', label: i18nProvider._(t`None`)},
  {value: 'MAIN_ACCOUNTANT_AND_TRAINING', label: i18nProvider._(t`Main accountant and training`)},
  {value: 'ADDITIONAL_WORK', label: i18nProvider._(t`Additional work`)},
]

export const WORK_TYPE = [
  {value: 'ACCOUNTANCY', label: i18nProvider._(t`Accountancy`)},
  {value: 'WAGES', label: i18nProvider._(t`Wages`)},
  {value: 'TAXES', label: i18nProvider._(t`Taxes`)},
  {value: 'COMPANY_SETUP_AND_TAKEOVER', label: i18nProvider._(t`Company setup and takeover`)},
  {value: 'DOCTOR', label: i18nProvider._(t`Doctor`)},
  {value: 'VACATION', label: i18nProvider._(t`Vacation`)},
  {value: 'ILLNESS', label: i18nProvider._(t`Illness`)},
  {value: 'ORAKULUM', label: i18nProvider._(t`Orakulum`)},
  {value: 'OTHERS', label: i18nProvider._(t`Other work type`)},
]

export const TAX_DATES_TYPE = [
  {
    value: 'ACCOUNTING_CLAIM',
    label: i18nProvider._(t`DPPO – včetně závěrky`),
  },
  {
    value: 'ACCOUNTING_CLAIM_FULL',
    label: i18nProvider._(t`DPPO – včetně závěrky v plném rozsahu`),
  },
  {
    value: 'ACCOUNTING_CLAIM_DOCUMENT',
    label: i18nProvider._(t`DPPO – příprava podkladů k přiznání (DPPO zpracuje cizí subjekt)`),
  },
  {
    value: 'ACCOUNTING_CLAIM_PROLONGATION',
    label: i18nProvider._(t`DPPO – odklad přiznání`),
  },
  {
    value: 'ACCOUNTING_CLAIM_NONPROFIT',
    label: i18nProvider._(t`Nezisk – DPPO s hospodářskou činností včetně závěrky`),
  },
  {
    value: 'ACCOUNTING_CLAIM_NONPROFIT_FULL',
    label: i18nProvider._(t`Nezisk – DPPO s hospodářskou činností včetně závěrky v plném rozsahu`),
  },
  {
    value: 'ACCOUNTING_CLAIM_DOCUMENT_NONPROFIT_FULL',
    label: i18nProvider._(t`Nezisk – závěrka a podklady pro rejstřík (bez povinnosti podat DPPO)`),
  },
  {
    value: 'ACCOUNTING_CLAIM_DOCUMENT_NONPROFIT',
    label: i18nProvider._(t`Nezisk – příprava podkladů k přiznání (DPPO zpracuje cizí subjekt)`),
  },
  {
    value: 'WAGE_CLAIM',
    label: i18nProvider._(t`DPFO – pouze mzdy, příjmy z pronájmu, kapitálu, ostatní- bez SP a ZP`),
  },
  {
    value: 'GLOBAL_CLAIM',
    label: i18nProvider._(t`DPFO – DE či % + SP a ZP`),
  },
  {
    value: 'GLOBAL_CLAIM_FULL',
    label: i18nProvider._(t`DPFO – PÚ včetně závěrky + SP a ZP`),
  },
  {
    value: 'GLOBAL_CLAIM_DOCUMENT',
    label: i18nProvider._(t`DPFO – příprava podkladů k přiznání (DPFO zpracuje cizí subjekt)`),
  },
  {
    value: 'GLOBAL_CLAIM_PROLONGATION',
    label: i18nProvider._(t`DPFO – odklad přiznání vč. spolupracující osoby`),
  },
  {
    value: 'GLOBAL_CLAIM_ROAD_TAX',
    label: i18nProvider._(t`Silniční daň`),
  },
]

export const INVOICE_TYPE = [
  {
    value: 'WORK',
    label: i18nProvider._(t`Work`),
  },
  {
    value: 'CONSULTING',
    label: i18nProvider._(t`Consulting`),
  },
]

export const QUOTE_STATUS_TYPE = [
  {
    value: 'CREATED',
    label: i18nProvider._(t`Created`),
  },
  {
    value: 'SENT',
    label: i18nProvider._(t`Sent`),
  },
  {
    value: 'ACCEPTED',
    label: i18nProvider._(t`Accepted`),
  },
]

export const PRICE_TARIFF_TYPE = [
  {
    value: 'HOURLY',
    label: i18nProvider._(t`Hourly`),
  },
  {
    value: 'PER_ITEM',
    label: i18nProvider._(t`Per item`),
  },
  {
    value: 'PER_EMPLOYEE',
    label: i18nProvider._(t`Per employee`),
  },
  {
    value: 'PER_MONTH',
    label: i18nProvider._(t`Per month`),
  },
  {
    value: 'PER_HALF_YEAR',
    label: i18nProvider._(t`Per half year`),
  },
]

export const ACCOUNTANT_TYPE = [
  {
    value: 'FINANCIAL_ACCOUNTANT',
    label: i18nProvider._(t`Financial accountant`),
  },
  {
    value: 'PAYROLL_ACCOUNTANT',
    label: i18nProvider._(t`Payroll accountant`),
  },
  {
    value: 'DEPUTY_ACCOUNTANT',
    label: i18nProvider._(t`Deputy accountant`),
  },
]

export const EFFICIENCY_STATUS = [
  {
    value: 'IN_PROGRESS',
    label: i18nProvider._(t`In progress`),
  },
  {
    value: 'DONE',
    label: i18nProvider._(t`Done`),
  },
  {
    value: 'FAILED',
    label: i18nProvider._(t`Failed`),
  },
]

export const CUSTOMER_VAT_STATUS = [
  {
    value: 'PAYER',
    label: i18nProvider._(t`Payer`),
  },
  {
    value: 'NOT_PAYER',
    label: i18nProvider._(t`Not payer`),
  },
]
