import {
  GET_LIST_REPORT_FAIL,
  GET_LIST_REPORT_REQUEST,
  GET_LIST_REPORT_SUCCESS,
  GET_TIMERECORD_VERSIONS_FAIL,
  GET_TIMERECORD_VERSIONS_REQUEST,
  GET_TIMERECORD_VERSIONS_SUCCESS,
  GET_TIMERECORD_VERSION_DIFF_FAIL,
  GET_TIMERECORD_VERSION_DIFF_REQUEST,
  GET_TIMERECORD_VERSION_DIFF_SUCCESS,
} from 'redux/actionType'

const initState = {
  reportList: [],
  reportListMeta: {},
  reportListMessage: undefined,
  reportListLoading: false,

  timerecordHistory: [],
  timerecordHistoryLoading: false,
  timerecordHistoryDiff: {},
  timerecordHistoryDiffLoading: false,
}

const timeRecordReportReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_LIST_REPORT_REQUEST: {
      return {
        ...state,
        reportList: [],
        reportListMeta: {},
        reportListLoading: true,
      }
    }
    case GET_LIST_REPORT_SUCCESS: {
      return {
        ...state,
        reportList: action.data.objects,
        reportListMeta: action.data.meta,
        reportListMessage: action.data.message,
        reportListLoading: false,
      }
    }
    case GET_LIST_REPORT_FAIL: {
      return {
        ...state,
        reportListLoading: false,
      }
    }
    case GET_TIMERECORD_VERSIONS_REQUEST: {
      return {
        ...state,
        timerecordHistoryLoading: true,
      }
    }
    case GET_TIMERECORD_VERSIONS_SUCCESS: {
      return {
        ...state,
        timerecordHistoryLoading: false,
        timerecordHistory: action.data,
      }
    }
    case GET_TIMERECORD_VERSIONS_FAIL: {
      return {
        ...state,
        timerecordHistoryLoading: false,
      }
    }
    case GET_TIMERECORD_VERSION_DIFF_REQUEST: {
      return {
        ...state,
        timerecordHistoryDiffLoading: true,
      }
    }
    case GET_TIMERECORD_VERSION_DIFF_SUCCESS: {
      return {
        ...state,
        timerecordHistoryDiffLoading: false,
        timerecordHistoryDiff: action.data,
      }
    }
    case GET_TIMERECORD_VERSION_DIFF_FAIL: {
      return {
        ...state,
        timerecordHistoryDiffLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default timeRecordReportReducer
