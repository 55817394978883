import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'
import httpClient from 'helper/httpClient'
import moment from 'moment'
import {
  GET_ARES_CLIENT_FAIL,
  GET_ARES_CLIENT_REQUEST,
  GET_ARES_CLIENT_SUCCESS,
  GET_CLIENTS_TO_VERIFY_FAIL,
  GET_CLIENTS_TO_VERIFY_REQUEST,
  GET_CLIENTS_TO_VERIFY_SUCCESS,
  GET_CLIENT_CONTACT_VERSIONS_FAIL,
  GET_CLIENT_CONTACT_VERSIONS_REQUEST,
  GET_CLIENT_CONTACT_VERSIONS_SUCCESS,
  GET_CLIENT_CONTACT_VERSION_DIFF_FAIL,
  GET_CLIENT_CONTACT_VERSION_DIFF_REQUEST,
  GET_CLIENT_CONTACT_VERSION_DIFF_SUCCESS,
  GET_CLIENT_FAIL,
  GET_CLIENT_INVOICE_ITEMS_FAIL,
  GET_CLIENT_INVOICE_ITEMS_REQUEST,
  GET_CLIENT_INVOICE_ITEMS_SUCCESS,
  GET_CLIENT_INVOICE_LIST_FAIL,
  GET_CLIENT_INVOICE_LIST_REQUEST,
  GET_CLIENT_INVOICE_LIST_SUCCESS,
  GET_CLIENT_LIST_ONLY_FAIL,
  GET_CLIENT_LIST_ONLY_REQUEST,
  GET_CLIENT_LIST_ONLY_SUCCESS,
  GET_CLIENT_REQUEST,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_TAX_DATES_FAIL,
  GET_CLIENT_TAX_DATES_REQUEST,
  GET_CLIENT_TAX_DATES_SUCCESS,
  GET_CLIENT_VERSIONS_FAIL,
  GET_CLIENT_VERSIONS_REQUEST,
  GET_CLIENT_VERSIONS_SUCCESS,
  GET_CLIENT_VERSION_DIFF_FAIL,
  GET_CLIENT_VERSION_DIFF_REQUEST,
  GET_CLIENT_VERSION_DIFF_SUCCESS,
  GET_LIST_CLIENT_FAIL,
  GET_LIST_CLIENT_REQUEST,
  GET_LIST_CLIENT_SUCCESS,
  GET_SMARTEMAILING_STATUS_FAIL,
  GET_SMARTEMAILING_STATUS_REQUEST,
  GET_SMARTEMAILING_STATUS_SUCCESS,
  POST_SYNC_SMARTEMAILING_FAIL,
  POST_SYNC_SMARTEMAILING_REQUEST,
  POST_SYNC_SMARTEMAILING_SUCCESS,
  UPDATE_GLOBAL_LOADING,
} from 'redux/actionType'

export const getClientList = (
  limit,
  offset,
  meta,
  show_inactive,
  filters = {},
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_CLIENT_REQUEST, loadNext: loadNext})
    return httpClient
      .get(endpoints.clients, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        show_inactive: show_inactive || null,
        ...filters,
      })
      .then((res) => {
        dispatch({type: GET_LIST_CLIENT_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getMyClientList = (
  limit,
  offset,
  meta,
  show_inactive,
  filters = {},
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_CLIENT_REQUEST, loadNext: loadNext})
    return httpClient
      .get(endpoints.clients + '/my-clients', {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        show_inactive: show_inactive || null,
        ...filters,
      })
      .then((res) => {
        dispatch({type: GET_LIST_CLIENT_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getMyClientInDeputyList = (
  limit,
  offset,
  meta,
  show_inactive,
  filters = {},
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_CLIENT_REQUEST, loadNext: loadNext})
    return httpClient
      .get(endpoints.clients + '/my-clients-in-deputy', {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        show_inactive: show_inactive || null,
        ...filters,
      })
      .then((res) => {
        dispatch({type: GET_LIST_CLIENT_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const searchClientList = (
  limit,
  offset,
  show_inactive,
  search,
  ids,
  tableSort,
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_CLIENT_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (show_inactive) urlParams += `&show_inactive=${show_inactive}`
    if (ids?.length) urlParams += `&ids=${ids.join(',')}`
    if (tableSort.orderBy && tableSort?.orderDirection)
      urlParams += `&order_by=${tableSort?.orderBy}:${tableSort?.orderDirection}`

    return httpClient
      .post(
        endpoints.clients + '/search' + urlParams,
        {query: search || null},
        {
          headers: {tz: moment()?.format('Z')},
        }
      )
      .then((res) => {
        dispatch({type: GET_LIST_CLIENT_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const partialSearchClientList = (
  limit,
  offset,
  show_inactive,
  search,
  ids,
  tableSort,
  loadNext = false
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_CLIENT_REQUEST, loadNext: loadNext})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (show_inactive) urlParams += `&show_inactive=${show_inactive}`
    if (ids?.length) urlParams += `&ids=${ids.join(',')}`
    if (tableSort.orderBy && tableSort?.orderDirection)
      urlParams += `&order_by=${tableSort?.orderBy}:${tableSort?.orderDirection}`

    return httpClient
      .post(endpoints.clients + '/partial-search' + urlParams, search, {
        headers: {tz: moment()?.format('Z')},
      })
      .then((res) => {
        dispatch({type: GET_LIST_CLIENT_SUCCESS, data: res.data, loadNext: loadNext})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClient = (clientId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_REQUEST})
    return httpClient
      .get(endpoints.clients + '/' + clientId)
      .then((res) => {
        dispatch({type: GET_CLIENT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientSimplified = (clientId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_REQUEST})
    return httpClient
      .get(endpoints.clients + '/' + clientId + '/simplified')
      .then((res) => {
        dispatch({type: GET_CLIENT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const createClient = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.clients, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchClient = (clientId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.clients + '/' + clientId, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteClient = (clientId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(endpoints.clients + '/' + clientId)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteClientEmployee = (clientId, employeeId, type) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.clients}/${clientId}/delete-employee/${employeeId}?type=${type}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteClientContact = (clientId, contactId, type) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.clients}/${clientId}/delete-contact/${contactId}?type=${type}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteDataFile = (clientId, fileId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.clients}/${clientId}/delete-pohoda-file/${fileId}`)
      .then((res) => {
        return Promise.resolve(res)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientFromAres = (cin) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_ARES_CLIENT_REQUEST})
    return httpClient
      .get(endpoints.ares, cin)
      .then((res) => {
        dispatch({type: GET_ARES_CLIENT_SUCCESS, data: res.data})
        return Promise.resolve(res)
      })
      .catch((err) => {
        dispatch({type: GET_ARES_CLIENT_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const resetAresData = (data) => {
  return (dispatch) => {
    dispatch({type: GET_ARES_CLIENT_SUCCESS, data: data})
  }
}

export const getClientsToVerify = (limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENTS_TO_VERIFY_REQUEST})
    return httpClient
      .get(endpoints.clients + '/clients-to-verify', {limit: limit, offset: offset})
      .then((res) => {
        dispatch({type: GET_CLIENTS_TO_VERIFY_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENTS_TO_VERIFY_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientVersions = (clientId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_VERSIONS_REQUEST})
    return httpClient
      .get(`${endpoints.clients}/${clientId}/versions`, {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_CLIENT_VERSIONS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_VERSIONS_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientVersionsDiff = (clientId, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_VERSION_DIFF_REQUEST})
    return httpClient
      .get(`${endpoints.clients}/${clientId}/versions/${versionId}/diff`)
      .then((res) => {
        dispatch({type: GET_CLIENT_VERSION_DIFF_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_VERSION_DIFF_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientVersionsRollback = (clientId, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.clients}/${clientId}/versions/${versionId}/rollback`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientContactVersions = (contactId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_CONTACT_VERSIONS_REQUEST})
    return httpClient
      .get(`${endpoints.contacts}/${contactId}/versions`, {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_CLIENT_CONTACT_VERSIONS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_CONTACT_VERSIONS_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientContactVersionsDiff = (contactId, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_CONTACT_VERSION_DIFF_REQUEST})
    return httpClient
      .get(`${endpoints.contacts}/${contactId}/versions/${versionId}/diff`)
      .then((res) => {
        dispatch({type: GET_CLIENT_CONTACT_VERSION_DIFF_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_CONTACT_VERSION_DIFF_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientContactVersionsRollback = (contactId, versionId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.contacts}/${contactId}/versions/${versionId}/rollback`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const exportContactsToExcel = (idList = [], searchQuery) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(
        endpoints.exportToExcel,
        {
          ids: idList.length ? idList.join(',') : null,
          ...searchQuery,
        },
        {
          headers: {
            'Content-Type': 'application/xlsx',
            Accept: 'application/xlsx',
            tz: moment()?.format('Z'),
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const exportContactsEmailsToExcel = (idList = [], searchQuery) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(
        endpoints.exportContactToExcel,
        {
          ids: idList.length ? idList.join(',') : null,
          ...searchQuery,
        },
        {
          headers: {
            'Content-Type': 'application/xlsx',
            Accept: 'application/xlsx',
            tz: moment()?.format('Z'),
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const exportContactsToSmartEmailing = (idList = []) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: POST_SYNC_SMARTEMAILING_REQUEST})
    let query = idList.length ? `?ids=${idList.join(',')}` : ''
    return httpClient
      .post(`${endpoints.syncSmartEmailing}${query}`)
      .then((res) => {
        dispatch({type: POST_SYNC_SMARTEMAILING_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: POST_SYNC_SMARTEMAILING_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getSmartEmailingSyncStatus = (taskName) => {
  return (dispatch) => {
    dispatch({type: GET_SMARTEMAILING_STATUS_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.smartEmailingStatus, {task_name: taskName})
      .then((res) => {
        dispatch({type: GET_SMARTEMAILING_STATUS_SUCCESS, data: res.data})
        if (res.data.status !== 'IN_PROGRESS') {
          dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
          dispatch({type: GET_SMARTEMAILING_STATUS_FAIL})
        }
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SMARTEMAILING_STATUS_FAIL})
        globalApiErrorHandler(err)
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        return Promise.reject(err?.response)
      })
  }
}

export const getClientListOnly = (limit, offset, meta, showInactive, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_LIST_ONLY_REQUEST})
    return httpClient
      .get(`${endpoints.clients}/simplified`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        ...filters,
        show_inactive: showInactive || null,
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_LIST_ONLY_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_LIST_ONLY_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientTaxDates = (
  clientId,
  limit = 1000,
  offset = 0,
  meta = {orderBy: 'submission_date', orderDirection: 'DESC'}
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_TAX_DATES_REQUEST})
    return httpClient
      .get(`${endpoints.clients}/${clientId}/tax-dates`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_TAX_DATES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_TAX_DATES_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const createClientTaxDate = (clientId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.clients}/${clientId}/tax-dates`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchClientTaxDate = (clientId, taxDateId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.clients}/${clientId}/tax-dates/${taxDateId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteClientTaxDate = (clientId, taxDateId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.clients}/${clientId}/tax-dates/${taxDateId}`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

// CLIENT INVOICE ITEMS
export const getClientInvoiceItems = (clientId, limit, offset, meta) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_INVOICE_ITEMS_REQUEST, data: true})
    return httpClient
      .get(`${endpoints.clients}/${clientId}/invoice-items`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        show_only_manual: true,
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_INVOICE_ITEMS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_INVOICE_ITEMS_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postClientInvoiceItem = (clientId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.clients}/${clientId}/invoice-items`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchClientInvoiceItem = (clientId, invoiceItemId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.clients}/${clientId}/invoice-items/${invoiceItemId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteClientInvoiceItem = (clientId, invoiceItemId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.clients}/${clientId}/invoice-items/${invoiceItemId}`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

// GET LIST OF INVOICES FOR ONE CLIENT
export const getClientInvoices = (clientId, limit, offset, showDeleted, intervalFilters, meta) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_INVOICE_LIST_REQUEST})

    return httpClient
      .get(`${endpoints.clients}/${clientId}/invoices`, {
        limit: limit,
        offset: offset,
        show_deleted: showDeleted || null,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
        ...intervalFilters,
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_INVOICE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_INVOICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const searchClientInvoices = (
  clientId,
  limit,
  offset,
  showDeleted,
  intervalFilters,
  search,
  ids,
  tableSort
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_INVOICE_LIST_REQUEST})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (intervalFilters.date_from) urlParams += `&date_from=${intervalFilters.date_from}`
    if (intervalFilters.date_to) urlParams += `&date_to=${intervalFilters.date_to}`
    if (showDeleted) urlParams += `&show_deleted=${showDeleted}`
    if (ids?.length) urlParams += `&ids=${ids.join(',')}`
    if (tableSort.orderBy && tableSort?.orderDirection)
      urlParams += `&order_by=${tableSort?.orderBy}:${tableSort?.orderDirection}`

    return httpClient
      .post(`${endpoints.clients}/${clientId}/invoices/search${urlParams}`, {query: search || null})
      .then((res) => {
        dispatch({type: GET_CLIENT_INVOICE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_INVOICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const partialSearchClientInvoices = (
  clientId,
  limit,
  offset,
  showDeleted,
  intervalFilters,
  search,
  ids,
  tableSort
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_INVOICE_LIST_REQUEST})

    let urlParams = `?limit=${limit}&offset=${offset}`
    if (intervalFilters.date_from) urlParams += `&date_from=${intervalFilters.date_from}`
    if (intervalFilters.date_to) urlParams += `&date_to=${intervalFilters.date_to}`
    if (showDeleted) urlParams += `&show_deleted=${showDeleted}`
    if (ids?.length) urlParams += `&ids=${ids.join(',')}`
    if (tableSort.orderBy && tableSort?.orderDirection)
      urlParams += `&order_by=${tableSort?.orderBy}:${tableSort?.orderDirection}`

    return httpClient
      .post(`${endpoints.clients}/${clientId}/invoices/partial-search${urlParams}`, search, {
        headers: {tz: moment()?.format('Z')},
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_INVOICE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_INVOICE_LIST_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const generateClientInvoice = (clientId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.clients}/${clientId}/invoices/generate-invoices`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientListForMap = (limit, offset, meta) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_LIST_CLIENT_REQUEST})
    return httpClient
      .get(`${endpoints.clients}/map`, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_LIST_CLIENT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_LIST_CLIENT_FAIL})
        return globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClientExportByColumns = (
  limit,
  offset,
  meta,
  show_inactive,
  fields,
  idList = [],
  searchQuery
) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(
        `${endpoints.clients}/export-xlsx`,
        {
          limit: limit,
          offset: offset,
          order_by:
            meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
          show_inactive: show_inactive || null,
          fields: fields,
          ids: idList.length ? idList.join(',') : null,
          ...searchQuery,
        },
        {
          headers: {
            'Content-Type': 'application/xlsx',
            Accept: 'application/xlsx',
            tz: moment()?.format('Z'),
          },
          responseType: 'arraybuffer',
        }
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch(globalApiErrorHandler)
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const validateClientValueWithId = (id, values) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.clients}/${id}/validate`, values)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const validateClientValue = (values) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.clients}/validate`, values)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const sendPersonalizedShortMessages = (ids, message) => {
  return (dispatch) => {
    let urlParams = ''
    if (ids?.length) urlParams += `?ids=${ids.join(',')}`

    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.clients}/send-personalized-short-messages` + urlParams, {message: message})
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
