import {FORM_ELEM_HEIGHT} from 'helper/constants'

const fieldStyle = (theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',
  },

  requiredColor: {
    color: theme.palette.error.main,
  },

  // chips used inside multi select
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  chip: {
    margin: 2,
  },

  // menu item inside the select paper
  menuItem: {
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },

  // number inputs
  inputJustifyRight: {
    '& input': {
      textAlign: 'end',
    },
  },

  inputPaddingRightZero: {
    '& input': {
      paddingRight: '0 !important',
    },
  },

  // error || helper text color
  formHelperText: {
    color: theme.palette.text.disabled,
    margin: '2px 8px',
    fontSize: '12px',
    width: '100%',
  },

  formHelperErrorText: {
    color: theme.palette.error.main,
  },

  noDataText: {
    color: theme.palette.text.disabled,
  },

  // loading indicator in select field
  loadingEndAdornment: {
    paddingRight: '20px',
    '& .MuiCircularProgress-root': {
      width: '20px !important',
      height: '20px !important',
    },
  },

  // basic field label
  fieldLabel: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
    minWidth: '100%',
  },

  inputField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.white,

      '& .MuiOutlinedInput-input': {
        padding: '6px 12px',
        fontSize: 14,
        lineHeight: 1.42857143,
        height: FORM_ELEM_HEIGHT,
        boxSizing: 'border-box',
        color: theme.palette.text.secondary + '!important',
      },
    },
    '& .MuiFormHelperText-root': {
      margin: '2px 8px',
      fontSize: 12,
    },

    '& .MuiOutlinedInput-multiline': {
      padding: '6px 12px',

      '& textarea': {
        height: 'auto !important',
        padding: '0 !important',
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '& input': {
        cursor: 'not-allowed',
      },
    },
  },

  messageTextArea: {
    fontFamily: theme.typography.fontFamily,
    padding: '6px 12px',
    fontSize: 14,
    boxSizing: 'border-box',
    width: '100%',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    borderRadius: theme.shape.borderRadius,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    color: theme.palette.text.secondary,

    '&:focus-visible': {
      outline: 'none',
    },
    '&:hover': {
      borderColor: theme.palette.text.primary,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      outline: '1px solid ' + theme.palette.primary.main,
    },
  },

  messageTextAreaError: {
    borderColor: theme.palette.error.main + ' !important',
    '&:focus': {
      outline: '1px solid ' + theme.palette.error.main,
    },
  },

  textAreaPlaceHolder: {
    '&::placeholder': {
      color: theme.palette.text.placeholder,
    },
  },

  autoSelectError: {
    '& fieldset': {
      borderColor: theme.palette.error.main + ' !important',
    },
    '&:focus': {
      outline: '1px solid ' + theme.palette.error.main,
    },
  },

  selectField: {
    width: '100%',

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '6px 12px',
      fontSize: 14,
      lineHeight: 1.42857143,
      minHeight: FORM_ELEM_HEIGHT,
      boxSizing: 'border-box',
      color: theme.palette.text.secondary + '!important',
      backgroundColor: theme.palette.white,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },

    '& legend > span': {
      display: 'none',
    },
  },

  multiselectPlaceholder: {
    color: theme.palette.text.placeholder + ' !important',

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      color: theme.palette.text.placeholder + ' !important',
    },
  },

  autoSelectInput: {
    width: '100%',

    '& .MuiInputBase-root': {
      height: 34,
    },

    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },

    '& .MuiAutocomplete-clearIndicator': {
      top: '-2px',
    },

    '& input': {
      height: '100%',
      padding: '6px 12px',
      boxSizing: 'border-box',
    },
  },

  multiSelectField: {
    '& .MuiChip-root': {
      height: 22,

      '& .MuiChip-label': {
        padding: '0 6px',
        fontSize: 12,
      },
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '4px 12px',
    },
  },

  selectItem: {
    padding: '6px 12px',
    fontSize: 14,
    lineHeight: 1.42857143,

    '& .MuiListItemText-root': {
      margin: 0,
      '& .MuiTypography-body1': {
        fontSize: '14px !important',
        lineHeight: 1.42857143,
      },
    },

    '& .MuiIconButton-root': {
      padding: '0 12px 0 0',

      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
  },

  readOnlyInput: {
    height: 57.2,
    width: '100%',
    padding: '6px 12px',
    fontSize: 14,
    lineHeight: 1.42857143,
    boxSizing: 'border-box',
    color: theme.palette.text.secondary,
  },

  cursorNotAllowed: {
    cursor: 'not-allowed',
  },
})

export default fieldStyle
