import {getDateTimeWithCurrentTimeZone, getHighestDate} from 'helper/functions'
import moment from 'moment/moment'
import React from 'react'
import {Trans} from '@lingui/macro'

export const historyColumns = (diffToDisplay, classes) => [
  {
    name: 'id',
    label: <Trans>Id</Trans>,
    align: 'right',
    width: '60px',
    render: (val, row, rowIndex) => (
      <span className={diffToDisplay?.id === row?.id ? classes.selectedRow : ''}>
        {rowIndex + 1}
      </span>
    ),
  },
  {
    name: 'valid_from',
    label: <Trans>Time of change</Trans>,
    render: (val, row) => (
      <>
        {getDateTimeWithCurrentTimeZone(val)}

        {row.valid_to === null ? (
          <b>
            {' '}
            (<Trans>current version</Trans>)
          </b>
        ) : (
          <b> ({`${getHighestDate(moment(val))}`})</b>
        )}
      </>
    ),
  },
]
