import {createTheme} from '@material-ui/core/styles'

const defaultTheme = createTheme({
  typography: {
    // htmlFontSize sets size of 1rem for the app
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: ['"Helvetica Neue"', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '5rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '1.1rem',
      lineHeight: 1.4,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.25,
      letterSpacing: '0.02857em',
      textTransform: 'inherit',
    },
  },
  palette: {
    // color shades generated by https://material-ui.com/customization/color/#color playground
    primary: {
      light: '#5699EA',
      main: '#428bca',
      dark: '#357ebd',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#7a7cff',
      main: '#0066b3',
      dark: '#0026ca',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      light: '#dff0d8',
      main: '#4caf50',
      dark: '#3c763d',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    warning: {
      light: '#fcf8e3',
      main: '#ff9800',
      dark: '#8a6d3b',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#333',
      secondary: '#555',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: '#777',
      white: '#ffffff',
      black: '#000000',
      placeholder: '#b8b8b8',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    white: '#ffffff',
    border: '#e3e3e3',
    borderDark: '#c4c4c4',
    loginBoxBgColor: '#f5f5f5',
    loginBgColor: '#f8f8f8',
    paperBackground: '#d3e1f1',
    background: {
      paper: '#f8f8f8',
      default: '#fafafa',
      content: '#e7f0fa',
      menu: '#cbddf3',
    },
  },
  // border radius of input fields and buttons
  shape: {
    borderRadius: 3,
  },
  // spacing sets size of padding and margin
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default defaultTheme
