import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import Toast from 'component/toast/Toast'
import {
  LS_LOGGED_USER,
  LS_SESSION_ID_NAME,
  MOCK_API,
  MOCK_PORT_MAPPING,
} from 'helper/configConstants'
import {DATE_TIME_FORMAT, ENVIRONMENT, GATEWAY_URL} from 'helper/constants'
import browserHistory from 'helper/history'
import moment from 'moment'
import React from 'react'
import {toast} from 'react-toastify'
import {Trans} from '@lingui/macro'

/**
 * Redirect user to specific url, can be used outside of component
 * @param {string} route - url to be redirected to
 */
export const redirectTo = (route) => {
  browserHistory.push(route)
}

export const redirectBack = () => {
  browserHistory.goBack()
}

/**
 * Add service name to gateway url or use mock if set in configConstants
 * @param {string} name - name of service
 */
export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  return `${GATEWAY_URL}/api/${name}`
}

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options)
}

export const fireErrorToast = (message, options = {autoClose: 15000}) => {
  return toast.error(<Toast message={message} type="error" />, options)
}

export const fireWarningToast = (message, options) => {
  return toast.warning(<Toast message={message} type="warning" />, options)
}

export const fireInfoToast = (message) => {
  return toast.info(<Toast message={message} type="info" />)
}

const loopThroughErrors = (message, errors) => {
  if (Array.isArray(errors) && errors?.length !== 0) {
    errors.forEach((err) => {
      fireErrorToast(err?.message || message)
      if (err?.message === 'Session ID not authenticated.') {
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
      }
    })
  } else {
    fireErrorToast(message)
  }
}

/**
 * Function used for basic error handling in catch(err=>globalApiErrorHandler(err))
 * @param error
 */
export const globalApiErrorHandler = (error) => {
  if (error.response) {
    const {status, data, config} = error.response

    switch (status) {
      case 400: // bad request
        loopThroughErrors(<Trans>Bad request</Trans>, data?.errors)
        return Promise.reject(data?.errors)
      case 401: // unauthorized
        fireErrorToast(<Trans>Unauthorized</Trans>)
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
        break
      case 403: // forbidden
        loopThroughErrors(<Trans>Forbidden</Trans>, data?.errors)
        return Promise.reject(data?.errors)
      case 404: // not found
        loopThroughErrors(<Trans>Not found</Trans>, data?.errors)
        return Promise.reject(data?.errors)
      case 422: // unprocessable entity
        // some requests might be returning arraybuffer in order to download xlsx file
        // unfortunately response type apply on error message as well
        if (config.responseType === 'arraybuffer') {
          const {errors} = parseArrayBufferErrorToJson(data)
          return Promise.reject(errors)
        } else if (data?.errors?.length) {
          // proper error message from BE
          return Promise.reject(data?.errors)
        }
        loopThroughErrors(<Trans>Unprocessable entity</Trans>, data?.errors)
        return Promise.reject(data?.errors)
      case 500: // internal server error
        loopThroughErrors(<Trans>Internal server error</Trans>, data?.errors)
        return Promise.reject(data?.errors)
      case 502: // bad gateway
        fireErrorToast(<Trans>Server connection issue</Trans>)
        return Promise.reject(data?.errors)
      default:
        data?.errors
          ? loopThroughErrors(<Trans>Bad request</Trans>, data.errors)
          : fireErrorToast(<Trans>Unknown server error</Trans>)
        return Promise.reject(data?.errors)
    }
  } else if (error.message === 'Network Error' || error.message === 'Server connection issue') {
    fireErrorToast(<Trans>Server connection issue</Trans>)
    return Promise.reject(error)
  } else {
    fireErrorToast(<Trans>Unknown error</Trans>)
    return Promise.reject(error)
  }
  return Promise.reject(error)
}

export const mapErrorResponseToForm = (err) => {
  let parsedError = {}
  if (Array.isArray(err)) {
    err.forEach((e) => {
      if (e.source.includes('.')) {
        let errorSplit = e.source.split('.')
        if (parsedError.hasOwnProperty(errorSplit[0])) {
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        } else {
          parsedError[errorSplit[0]] = {}
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        }
      } else {
        parsedError[e.source] = e.message
      }
    })
  }
  return parsedError
}

export const prepareOptionsForMultiSelect = (optionArray, valueKey = 'id', nameKey = 'name') => {
  if (Array.isArray(optionArray)) {
    const options = {}
    optionArray.forEach((item, index) => {
      // if you provide array of objects take id as option value
      const optionIndex = item?.[valueKey] ? item[valueKey] : index

      options[optionIndex] = item?.[nameKey] ? item[nameKey] : item
    })
    return options
  } else {
    return {}
  }
}

export const parseArrayBufferErrorToJson = (arrayBuffer) => {
  let decoded = new TextDecoder().decode(new Uint8Array(arrayBuffer))
  return JSON.parse(decoded)
}

export const isObjectEmpty = (obj) => {
  return obj && Object.keys(obj)?.length === 0 && obj.constructor === Object
}

export const getItemFromStorage = (key, defaultValue = undefined) => {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue
  } catch (e) {
    console.error(e)
    fireErrorToast(<Trans>Local storage could not be parsed</Trans>)
    return defaultValue
  }
}

export const setItemToStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item))
}

export const getImportStatus = (val) => {
  if (val === 'success') {
    return <Trans>Imported</Trans>
  } else if (val === 'failure') {
    return <Trans>Failure</Trans>
  } else {
    return val
  }
}

export const renderDetailData = (data, classes) => {
  if (data?.length === 2 && Array.isArray(data[0]) && Array.isArray(data[1])) {
    return (
      <GridContainer spacing={4}>
        <GridItem xs={12} sm={6} container alignContent={'flex-start'}>
          {data[0]?.map(
            (record, index) =>
              (record.authorized === true || record.authorized === undefined) && (
                <GridContainer
                  key={index}
                  className={classes.detailRow}
                  alignContent={'flex-start'}
                >
                  <GridItem xs={6} className={classes.detailTitleColumn}>
                    {record.title}
                  </GridItem>
                  <GridItem xs={6} className={classes.breakWord}>
                    {typeof record.value === 'function' ? record.value() : record.value}
                  </GridItem>
                </GridContainer>
              )
          )}
        </GridItem>
        <GridItem xs={12} sm={6} container alignContent={'flex-start'}>
          {data[1]?.map(
            (record, index) =>
              (record.authorized === true || record.authorized === undefined) && (
                <GridContainer
                  key={index}
                  className={classes.detailRow}
                  alignContent={'flex-start'}
                >
                  <GridItem xs={6} className={classes.detailTitleColumn}>
                    {record.title}
                  </GridItem>
                  <GridItem xs={6} className={classes.breakWord}>
                    {typeof record.value === 'function' ? record.value() : record.value}
                  </GridItem>
                </GridContainer>
              )
          )}
        </GridItem>
      </GridContainer>
    )
  } else {
    return (
      <GridItem xs={12} container alignContent={'flex-start'}>
        {data[0]?.map(
          (record, index) =>
            (record.authorized === true || record.authorized === undefined) && (
              <GridContainer key={index} className={classes.detailRow} alignContent={'flex-start'}>
                <GridItem xs={6} className={classes.detailTitleColumn}>
                  {record.title}
                </GridItem>
                <GridItem xs={6} className={classes.breakWord}>
                  {typeof record.value === 'function' ? record.value() : record.value}
                </GridItem>
              </GridContainer>
            )
        )}
      </GridItem>
    )
  }
}

export const getTimeAsString = (end, start) => {
  let finalString = ''
  let endMoment = moment(end)
  let startMoment = moment(start)

  const years = Number(endMoment.diff(startMoment, 'years', true).toString().split('.')[0])

  if (years > 0) {
    const yearsString =
      years && years === 1 ? 'rok' : years === 2 || years === 3 || years === 4 ? 'roky' : 'let'
    startMoment.add(years, 'years')
    finalString += `${years} ${yearsString},`
  }

  const months = Number(endMoment.diff(startMoment, 'months', true).toString().split('.')[0])
  if (months > 0) {
    const monthsString =
      months && months === 1
        ? 'měsíc'
        : months === 2 || months === 3 || months === 4
        ? 'měsíce'
        : 'měsíců'
    startMoment.add(months, 'months')
    finalString += ` ${months} ${monthsString},`
  }

  const days = Math.round(Number(endMoment.diff(startMoment, 'days', true)))
  const daysString =
    days && days === 1 ? 'den' : days === 2 || days === 3 || days === 4 ? 'dny' : 'dnů'
  finalString += ` ${days} ${daysString}`

  return finalString
}

// gets number of years, months or days (only one, the greatest of them)
export const getHighestDate = (validFrom) => {
  // add one minute to fix bug in history
  const date = getTimeAsString(moment().add(1, 'minute'), moment(validFrom))
  return date.split(',')[0].trim()
}

export const getName = (employee) => {
  let fullName = ''

  fullName += employee?.last_name ? `${employee?.last_name}` : ''
  fullName += employee?.first_name ? ` ${employee?.first_name}` : ''
  fullName += employee?.title_before ? ` ${employee?.title_before}` : ''
  // fullName += employee?.title_after ? ` ${employee?.title_after}` : ''

  return fullName
}

export const getContactAndAddress = (contact) => {
  const fullName = getName(contact)

  const getEmail = () => {
    let emails = contact?.email
    if (contact?.private_email) emails += `/${contact?.private_email}`
    return emails ? emails + ',' : null
  }

  const getPhone = () => {
    let phones = contact?.phone
    if (contact?.private_phone) phones += `/${contact?.private_phone}`
    return phones || null
  }

  return `<div>
            <div>${fullName ? fullName + ',' : ''}</div>
            <div>${getEmail() ? getEmail() : ''}</div>
            <div>${getPhone() ? getPhone() : ''}</div>
          </div>`
}

export const convertFloatToTime = (number) => {
  // Separate the int from the decimal part
  let hour = Math.floor(number)
  let decpart = number - hour

  let min = 1 / 60
  // Round to nearest minute
  decpart = min * Math.round(decpart / min)

  let minute = Math.floor(decpart * 60)

  // Add padding if need
  if (String(minute).length < 2) {
    minute = `0${minute}`
  }

  if (String(hour).length < 2) {
    hour = `0${hour}`
  }

  // Concat hours and minutes
  return hour + ':' + minute
}

export const convertTimeToFloat = (time) => {
  const hours = String(time).split(':')?.[0]
  let minutes = String(time).split(':')?.[1]

  if (minutes > 0) {
    minutes = ((Math.round(Number(minutes)) / 100) * 100) / 60

    if (String(minutes).includes('.')) {
      minutes = String(minutes).split('.')[1]
    }

    return Number(`${hours}.${minutes}`)
  }

  return Number(hours)
}

export const formatDateTime = (value) => {
  let date = value?.split(' ')?.[0]

  const dateSeparator = date.includes('.')
    ? '.'
    : date.includes('/')
    ? '/'
    : date.includes('-')
    ? '-'
    : null

  if (date?.length > 2 && date?.split(dateSeparator)?.length > 2) {
    let newValueSplit = date?.split(dateSeparator)
    date = newValueSplit
      .map((val, index) => {
        if (newValueSplit?.length - index > 1) {
          if (val?.length === 1) {
            return `0${val}`
          }
          // last number - year
        } else if (newValueSplit?.length - 1 === index) {
          let year = val
          while (year?.length === 2) {
            year = moment()?.year().toString()?.slice(0, 2) + year
          }
          while (year?.length < 4) {
            year += '0'
          }
          return year
        }
        return val
      })
      .join('.')
  } else if (date?.length === 2 && date?.split(dateSeparator)?.length === 1) {
    // input of 20 -> 20.10.2022
    const day = value.slice(0, 2)
    const month = moment().format('MM')
    const year = moment().year()
    date = `${day}.${month}.${year}`
  } else if (date?.length === 4 && date?.split(dateSeparator)?.length === 1) {
    // input of 2010 -> 20.10.2022
    const day = value.slice(0, 2)
    const month = value.slice(2, 4)
    const year = moment().year()
    date = `${day}.${month}.${year}`
  } else if (date?.length === 6 && date?.split(dateSeparator)?.length === 1) {
    // input of 201022 -> 20.10.2022
    const day = value.slice(0, 2)
    const month = value.slice(2, 4)
    const year = value.slice(4, 6)
    date = `${day}.${month}.20${year}`
  }

  let dateTime = `${date ? date : ''} `

  let time = value?.split(' ')?.[1]

  if (time || time === '') {
    let timeFirstPart
    let timeSecondPart
    if (value?.split(' ')?.length > 1) {
      if (time?.length >= 3 && !time.includes(':')) {
        timeFirstPart = time.slice(0, -2)
        if (timeFirstPart?.length === 1) {
          timeFirstPart = `0${timeFirstPart}`
        }
        timeSecondPart = time.slice(-2)
      } else if (time?.length === 2) {
        timeFirstPart = time
        timeSecondPart = '00'
      } else if (time?.length === 5 && time.includes(':')) {
        timeFirstPart = time.split(':')?.[0] > 23 ? 23 : time.split(':')?.[0]
        timeSecondPart = time.split(':')?.[1]
      }
      // make sure there is no undefined displayed to user
      if (timeFirstPart) {
        time = `${timeFirstPart}:${timeSecondPart}`.replaceAll('undefined', '00')
      }
    }
    dateTime += `${time ? time : moment().format('HH:mm')}`
  }

  return dateTime
}

export const formatDateOnly = (value) => {
  let date = value?.split(' ')?.[0]

  const dateSeparator = date?.includes('.')
    ? '.'
    : date.includes('/')
    ? '/'
    : date.includes('-')
    ? '-'
    : null

  if (dateSeparator && date?.length > 2 && date?.split(dateSeparator)?.length > 2) {
    let newValueSplit = date?.split(dateSeparator)
    date = newValueSplit
      .map((val, index) => {
        if (newValueSplit?.length - index > 1) {
          if (val?.length === 1) {
            return `0${val}`
          }
          // last number - year
        } else if (newValueSplit?.length - 1 === index) {
          let year = val
          while (year?.length === 2) {
            year = moment()?.year().toString()?.slice(0, 2) + year
          }
          while (year?.length < 4) {
            year += '0'
          }
          return year
        }
        return val
      })
      .join('.')
  } else if (date?.length === 2 && date?.split(dateSeparator)?.length === 1) {
    // input of 20 -> 20.10.2022
    const day = value.slice(0, 2)
    const month = moment().format('MM')
    const year = moment().year()
    date = `${day}.${month}.${year}`
  } else if (date?.length === 4 && date?.split(dateSeparator)?.length === 1) {
    // input of 2010 -> 20.10.2022
    const day = value.slice(0, 2)
    const month = value.slice(2, 4)
    const year = moment().year()
    date = `${day}.${month}.${year}`
  } else if (date?.length === 6 && date?.split(dateSeparator)?.length === 1) {
    // input of 201022 -> 20.10.2022
    const day = value.slice(0, 2)
    const month = value.slice(2, 4)
    const year = value.slice(4, 6)
    date = `${day}.${month}.20${year}`
  }

  return date
}

export const getAddress = (object = {}, isSeat = false) => {
  const seat = isSeat ? 'seat_' : ''

  const street = object[`${seat + 'street'}`] ? object[`${seat + 'street'}`] : ''
  const city = object[`${seat + 'city'}`] ? object[`${seat + 'city'}`] : ''
  const postalCode = object[`${seat + 'postal_code'}`] ? object[`${seat + 'postal_code'}`] : ''
  const country = object[`${seat + 'country'}`] ? object[`${seat + 'country'}`] : ''

  let address = ''

  if (street && city) {
    address = [street, city, postalCode, country].join(', ')
  }

  return address.replaceAll(',', '').trim().length ? address : null
}

export const getDateTimeWithCurrentTimeZone = (val, format = DATE_TIME_FORMAT) => {
  return val ? moment(val)?.utc()?.add(moment().format('Z'), 'hours')?.format(format) : ''
}

export const getYesNoString = (val) => {
  return val ? <Trans>Yes</Trans> : <Trans>No</Trans>
}
