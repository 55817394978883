import PublicLayout from 'layout/PublicLayout/PublicLayout'
import PropTypes from 'prop-types'
import React, {useEffect} from 'react'
import {Route} from 'react-router-dom'

const PublicRoute = (props) => {
  const {component, path, ...rest} = props

  useEffect(() => {
    document.getElementsByTagName('html').scrollTop = 0
  }, [])

  return (
    <Route path={path} {...rest}>
      <PublicLayout>{component}</PublicLayout>
    </Route>
  )
}

PublicRoute.propTypes = {
  component: PropTypes.node,
  path: PropTypes.string,
  rest: PropTypes.object,
}

export default PublicRoute
