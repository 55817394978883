import {
  GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_FAIL,
  GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_REQUEST,
  GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_SUCCESS,
  GET_EMPLOYEE_BILLING_FREQUENCY_FAIL,
  GET_EMPLOYEE_BILLING_FREQUENCY_REQUEST,
  GET_EMPLOYEE_BILLING_FREQUENCY_SUCCESS,
  GET_EMPLOYEE_VAT_PERIOD_FAIL,
  GET_EMPLOYEE_VAT_PERIOD_REQUEST,
  GET_EMPLOYEE_VAT_PERIOD_SUCCESS,
  GET_LIST_HOURS_FAIL,
  GET_LIST_HOURS_REQUEST,
  GET_LIST_HOURS_SUCCESS,
  GET_TIMERECORD_FAIL,
  GET_TIMERECORD_REQUEST,
  GET_TIMERECORD_SUCCESS,
} from 'redux/actionType'

const initState = {
  hourList: [],
  hourListLoading: false,
  hourListMeta: {},
  hourListSummary: {},

  timeRecord: {},
  timeRecordLoading: false,

  vatPeriod: [],
  vatPeriodLoading: false,

  billingFrequency: [],
  billingFrequencyLoading: false,

  billingFrequencyDatesFrom: [],
  billingFrequencyDatesFromLoading: false,
}

const hourReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_LIST_HOURS_REQUEST: {
      return {
        ...state,
        hourListLoading: !action.loadNext,
      }
    }
    case GET_LIST_HOURS_SUCCESS: {
      return {
        ...state,
        hourList: action.loadNext
          ? [...state.hourList, ...action.data.objects]
          : action.data.objects,
        hourListMeta: action.data.meta,
        hourListSummary: action.data.summary,
        hourListLoading: false,
      }
    }
    case GET_LIST_HOURS_FAIL: {
      return {
        ...state,
        hourListLoading: false,
      }
    }
    case GET_EMPLOYEE_VAT_PERIOD_REQUEST: {
      return {
        ...state,
        vatPeriodLoading: true,
        vatPeriod: [],
      }
    }
    case GET_EMPLOYEE_VAT_PERIOD_SUCCESS: {
      return {
        ...state,
        vatPeriodLoading: false,
        vatPeriod: action.data,
      }
    }
    case GET_EMPLOYEE_VAT_PERIOD_FAIL: {
      return {
        ...state,
        vatPeriodLoading: false,
        vatPeriod: [],
      }
    }
    case GET_EMPLOYEE_BILLING_FREQUENCY_REQUEST: {
      return {
        ...state,
        billingFrequencyLoading: true,
        billingFrequency: [],
      }
    }
    case GET_EMPLOYEE_BILLING_FREQUENCY_SUCCESS: {
      return {
        ...state,
        billingFrequencyLoading: false,
        billingFrequency: action.data,
      }
    }
    case GET_EMPLOYEE_BILLING_FREQUENCY_FAIL: {
      return {
        ...state,
        billingFrequencyLoading: false,
        billingFrequency: [],
      }
    }
    case GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_REQUEST: {
      return {
        ...state,
        billingFrequencyDatesFromLoading: true,
        billingFrequencyDatesFrom: [],
      }
    }
    case GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_SUCCESS: {
      return {
        ...state,
        billingFrequencyDatesFromLoading: false,
        billingFrequencyDatesFrom: action.data,
      }
    }
    case GET_EMPLOYEE_BILLING_FREQUENCY_DATES_FROM_FAIL: {
      return {
        ...state,
        billingFrequencyDatesFromLoading: false,
        billingFrequencyDatesFrom: [],
      }
    }
    case GET_TIMERECORD_REQUEST: {
      return {
        ...state,
        timeRecordLoading: true,
      }
    }
    case GET_TIMERECORD_SUCCESS: {
      return {
        ...state,
        timeRecord: action.data,
        timeRecordLoading: false,
      }
    }
    case GET_TIMERECORD_FAIL: {
      return {
        ...state,
        timeRecordLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default hourReducer
