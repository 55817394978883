import {GET_BANKS_FAIL, GET_BANKS_REQUEST, GET_BANKS_SUCCESS} from 'redux/actionType'

const initState = {
  bankList: [],
  bankMeta: {},
  bankLoading: false,
}

const bankReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_BANKS_REQUEST: {
      return {
        ...state,
        bankLoading: true,
      }
    }
    case GET_BANKS_SUCCESS: {
      return {
        ...state,
        bankLoading: false,
        bankMeta: action.data.meta,
        bankList: action.data.objects,
      }
    }
    case GET_BANKS_FAIL: {
      return {
        ...state,
        bankLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default bankReducer
