import {
  GET_LIST_CONSTANTS_FAIL,
  GET_LIST_CONSTANTS_REQUEST,
  GET_LIST_CONSTANTS_SUCCESS,
} from 'redux/actionType'

const initState = {
  constants: {},
  constantsLoading: false,
}

const constantReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_LIST_CONSTANTS_REQUEST: {
      return {
        ...state,
        constantsLoading: true,
      }
    }
    case GET_LIST_CONSTANTS_SUCCESS: {
      return {
        ...state,
        constantsLoading: false,
        constants: action.data,
      }
    }
    case GET_LIST_CONSTANTS_FAIL: {
      return {
        ...state,
        constantsLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default constantReducer
