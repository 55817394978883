import historyStyles from 'component/History/historyStyles'
import CustomCard from 'component/material/CustomCard'
import Table from 'component/material/table/Table'
import React from 'react'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const HistoryRecordCard = (props) => {
  const {classes, columns, onTableRowClick, data, diffRow} = props

  return (
    <CustomCard
      title={<Trans>History records</Trans>}
      content={
        data?.length > 0 ? (
          <Table
            columns={columns}
            data={data}
            onTableRowClick={onTableRowClick}
            displayCheckbox={false}
            selectedRow={diffRow}
          />
        ) : (
          <div className={classes.noHistoryRecord}>
            <Trans>No history yet</Trans>
          </div>
        )
      }
      additionalCardHeaderClass={classes.historyRecordsHeader}
      additionalCardContentClass={classes.historyRecordsContent}
    />
  )
}

export default withStyles(historyStyles)(HistoryRecordCard)
