import HistoryRecordCard from 'component/History/HistoryRecordCard'
import HistoryRecordDiffCard from 'component/History/HistoryRecordDiffCard'
import {historyColumns} from 'component/History/historyColumns'
import historyStyles from 'component/History/historyStyles'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import WarningDialog from 'component/material/WarningDialog'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DB_DATE_TIME_FORMAT_WITHOUT_Z,
  TARIFF_TYPE,
  WORK_TYPE,
} from 'helper/constants'
import {fireErrorToast, fireSuccessToast, redirectTo} from 'helper/functions'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {
  getTimeRecordVersions,
  getTimeRecordVersionsDiff,
  getTimeRecordVersionsRollback,
} from 'redux/action/timeRecordReportAction'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const TimeRecordHistory = (props) => {
  const {
    classes,
    getTimeRecordVersions,
    getTimeRecordVersionsDiff,
    getTimeRecordVersionsRollback,
    timerecordHistory,
    timerecordHistoryDiff,
    timerecordHistoryDiffLoading,
  } = props

  const {id} = useParams()

  const [diffToDisplay, setDiffToDisplay] = useState(null)
  const [diffRow, setDiffRow] = useState(0)
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false)

  const handleOpenRestoreDialog = () => {
    setOpenRestoreDialog(true)
  }
  const handleCloseRestoreDialog = () => {
    setOpenRestoreDialog(false)
  }

  const handleRestoreVersion = () => {
    if (diffToDisplay?.id) {
      return getTimeRecordVersionsRollback(id, diffToDisplay.id)
        .then(() => {
          handleCloseRestoreDialog()
          redirectTo('/time-records/' + id)
          fireSuccessToast(<Trans>Roll back was successful.</Trans>)
        })
        .catch(() => {
          fireErrorToast(<Trans>Roll back failed.</Trans>)
        })
    }
  }

  const onTableRowClick = (row, rowIndex) => {
    setDiffToDisplay(row)
    setDiffRow(rowIndex)
  }

  const diffAttributes = [
    {
      name: 'date_from',
      label: <Trans>Date and time from</Trans>,
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_TIME_FORMAT),
    },
    {
      name: 'date_to',
      label: <Trans>Date and time to</Trans>,
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_TIME_FORMAT),
    },
    {
      name: 'client',
      label: <Trans>Client</Trans>,
      render: (val) => val.name,
    },
    {
      name: 'work_type',
      label: <Trans>Work type</Trans>,
      render: (val) => val && WORK_TYPE.find((type) => type.value === val)?.label,
    },
    {
      name: 'period',
      label: <Trans>Period</Trans>,
    },
    {
      name: 'tariff',
      label: <Trans>Rate</Trans>,
      render: (val) => val && TARIFF_TYPE.find((type) => type.value === val)?.label,
    },
    {
      name: 'note',
      label: <Trans>Note</Trans>,
    },
  ]

  const getCurrentValue = (diff, row) => {
    const current = diff?.current_version?.[row.name]

    return row.render ? row.render(current) : current
  }

  const getChangedValue = (diff, row) => {
    let current = diff?.current_version?.[row.name]
    let changed = diff?.diff_version?.[row.name]
    const originChanged = changed

    if (row.name === 'valid_from' || row.name === 'valid_to') {
      current = current && moment(current, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_TIME_FORMAT)
      changed = changed && moment(changed, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_TIME_FORMAT)
    }

    if (row.name === 'client') {
      current = current.id
      changed = changed.id
    }

    if (current !== changed) {
      return row.render ? row.render(originChanged) : originChanged
    }

    return (
      <span className={classes.unchangedHistoryValue}>
        {row.render ? row.render(originChanged) : originChanged}
      </span>
    )
  }

  useEffect(() => {
    getTimeRecordVersions(id).then((res) => res[0]?.id && setDiffToDisplay(res[0]))

    return () => {
      setDiffToDisplay(null)
    }
  }, [])

  useEffect(() => {
    if (diffToDisplay?.id) {
      getTimeRecordVersionsDiff(id, diffToDisplay.id)
    }
  }, [diffToDisplay])

  return (
    <GridContainer direction={'row'} spacing={4}>
      <GridItem xs={12} md={5}>
        <HistoryRecordCard
          columns={historyColumns(diffToDisplay, classes)}
          onTableRowClick={onTableRowClick}
          data={timerecordHistory}
          diffRow={diffRow}
        />
      </GridItem>

      <GridItem xs={12} md={7}>
        <HistoryRecordDiffCard
          diffToDisplay={diffToDisplay}
          diffData={timerecordHistoryDiff}
          diffLoading={timerecordHistoryDiffLoading}
          diffAttributes={diffAttributes}
          historyData={timerecordHistory}
          handleOpenRestoreDialog={handleOpenRestoreDialog}
          getCurrentValue={getCurrentValue}
          getChangedValue={getChangedValue}
        />
        <WarningDialog
          open={openRestoreDialog}
          title={<Trans>Restore time record version</Trans>}
          onClose={handleCloseRestoreDialog}
          onConfirm={handleRestoreVersion}
        />
      </GridItem>
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTimeRecordVersions,
      getTimeRecordVersionsDiff,
      getTimeRecordVersionsRollback,
    },
    dispatch
  )
}

export default compose(
  withStyles(historyStyles),
  connect((store) => {
    return {
      timerecordHistory: store.timeRecord.timerecordHistory,
      timerecordHistoryLoading: store.timeRecord.timerecordHistoryLoading,
      timerecordHistoryDiff: store.timeRecord.timerecordHistoryDiff,
      timerecordHistoryDiffLoading: store.timeRecord.timerecordHistoryDiffLoading,
    }
  }, mapDispatchToProps)
)(TimeRecordHistory)
