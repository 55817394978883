import {
  GET_EMPLOYEES_INVOICES_FAIL,
  GET_EMPLOYEES_INVOICES_REQUEST,
  GET_EMPLOYEES_INVOICES_SUCCESS,
  GET_EMPLOYEE_INVOICE_FAIL,
  GET_EMPLOYEE_INVOICE_REQUEST,
  GET_EMPLOYEE_INVOICE_SUCCESS,
} from 'redux/actionType'

const initState = {
  employeesInvoicesList: [],
  employeesInvoicesListLoading: false,
  employeesInvoicesListMeta: {},

  employeeInvoice: {},
  employeeInvoiceLoading: false,
}

const employeeInvoicesReducer = (state = initState, action = null) => {
  switch (action.type) {
    case GET_EMPLOYEES_INVOICES_REQUEST: {
      return {
        ...state,
        employeesInvoicesListLoading: !action.loadNext,
      }
    }
    case GET_EMPLOYEES_INVOICES_SUCCESS: {
      return {
        ...state,
        employeesInvoicesList: action.loadNext
          ? [...state.employeesInvoicesList, ...action.data.objects]
          : action.data.objects,
        employeesInvoicesListMeta: action.data.meta,
        employeesInvoicesListLoading: false,
      }
    }
    case GET_EMPLOYEES_INVOICES_FAIL: {
      return {
        ...state,
        employeesInvoicesListLoading: false,
      }
    }
    case GET_EMPLOYEE_INVOICE_REQUEST: {
      return {
        ...state,
        employeeInvoiceLoading: true,
      }
    }
    case GET_EMPLOYEE_INVOICE_SUCCESS: {
      return {
        ...state,
        employeeInvoiceLoading: false,
        employeeInvoice: action.data,
      }
    }
    case GET_EMPLOYEE_INVOICE_FAIL: {
      return {
        ...state,
        employeeInvoiceLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default employeeInvoicesReducer
