import HistoryRecordCard from 'component/History/HistoryRecordCard'
import HistoryRecordDiffCard from 'component/History/HistoryRecordDiffCard'
import {historyColumns} from 'component/History/historyColumns'
import historyStyles from 'component/History/historyStyles'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import WarningDialog from 'component/material/WarningDialog'
import {
  CUSTOMER_VAT_STATUS,
  DATE_FORMAT,
  DB_DATE_TIME_FORMAT_WITHOUT_Z,
  PRICE_SUB_TYPE_LIST,
  PRICE_TARIFF_TYPE,
  PRICE_TYPE_LIST,
} from 'helper/constants'
import {fireErrorToast, fireSuccessToast, getYesNoString, redirectTo} from 'helper/functions'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {
  getPriceVersions,
  getPriceVersionsDiff,
  getPriceVersionsRollback,
} from 'redux/action/priceListAction'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'

const PriceListPriceHistory = (props) => {
  const {
    classes,
    priceHistory,
    priceHistoryDiff,
    priceHistoryDiffLoading,
    getPriceVersions,
    getPriceVersionsDiff,
    getPriceVersionsRollback,
  } = props

  const {id, priceId} = useParams()

  const [diffToDisplay, setDiffToDisplay] = useState(null)
  const [diffRow, setDiffRow] = useState(0)
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false)

  const handleOpenRestoreDialog = () => {
    setOpenRestoreDialog(true)
  }
  const handleCloseRestoreDialog = () => {
    setOpenRestoreDialog(false)
  }

  const onTableRowClick = (row, rowIndex) => {
    setDiffToDisplay(row)
    setDiffRow(rowIndex)
  }

  const handleRestoreVersion = () => {
    if (diffToDisplay?.id) {
      return getPriceVersionsRollback(priceId, diffToDisplay.id)
        .then(() => {
          handleCloseRestoreDialog()
          redirectToDetail()
          fireSuccessToast(<Trans>Roll back was successful.</Trans>)
        })
        .catch(() => {
          fireErrorToast(<Trans>Roll back failed.</Trans>)
        })
    }
  }

  const redirectToDetail = () => {
    redirectTo(`/price-lists/${id}/prices/${priceId}`)
  }

  const diffAttributes = [
    {
      label: <Trans>Price name</Trans>,
      name: 'name',
    },
    {
      label: <Trans>Type</Trans>,
      name: 'type',
      render: (val) => val && PRICE_TYPE_LIST.find((type) => type.value === val)?.label,
    },
    {
      label: <Trans>Sub type</Trans>,
      name: 'sub_type',
      render: (val) => val && PRICE_SUB_TYPE_LIST.find((type) => type.value === val)?.label,
    },
    {
      label: <Trans>Price</Trans>,
      name: 'price',
    },
    {
      label: <Trans>Items count from</Trans>,
      name: 'items_count_from',
    },
    {
      label: <Trans>Items count to</Trans>,
      name: 'items_count_to',
    },
    {
      label: <Trans>Valid from</Trans>,
      name: 'valid_from',
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_FORMAT),
    },
    {
      label: <Trans>Valid to</Trans>,
      name: 'valid_to',
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_FORMAT),
    },
    {
      label: <Trans>Tariff</Trans>,
      name: 'tariff',
      render: (val) => val && PRICE_TARIFF_TYPE.find((type) => type.value === val)?.label,
    },
    {
      label: <Trans>Customer vat status</Trans>,
      name: 'customer_vat_status',
      render: (val) => val && CUSTOMER_VAT_STATUS.find((type) => type.value === val)?.label,
    },
    {
      label: <Trans>Is automatic</Trans>,
      name: 'is_automatic',
      render: (val) => getYesNoString(val),
    },
    {
      label: <Trans>Is data access fee</Trans>,
      name: 'is_pohoda_data_access_fee',
      render: (val) => getYesNoString(val),
    },
    {
      label: <Trans>Is SQL data access fee</Trans>,
      name: 'is_sql_pohoda_data_access_fee',
      render: (val) => getYesNoString(val),
    },
    {
      label: <Trans>Is fee for service</Trans>,
      name: 'is_fee',
      render: (val) => getYesNoString(val),
    },
  ]

  const getCurrentValue = (diff, row) => {
    const current = diff?.current_version?.[row.name]

    return row.render ? row.render(current) : current
  }

  const getChangedValue = (diff, row) => {
    let current = diff?.current_version?.[row.name]
    let changed = diff?.diff_version?.[row.name]
    let originChanged = diff?.diff_version?.[row.name]

    if (row.name === 'valid_from' || row.name === 'valid_to') {
      current = current && moment(current, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_FORMAT)
      changed = changed && moment(changed, DB_DATE_TIME_FORMAT_WITHOUT_Z).format(DATE_FORMAT)
    }

    if (current !== changed) {
      return row.render ? row.render(originChanged) : originChanged
    }

    return (
      <span className={classes.unchangedHistoryValue}>
        {row.render ? row.render(originChanged) : originChanged}
      </span>
    )
  }

  useEffect(() => {
    getPriceVersions(priceId).then((res) => res[0]?.id && setDiffToDisplay(res[0]))

    return () => {
      setDiffToDisplay(null)
    }
  }, [])

  useEffect(() => {
    if (diffToDisplay?.id) {
      getPriceVersionsDiff(priceId, diffToDisplay.id)
    }
  }, [diffToDisplay])

  return (
    <GridContainer direction={'row'} spacing={4}>
      <GridItem xs={12} md={5}>
        <HistoryRecordCard
          columns={historyColumns(diffToDisplay, classes)}
          onTableRowClick={onTableRowClick}
          data={priceHistory}
          diffRow={diffRow}
        />
      </GridItem>

      <GridItem xs={12} md={7}>
        <HistoryRecordDiffCard
          diffToDisplay={diffToDisplay}
          diffData={priceHistoryDiff}
          diffLoading={priceHistoryDiffLoading}
          diffAttributes={diffAttributes}
          historyData={priceHistory}
          handleOpenRestoreDialog={handleOpenRestoreDialog}
          getCurrentValue={getCurrentValue}
          getChangedValue={getChangedValue}
        />
        <WarningDialog
          open={openRestoreDialog}
          title={<Trans>Restore price version</Trans>}
          onClose={handleCloseRestoreDialog}
          onConfirm={handleRestoreVersion}
        />
      </GridItem>
    </GridContainer>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPriceVersions,
      getPriceVersionsDiff,
      getPriceVersionsRollback,
    },
    dispatch
  )
}

export default compose(
  withStyles(historyStyles),
  connect((store) => {
    return {
      priceHistory: store.priceList.priceHistory,
      priceHistoryLoading: store.priceList.priceHistoryLoading,
      priceHistoryDiff: store.priceList.priceHistoryDiff,
      priceHistoryDiffLoading: store.priceList.priceHistoryDiffLoading,
    }
  }, mapDispatchToProps)
)(PriceListPriceHistory)
